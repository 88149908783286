<template>
  <div class="privacyPolicy">
    <!-- 导航栏 -->
    <HeadNav pageName />
    <div class="xqzxBox">
<p>
    <span style=";font-family:宋体;font-size:14px">为保障</span><span style=";font-family:宋体;font-size:14px">人在海南</span><span style=";font-family:宋体;font-size:14px">功能实现与安全稳定运行的目的，我们可能会申请或使用设备的权限。</span>
</p>
<p>
    <span style=";font-family:宋体;font-size:14px">我们在以下列表中展示</span><span style=";font-family:宋体;font-size:14px">人在海南</span><span style=";font-family:宋体;font-size:14px">可能申请、使用的设备权限。随着</span><span style=";font-family:宋体;font-size:14px">人在海南</span><span style=";font-family:宋体;font-size:14px">的升级，如申请或使用的设备权限类型与目的发生变动，我们将及时更新列表。</span>
</p>
<p>
    <span style=";font-family:宋体;font-size:14px"><span style="font-family:宋体">请您知悉，我们接入的第三方</span>SDK也可能会申请或使用某些设备权限。</span>
</p>
<p>
    <span style=";font-family:宋体;font-size:14px">&nbsp;</span>
</p>
<p>
    <span style=";font-family:宋体;font-size:14px">定位</span>
</p>
<p>
    <span style=";font-family:宋体;font-size:14px">功能说明：地理位置经纬度</span>
</p>
<p>
    <span style=";font-family:宋体;font-size:14px">使用场景：</span><span style=";font-family:宋体;font-size:14px">用于向用户推荐附近的工作</span>
</p>
<p>
    <span style=";font-family:宋体;font-size:14px">调用路径：</span>
</p>
<p>
    <span style=";font-family:宋体;font-size:14px">应用</span><span style=";font-family:宋体;font-size:14px">首页</span><span style=";font-family:宋体;font-size:14px">-&gt;</span><span style=";font-family:宋体;font-size:14px">获取用户定位</span>
</p>
<p>
    <span style=";font-family:宋体;font-size:14px"><span style="font-family:宋体">适用平台：</span>Android/iOS</span>
</p>
<p>
    <span style=";font-family:宋体;font-size:14px">&nbsp;</span>
</p>
<p>
    <span style=";font-family:宋体;font-size:14px">麦克风、摄像头</span>
</p>
<p>
    <span style=";font-family:宋体;font-size:14px">功能说明：</span><span style=";font-family:宋体;font-size:14px">录入音频及</span><span style=";font-family:宋体;font-size:14px">使用摄像头进行图片拍摄和识别</span>
</p>
<p>
    <span style=";font-family:宋体;font-size:14px">使用场景：</span><span style=";font-family:宋体;font-size:14px">用于在用户发布动态时录制视频</span><span style=";font-family:宋体;font-size:14px">，</span><span style=";font-family:宋体;font-size:14px">发布动态时拍摄照片</span>
</p>
<p>
    <span style=";font-family:宋体;font-size:14px">调用路径：应用</span><span style=";font-family:宋体;font-size:14px">海脉圈</span><span style=";font-family:宋体;font-size:14px">-&gt;</span><span style=";font-family:宋体;font-size:14px"><span style="font-family:宋体">发布动态</span>-&gt;选择相册</span>
</p>
<p>
    <span style=";font-family:宋体;font-size:14px"><span style="font-family:宋体">适用平台：</span>Android/iOS</span>
</p>
<p>
    <span style=";font-family:宋体;font-size:14px">&nbsp;</span>
</p>
<p>
    <span style=";font-family:宋体;font-size:14px">拨打电话</span>
</p>
<p>
    <span style=";font-family:宋体;font-size:14px">功能</span><span style=";font-family:宋体;font-size:14px">说明：</span><span style="font-family: &#39;Segoe UI Emoji&#39;;color: rgb(64, 64, 64);letter-spacing: 0;font-size: 14px;background: rgb(255, 255, 255)"><span style="font-family:Segoe UI Emoji">直接拨打电话</span></span>
</p>
<p>
    <span style=";font-family:宋体;font-size:14px">使用</span><span style=";font-family:宋体;font-size:14px">场景：</span><span style=";font-family:宋体;font-size:14px"><span style="font-family:宋体">方便用户直接使用本软件拨打电话给企业</span><span style="font-family:Calibri">HR</span></span>
</p>
<p>
    <span style=";font-family:宋体;font-size:14px">调用路径：应用</span><span style=";font-family:宋体;font-size:14px">海聘</span><span style=";font-family:宋体;font-size:14px">-&gt;</span><span style=";font-family:宋体;font-size:14px"><span style="font-family:宋体">职位详情</span>-&gt;拨打电话</span>
</p>
<p>
    <span style=";font-family:宋体;font-size:14px"><span style="font-family:宋体">适用平台：</span>Android/iOS</span>
</p>
<p>
    <span style=";font-family:宋体;font-size:14px">&nbsp;</span>
</p>
<p>
    <span style=";font-family:宋体;font-size:14px">存储卡</span>
</p>
<p>
    <span style=";font-family:宋体;font-size:14px">功能说明：</span><span style=";font-family:宋体;font-size:14px">读取和写入设备存储空间内的数据</span>
</p>
<p>
    <span style=";font-family:宋体;font-size:14px">使用场景：</span><span style=";font-family:宋体;font-size:14px">用于存储用户信息在本地</span>
</p>
<p>
    <span style=";font-family:宋体;font-size:14px">调用路径：</span><span style=";font-family:宋体;font-size:14px">应用</span><span style=";font-family:宋体;font-size:14px">首页</span><span style=";font-family:宋体;font-size:14px">-&gt;</span><span style=";font-family:宋体;font-size:14px">获取用户存储信息</span>
</p>
<p>
    <span style=";font-family:宋体;font-size:14px"><span style="font-family:宋体">适用平台：</span>Android/iOS</span>
</p>
<p>
    <span style=";font-family:宋体;font-size:14px">&nbsp;</span>
</p>
<p>
    <span style=";font-family:宋体;font-size:14px"><span style="font-family:Calibri">APP</span><span style="font-family:宋体">列表</span></span>
</p>
<p>
    <span style=";font-family:宋体;font-size:14px">功能说明：</span><span style=";font-family:宋体;font-size:14px">读取已安装应用软件列表</span>
</p>
<p>
    <span style=";font-family:宋体;font-size:14px">使用场景：</span><span style=";font-family:宋体;font-size:14px">用于判断用户手机内是否安装了高德地图、百度地图等地图软件，跳转到地图进行导航定位</span>
</p>
<p>
    <span style=";font-family:宋体;font-size:14px">调用路径：</span><span style=";font-family:宋体;font-size:14px">应用活动</span><span style=";font-family:宋体;font-size:14px">-&gt;</span><span style=";font-family:宋体;font-size:14px">导航、应用海聘</span><span style=";font-family:宋体;font-size:14px">-&gt;</span><span style=";font-family:宋体;font-size:14px">职位详情</span><span style=";font-family:宋体;font-size:14px">-&gt;</span><span style=";font-family:宋体;font-size:14px">导航</span>
</p>
<p>
    <span style=";font-family:宋体;font-size:14px"><span style="font-family:宋体">适用平台：</span>Android/iOS</span>
</p>
<p>
    <span style=";font-family:宋体;font-size:14px">&nbsp;</span>
</p>
<p>
    <span style=";font-family:宋体;font-size:14px">系统设置</span>
</p>
<p>
    <span style=";font-family:宋体;font-size:14px">功能说明：</span><span style=";font-family:宋体;font-size:14px">修改系统设置</span>
</p>
<p>
    <span style=";font-family:宋体;font-size:14px">使用场景：</span><span style=";font-family:宋体;font-size:14px">APP桌面角标</span>
</p>
<p>
    <span style=";font-family:宋体;font-size:14px">调用路径：</span><span style=";font-family:宋体;font-size:14px">APP启动时</span>
</p>
<p>
    <span style=";font-family:宋体;font-size:14px"><span style="font-family:宋体">适用平台：</span>Android/iOS</span>
</p>
<p>
    <span style=";font-family:宋体;font-size:14px">&nbsp;</span>
</p>
<p>
    <span style=";font-family:宋体;font-size:14px">&nbsp;</span>
</p>
<p>
    <span style=";font-family:宋体;font-size:14px">&nbsp;</span>
</p>
<p>
    <span style=";font-family:宋体;font-size:14px">&nbsp;</span>
</p>
<p>
    <span style=";font-family:宋体;font-size:14px">&nbsp;</span>
</p>
<p>
    <span style=";font-family:宋体;font-size:14px">&nbsp;</span>
</p>
<p>
    <span style=";font-family:宋体;font-size:14px">&nbsp;</span>
</p>
<p>
    <span style=";font-family:宋体;font-size:14px">&nbsp;</span>
</p>
<p>
    <span style=";font-family:宋体;font-size:14px">&nbsp;</span>
</p>
<p>
    <span style=";font-family:宋体;font-size:14px">&nbsp;</span>
</p>
<p>
    <br/>
</p>
    </div>
    <!-- footerBox -->
    <div class="gwFooter">
      <FooterBox />
    </div>
  </div>
</template>

<script>
import HeadNav from "@/components/HeadNav.vue";
import FooterBox from "@/components/FooterBox.vue";
export default {
  name: "privacyPolicy",
  components: {
    HeadNav,
    FooterBox
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {}
};
</script>
<style lang="scss">
</style>