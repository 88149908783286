<template>
  <div class="newsDetails">
    <!-- 导航栏 -->
    <HeadNav pageName="Home" />
    <!-- 资讯内容 -->
    <div class="xqBanner">
      <img src="@/assets/images/banner8.png" alt />
      <div class="xqzxBox">
        <div class="xqNews">
          <h3 class="title">自贸港建设总体方案去年发布以来</h3>
          <div class="time">2021-08-24</div>
          <p>
            本报海口10月27日电 （记者赵鹏、孙海天）“能够搭上自贸港的东风，见证海南发展，与企业一起成长，我们真是太幸运了！”10月24日，在2021年海南自由贸易港招才引智活动上，现任海南银行股份有限公司战略发展部总经理的黄亮如是说。据海南省委人才发展局相关负责人介绍，此次活动所征集岗位均来自省内高校、科研院所、国有企业、重点项目平台和园区，首批3.6万余个岗位。
            为推进四方英才共建自贸港，海南制定实施了《海南自由贸易港高层次人才分类标准2020》。海南还印发了《关于推进“1+N”人才评价机制改革的实施意见》，将科技人才等9类人才作为试点，按照每类人才的不同职业、不同岗位、不同层次，科学分类设计评价标准。
            海南着力打造“千人专项”引才工程、产业团队建设工程、国际人才集聚工程、柔性引才聚智工程“四大引才工程”，实施“南海系列”育才计划、“双百”人才团队建设工程等人才工程项目。2018年4月以来，海南聚焦重点领域、产业、项目发展需要，着力实行积极、开放、有效的引才政策。自去年《海南自由贸易港建设总体方案》发布以来，海南累计引进各类人才26万人。
            引得进、留得住，关键在于用得好。海南推出了制定“一系列”服务政策、推行“一站式”服务、实行“一张卡”服务人才和提供“一专员”个性服务的“四个一”人才服务机制。目前，海南已出台人才落户、安居等系列服务保障政策，以解人才来琼后顾之忧。
            “当前的海南，比历史上任何时候都更加急需人才、渴望人才，也具备吸引人才的良好环境。”海南省委主要负责人表示，“海南拥有人才最为看重的广阔发展空间，年轻的自由贸易港每天都在发生新变化，充满生机与活力，是每个人都能施展抱负的舞台。”
          </p>
          <div class="keyWords">关键词：人才、海南、自贸港</div>
          <div class="line"></div>
          <h3 class="article">相关文章</h3>
        </div>
        <!-- 新闻资讯 -->
        <div class="newsBoxInfo">
          <NewsInformation />
        </div>
        <!-- footerBox -->
        <div class="gwFooter">
          <FooterBox />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeadNav from "@/components/HeadNav.vue";
import NewsInformation from "@/components/NewsInformation.vue";
import FooterBox from "@/components/FooterBox.vue";
export default {
  name: "newsDetails",
  components: {
    HeadNav,
    NewsInformation,
    FooterBox
  },
  metaInfo: {
    title: '资讯详情',
    meta: [
      {
        name: 'keywords',
        content: '人在海南、人才引进、海南自贸港、自贸港政策、海南教育、自贸港、重点园区、海南人才落户、海南招聘、海南考试、海南公考、海南公务员、海南社保、海南公积金、海南房价、海南房产、海南摇号、海南免税店、海南电动车上牌、海南充电桩、海南医疗、乐城特药险、海南健康码、人在岛上、人在海上、岛内、岛外、海南职场、海南公司、海南产业园、海南高薪工作、海南招聘、海南社交、海南线上活动、海南线下活动、海聘、海脉圈、甘肃人在海南、广西人在海南、贵州人在海南、海南人在海南、黑龙江人在海南、吉林人在海南、辽宁人在海南、内蒙古人在海南、宁夏人在海南、青海人在海南、山西人在海南、西藏人在海南、云南人在海南、广东人在海南、北京人在海南、上海人在海南、东北人在海南、深圳人在海南，海聘，海聘圈，简历，公司企业，求职，职位，找工作，话题广场，圈子，个人简历，我的圈子，职场名片，我要求职'
      },
      {
        name: 'description',
        content: '岛上生活-人在海南”项目致力于成为海南人才引进的窗口——海南本地职场社交平台，吸引各类优秀人才来海南发展创业，共享海南自由贸易港发展新机遇。用户可在平台上进行求职招聘、建立连接，关注好友的职业动态及互动，浏览本地各行业的项目介绍、企业展示、创始人访谈等，跟踪行业资讯和动态，留意职场的变化与新机会。通过搭建人才线上交流平台，营造相同职业、相同行业的人员聚集交流氛围，促进信息的流转和整体的海南职场交流效率。'
      }
    ]
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {}
};
</script>
<style lang="scss">
.newsDetails {
  position: absolute;
  top: 0;
  width: $wd_1920;
  min-height: 100%;
  background: $bg_fff;
  box-sizing: border-box;
  .xqBanner {
    height: 680px;
    position: relative;
    top: 0;
    width: $wd_1920;
    box-sizing: border-box;
    .xqzxBox {
      width: 100%;
      background: $bg_fff;
      .xqNews {
        position: relative;
        top: -200px;
        z-index: 2;
        border-radius: 20px;
        width: 1200px;
        padding: 60px 0 0;
        box-sizing: border-box;
        background: $bg_fff;
        margin: 0 auto;
        .title {
          font-weight: bold;
          font-size: $size_36;
          text-align: center;
          padding: 0 10% 30px;
        }
        .time {
          font-size: $size_20;
          padding-bottom: 50px;
          text-align: center;
        }
        p {
          font-size: $size_20;
          line-height: 36px;
          color: #000;
          width: 978px;
          margin: 0 auto;
        }
        .keyWords {
          font-size: $size_20;
          padding-top: 100px;
          width: 978px;
          margin: 0 auto;
        }
        .line {
          height: 1px;
          width: 100%;
          background: $clr_999;
          margin-top: 60px;
        }
        .article {
          font-size: $size_36;
          font-weight: bold;
          padding: 60px 0 0;
        }
      }
      .newsBoxInfo {
        margin-top: -140px;
        padding-bottom: 130px;
        border-bottom: 1px solid $clr_999;
      }
    }
  }
}
</style>