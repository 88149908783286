import sha1 from "sha1"
import axios from "axios"
import { baseRestUrl } from "@/lib/https"
import  JSEncrypt  from 'jsencrypt'

//32位随机字符串
function getStrLen(limit = 32) {
  let range = "0123456789abcdefghijklmnopqrstuvwxyz";
  let res = "";
  for (let i = 0; i < limit; i++) {
    res += range.charAt([Math.floor(Math.random()*(range.length - 1))])
  }
  return   res.substr(0,limit);
}

// ASCII码升序
export function ascScort(obj={}) {
  if (Object.prototype.toString.call(obj) !== "[object Object]") {
    throw TypeError( " the function of 'ascScort' need a object,but the val is not")
  }
  obj.nonceStr = getStrLen();
  obj.timestamp = Math.round(Date.now() / 1000)
  return {
    nonceStr: obj.nonceStr,
    timestamp: obj.timestamp,
    default: Object.keys(obj).map(key => `${key}=${encodeURIComponent(obj[key])}`).sort((a, b) => {
      if(a > b)  return 1;
      if (a == b)  return 0;
      if (a<b) return -1;
    }).join('&')
  }
}


//sha1加密
export function getStr(asc, token = "") {
  token = token || "";
  return sha1(asc + "&token=" + token)
}
 
//ras加密  JSEncrypt自带base64
function encryption(publicKey, str) {
  let encrypt = new JSEncrypt();
  encrypt.setPublicKey(publicKey)
  let code = encrypt.encrypt(str);
  return code
}

//获取公钥
export  function getSign(str) {
  return new Promise((resolve, reject) => {
    let _PUBLIKEY = localStorage.getItem("_PUBLIKEY");
    if(!_PUBLIKEY) {
      axios.get(baseRestUrl+'/key/password/public', {
        timeout: 10000,
        headers: {
          'Content-Type': 'application/json',
          'platform': 'h5'
        },
      }).then(res => {
        if (res.data.code === '200000') {
          _PUBLIKEY = res.data.data.key.replace('-----BEGIN PUBLIC KEY-----\n', '').replace('\n-----END PUBLIC KEY-----','').replace('\n','');
          localStorage.setItem('_PUBLIKEY', _PUBLIKEY)
          resolve(encryption(_PUBLIKEY, str))
        } else {
          reject(res.statusText)
        }
      })
      .catch(err => {
        console.log("获取公钥出错："+err)
        reject(err)
      })
    } else {
      resolve(encryption(_PUBLIKEY, str))
    }
  })
}

