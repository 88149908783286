import axios from "../https"
class Common {
    constructor() {}
    get(url, params) {
        return axios.get(url, {params})
    }
    post(url, params) {
        return axios.post(url, params)
    }
}

export default Common