
/* 
*全局通用方法
*/

 //获取url指定参数的值,例如：getQueryVariable('id')可以取到当前url中参数为id的值
export  function getQueryVariable(variable) { 
  console.log(variable,'获取url参数')
  var query = window.location.search.substring(1);
  var vars = query.split("&");
  for (var i=0;i<vars.length;i++) {
      var pair = vars[i].split("=");
      if(pair[0] == variable){return pair[1];}
  }

  return '';
}


export function getQuery() {
  let qs = location.search ? location.search.substring(1) : "",
    args = {};
  if (qs) {
     for ( let item of  qs.split("&").map(e => e.split("="))) {
       let name = decodeURIComponent(item[0]), // 使用decodeURIComponent是因为传过来的参数一般都是经过编码过后的
          value = decodeURIComponent(item[1]);
       args[name] = value
     }
  }
  return args
}

// 获取url中指定参数
export function urlParams(key) {
  let params = getQuery();
  return params[key]
}


//判断安卓还是ios
export function getSystem(){ 
  var u = navigator.userAgent;
  var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
  var isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
  return { //返回对象，使用可以判断对应的key(isAndroid,isIOS)是不是true
      isAndroid: isAndroid,
      isIOS: isIOS,
  }
}

//不同环境的图片域名
export function getImgUrl(){
  console.log(process.env.NODE_ENV,'环境')
  if(process.env.NODE_ENV==='development'){
    return  'https://d4ggmk2-1305051129.file.myqcloud.com'
  }
  if(process.env.NODE_ENV==='test'){
    // return 'https://d4ggmk2-1305051129.file.myqcloud.com/'
  }
  if(process.env.NODE_ENV==='release'){
    // return 'https://d4ggmk2-1305051129.file.myqcloud.com/'
  }
  if(process.env.NODE_ENV==='production'){
    // return 'https://d4ggmk2-1305051129.file.myqcloud.com/'
  }
  
  
}