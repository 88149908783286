<template>
  <div class="home">
    <div class="navBox" :class="{'navBarWrap':navBarFixed}">
      <!-- 导航栏 -->
      <HeadNav pageName="Home" />

      <img class="banner" src="@/assets/images/banner.png" alt />
      <div class="main">
        <div class="left">
          <h1 class="title">人在海南</h1>
          <h2 class="title">发现更多自贸港机遇</h2>
          <p>从人才落地、孵化、培训、交流、就业岗位等方面提供一站式服务，打造互联网高端人才体系，建设海南互联网人才梯队，减少人才流失，助力海南本土企业打造雇主品牌，建立海南人才银行，长期吸纳并储备海南中高端人才。</p>
          <div class="modelFlex">
            <div class="modeList">
              <img src="@/assets/images/ios.png" alt />
              <div @click="showCode=true">IOS端</div>
            </div>
            <div class="modeList">
              <img src="@/assets/images/android.png" alt />
              <div @click="showCode=true">Android端</div>
            </div>
          </div>
        </div>

        <div class="right">
          <img src="@/assets/images/banner1.png" alt />
        </div>
      </div>
    </div>
    <!-- 职场、人脉、创业、机会 -->
    <div class="menuBox1">
      <h3>职场、人脉、创业、机会</h3>
      <div class="infoBox">
        <div class="imgBox">
          <img src="@/assets/images/banner2.png" alt />
          <div class="txtBox">
            <ul>
              <li v-for="(row, i) in datas" :key="i" :class="'list'+i">
                <h3>{{row.title}}</h3>
                <p>{{row.txt}}</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- 全方位海聘 -->
    <div class="menuBox2 bgColor">
      <h3>全方位海聘</h3>
      <h4>海量岗位&人才银行</h4>
      <p>从人才落地、孵化、培训、交流、就业岗位等方面提供一站式服务，打造互联网高端人才体系，建设海南互联网人才梯队，减少人才流失，助力海南本土企业打造雇主品牌，建立海南人才银行，长期吸纳并储备海南中高端人才。</p>
      <div class="imgFlex">
        <ul>
          <li>
            <img class="left" src="@/assets/images/frame1.png" alt />
            <div>海量海南岗位</div>
            <img class="right" src="@/assets/images/info1.png" alt />
          </li>
          <li>
            <img class="left" src="@/assets/images/frame2.png" alt />
            <div>中高端人才精英</div>
            <img class="right" src="@/assets/images/info2.png" alt />
          </li>
          <li>
            <img class="left" src="@/assets/images/frame3.png" alt />
            <div>一站式招聘服务</div>
            <img class="right" src="@/assets/images/info3.png" alt />
          </li>
        </ul>
      </div>
    </div>
    <!-- 资讯/行业动态 -->
    <div class="menuBox3">
      <div class="left">
        <div class="top mbot">
          <div class="dwBg">
            <h4>资讯</h4>
            <div class="imgBg">
              <img src="@/assets/images/type4.png" alt />
            </div>
          </div>
          <p>想了解海南时政解读与行业动态？这里都可以满足</p>
        </div>
        <div class="top">
          <div class="dwBg">
            <h4>群组</h4>
            <div class="imgBg">
              <img src="@/assets/images/type1.png" alt />
            </div>
          </div>
          <p>促进不同海南兴趣群组人员聚集，形成即时性行业交流与兴趣沟通。</p>
        </div>
      </div>
      <div class="left middle">
        <div class="top mbot">
          <div class="dwBg">
            <h4>圈子</h4>
            <div class="imgBg">
              <img class="img3" src="@/assets/images/type3.png" alt />
            </div>
          </div>
          <p>海南本地职场社交圈&海南行业圈子，用观点制造行业影响力。</p>
        </div>
        <div class="top">
          <div class="dwBg">
            <h4>活动</h4>
            <div class="imgBg">
              <img class="img4" src="@/assets/images/type2.png" alt />
            </div>
          </div>
          <p>线上交流还不够？那来一场职场奔现吧，说不定就有你的伯乐。</p>
        </div>
      </div>
      <div class="right">
        <h3>海南行业动态</h3>
        <h3>畅聊本地职场</h3>
        <p>围绕着职场总是有说不尽的话题，政策、落户、房产、招聘、老板、薪资、制度、福利等，在提供基本社交的基础上，提供社区的功能，在社区中用户可以加圈子发布内容，也可以浏览自己感兴趣的内容；用户和用户之间基于内容展开评论、点赞和转发等互动，扩大了内容吸收的范围，也满足了用户的分享欲和交流欲。</p>
      </div>
    </div>
    <!--  携手自贸港-共建海南新未来 -->
    <div class="menuBox4 bgColor">
      <h3>
        携手自贸港
        <span></span>共建海南新未来
      </h3>
      <p>人在海南将充分发挥自身产品优势，帮助海南职场人士和企业，打造职业形象、树立海南企业品牌，提前进行高效连接，收获职场机遇。面对变化，有备而来，携手共建海南自贸港新未来。</p>
      <div class="numsBox">
        <ul>
          <li>
            <div class="top">100+</div>
            <div class="bottom">覆盖行业</div>
          </li>
          <li>
            <div class="top">1528+</div>
            <div class="bottom">海量企业</div>
          </li>
          <li>
            <div class="top">2800+</div>
            <div class="bottom">人才银行</div>
          </li>
        </ul>
      </div>
      <div class="lxwm" @click="aboutUsUrl">联系我们</div>
      <div class="classifyBox">
        <ul>
          <li>IT互联网</li>
          <li>金融贸易</li>
          <li>文化传媒</li>
          <li>房地产</li>
          <li>医疗</li>
          <li>教育</li>
        </ul>
      </div>
    </div>
    <!-- 新闻资讯 -->
    <div class="menuBox5">
      <h3>新闻资讯</h3>
      <NewsInformation />
    </div>
    <!-- 马上免费使用 -->
    <div class="menuBox6">
      <img class="dbBanner" src="@/assets/images/banner6.png" alt />
      <img class="mask" src="@/assets/images/mask.png" alt />
      <div class="download">
        <div class="t1">你还在犹豫什么？</div>
        <div class="t2">马上免费使用！</div>
        <div class="modelFlex">
          <div class="modeList">
            <img src="@/assets/images/ios.png" alt />
            <div @click="showCode=true">人在海南IOS端</div>
          </div>
          <div class="modeList">
            <img src="@/assets/images/android.png" alt />
            <div @click="showCode=true">人在海南Android端</div>
          </div>
        </div>
      </div>
    </div>
    
    <!-- app下载二维码 -->
    <QrCode v-if="showCode" :isShow.sync="showCode" :url="'app'" />

    <!-- footerBox -->
    <div class="menuBox7">
      <FooterBox />
    </div>
  </div>
</template>

<script>
import HeadNav from "@/components/HeadNav.vue";
import NewsInformation from "@/components/NewsInformation.vue";
import FooterBox from "@/components/FooterBox.vue";
import QrCode from "@/components/qrCode.vue";

export default {
  name: "home",
  metaInfo: {
    title: "人在海南官网",
    meta: [
      {
        name: "keywords",
        content:
          "人在海南、人才引进、海南自贸港、自贸港政策、海南教育、自贸港、重点园区、海南人才落户、海南招聘、海南考试、海南公考、海南公务员、海南社保、海南公积金、海南房价、海南房产、海南摇号、海南免税店、海南电动车上牌、海南充电桩、海南医疗、乐城特药险、海南健康码、人在岛上、人在海上、岛内、岛外、海南职场、海南公司、海南产业园、海南高薪工作、海南招聘、海南社交、海南线上活动、海南线下活动、海聘、海脉圈、甘肃人在海南、广西人在海南、贵州人在海南、海南人在海南、黑龙江人在海南、吉林人在海南、辽宁人在海南、内蒙古人在海南、宁夏人在海南、青海人在海南、山西人在海南、西藏人在海南、云南人在海南、广东人在海南、北京人在海南、上海人在海南、东北人在海南、深圳人在海南，海聘，海聘圈，简历，公司企业，求职，职位，找工作，话题广场，圈子，个人简历，我的圈子，职场名片，我要求职"
      },
      {
        name: "description",
        content:
          "岛上生活-人在海南”项目致力于成为海南人才引进的窗口——海南本地职场社交平台，吸引各类优秀人才来海南发展创业，共享海南自由贸易港发展新机遇。用户可在平台上进行求职招聘、建立连接，关注好友的职业动态及互动，浏览本地各行业的项目介绍、企业展示、创始人访谈等，跟踪行业资讯和动态，留意职场的变化与新机会。通过搭建人才线上交流平台，营造相同职业、相同行业的人员聚集交流氛围，促进信息的流转和整体的海南职场交流效率。"
      }
    ]
  },
  components: {
    HeadNav,
    NewsInformation,
    FooterBox,
    QrCode
  },
  data() {
    return {
      showCode: false,
      navBarFixed: true,
      datas: [
        {
          title: "行业资讯",
          txt: "跟踪行业的资讯和动态，留意职场的变化和新机会。"
        },
        {
          title: "本地职场",
          txt:
            "建立海南职场人才沟通平台，促进职场人员交流，营造人才线上聚集交流，与线下职场联动。"
        },
        {
          title: "人才银行",
          txt:
            "建立人才银行，为企业提供人才库，为项目孵化中心储备足够的人才资源。"
        },
        {
          title: "拓展人脉",
          txt:
            "搭建人才线上交流平台，营造相同职业，相同行业的人员聚集交流氛围，促进信息的流转和整体的职场交流效率。"
        },
        {
          title: "创业窗口",
          txt:
            "需要找投资和找人才的用户也可以在相应的板块下获取资源，提供资源分享的平台。"
        },
        {
          title: "职场社交",
          txt:
            "用户在上面建立连接，关注好友的职业动态并互动，同时可以浏览本地各行业企业的项目介绍、企业展示、创始人访谈等。"
        }
      ]
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {
    // 事件监听滚动条
    window.addEventListener("scroll", this.watchScroll);
  },
  methods: {
    aboutUsUrl() {
      this.$router.push({ path: "/aboutUs" });
    },
    watchScroll() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      //  当滚动超过 50 时，实现吸顶效果
      if (scrollTop > 1000) {
        this.navBarFixed = false;
      } else {
        this.navBarFixed = true;
      }
    }
  }
};
</script>
<style lang="scss" >

.home {
  .navBarWrap {
    .navList {
      position: absolute;
      background: none;
      box-shadow: none;
      .logoFlex h1 {
        color: $clr_fff;
      }
      .type {
        ul {
          li {
            color: $clr_fff;
          }
          .current {
            color: $clr_fff;
            &::before {
              background: $clr_fff;
            }
          }
          li:hover {
            color: #eee;
          }
          .current:hover {
            color: $clr_fff;
          }
        }
        .btns {
          div {
            background: $bg_fff;
            color: $clr_son;
          }
        }
      }
    }
    .height {
      height: 0;
    }
  }

  .navBox {
    position: relative;
    .banner {
      width: $wd_1920;
      height: $he_1080;
    }
    .main {
      position: absolute;
      bottom: 21%;
      left: 0;
      width: $wd_1920;
      padding: 0 22% 0 17%;
      box-sizing: border-box;
      z-index: 99;
      @include flexBetween;
      height: 604px;
      .left {
        @include flexColumnJustify;
        width: 562px;
        cursor: pointer;
        .title {
          font-weight: bold;
          font-size: 48px;
          padding-top: 20px;
          color: $clr_fff;
        }
        p {
          font-size: $size_24;
          padding-top: 40px;
          line-height: 36px;
          color: $clr_fff;
        }
        .modelFlex {
          padding-top: 60px;
          transition: all 0.5s ease-out 0.1s;
          overflow: hidden;
          @include flexBetween;
          .modeList {
            border-radius: 60px;
            height: 70px;
            line-height: 70px;
            background: $bg_fff;
            width: 250px;
            padding: 0 30px;
            @include flexEnter;
            box-sizing: border-box;
            img {
              height: 50px;
              width: 42px;
            }
            div {
              text-align: center;
              color: $clr_son;
              font-size: $size_24;
              flex: 2;
            }
          }
        }
      }
      .right {
        width: 413px;
        height: 604px;
      }
    }
  }
  //
  .menuBox1 {
    padding: 80px 176px 260px;
    h3 {
      font-weight: 900;
      font-size: $size_36;
      text-align: center;
      padding-bottom: 360px;
    }
    .infoBox {
      width: 1568px;
      height: 320px;
      position: relative;
      .imgBox {
        height: 320px;
        width: 1568px;
        img {
          height: 320px;
          width: 1568px;
        }
        li {
          width: 200px;
          position: absolute;
          h3 {
            color: $clr_son;
            font-weight: bold;
            font-size: $size_24;
            text-align: center;
            padding-bottom: 15px;
          }
          p {
            font-size: $size_20;
            line-height: 36px;
            color: $clr_666;
          }
        }
        .list0 {
          width: 184px;
          left: 20px;
          bottom: -100px;
        }
        .list1 {
          width: 184px;
          left: 210px;
          top: -240px;
        }
        .list2 {
          left: 400px;
          bottom: -50px;
        }
        .list3 {
          left: 770px;
          top: -210px;
        }
        .list4 {
          left: 1010px;
          bottom: -120px;
        }
        .list5 {
          left: 1370px;
          top: -140px;
        }
      }
    }
  }
  //
  .menuBox2 {
    @include flexColumnAlign;
    padding: 60px 0;
    h3 {
      font-weight: 900;
      font-size: $size_36;
      padding-bottom: 20px;
    }
    h4 {
      font-weight: 900;
      font-size: $size_24;
      padding-bottom: 40px;
    }
    p {
      width: 1026px;
      color: $clr_666;
      font-size: $size_20;
      line-height: 36px;
      padding-bottom: 130px;
    }
    .imgFlex ul {
      @include flexEnter;
      li {
        width: 351px;
        height: 644px;
        position: relative;
        margin: 0 50px;
        .right {
          width: 351px;
          height: 644px;
        }
        .left {
          position: absolute;
          top: -53px;
          left: -30px;
          width: 100px;
          height: 100px;
          z-index: 2;
        }
        div {
          position: absolute;
          top: -42px;
          left: 0;
          font-size: $size_24;
          line-height: 36px;
          height: 36px;
          width: 351px;
          text-align: center;
        }
      }
    }
  }
  //
  .menuBox3 {
    @include flexEnter2;
    padding: 40px 0 120px 0;
    .left {
      margin-right: 50px;
      .top {
        width: 250px;
        height: 350px;
        border-radius: 20px;
        background: $bg_fff;
        box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.1);
        box-sizing: border-box;
        .dwBg {
          width: 100%;
          height: 130px;
          background: $bg_all;
          position: relative;
          border-radius: 20px 20px 0 0;
        }
        h4 {
          font-weight: 900;
          font-size: $size_24;
          padding-bottom: 40px;
          text-align: center;
          padding-top: 30px;
        }
        .imgBg {
          position: absolute;
          left: 0;
          right: 0;
          bottom: -50px;
          margin: auto;
          background: $bg_all;
          border: 4px solid #fff;
          box-sizing: border-box;
          border-radius: 50%;
          width: 96px;
          height: 96px;
          @include flexEnter;
          justify-content: center;
          img {
            width: 50px;
            height: 50px;
          }
          .img3 {
            width: 60px;
            height: 43px;
          }
          .img4 {
            width: 54px;
            height: 54px;
          }
        }
        p {
          @include flexColumnEnd;
          padding: 0 20px;
          font-size: $size_20;
          color: $clr_666;
          line-height: 36px;

          height: 190px;
        }
      }
      .mbot {
        margin-bottom: 50px;
      }
    }
    .middle {
      @include flexColumnEnd;
      margin-top: 150px;
    }
    .right {
      margin-left: 100px;
      h3 {
        font-weight: bold;
        font-size: $size_36;
        padding-bottom: 20px;
      }
      p {
        font-size: $size_20;
        line-height: 36px;
        padding-top: 30px;
        width: 444px;
        color: $clr_666;
      }
    }
  }
  //
  .menuBox4 {
    padding: 100px 13%;
    @include flexColumnAlign;
    h3 {
      font-size: $size_36;
      color: $clr_son;
      font-weight: bold;
      margin-bottom: 60px;
      span {
        margin: 0 10px;
      }
    }
    p {
      font-size: $size_20;
      color: $clr_666;
      line-height: 36px;
      width: 922px;
      margin-bottom: 110px;
    }
    .numsBox ul {
      @include flexBetween;
      margin-bottom: 130px;
      width: 922px;
      li {
        background: $bg_fff;
        border-radius: 20px;
        width: 250px;
        height: 220px;
        @include flexColumnAlign;
        justify-content: center;
        .top {
          color: $clr_son;
          font-weight: bold;
          font-size: 50px;
          padding-bottom: 40px;
        }
        .bottom {
          font-size: $size_20;
        }
      }
    }
    .lxwm {
      background: $bg_son;
      height: 60px;
      line-height: 60px;
      border-radius: 55px;
      width: 200px;
      text-align: center;
      color: $clr_fff;
      font-size: $size_20;
      margin-bottom: 130px;
      cursor: pointer;
    }
    .classifyBox {
      width: 100%;
      cursor: pointer;
      ul {
        @include flexBetween;
        li {
          color: $clr_son;
          font-weight: bold;
          font-size: $size_32;
        }
      }
    }
  }
  .menuBox5 {
    padding: 130px 6%;
    h3 {
      font-weight: 900;
      font-size: $size_36;
      padding-bottom: 100px;
      text-align: center;
    }
  }
  .menuBox6 {
    height: 700px;
    width: $wd_1920;
    position: relative;
    .dbBanner {
      height: 700px;
    }
    .mask {
      @include dwAbsolute;
      z-index: 2;
    }
    .download {
      padding: 100px 0 210px;
      box-sizing: border-box;
      @include dwAbsolute;
      z-index: 3;
      @include flexColumnAlign;
      .t1 {
        font-size: $size_32;
        color: $clr_fff;
        font-weight: bold;
        padding-bottom: 50px;
      }
      .t2 {
        font-size: 68px;
        color: $clr_fff;
        font-weight: bold;
        padding-bottom: 150px;
      }
      .modelFlex {
        @include flexBetween;
        cursor: pointer;
        .modeList {
          border-radius: 60px;
          height: 90px;
          line-height: 90px;
          background: $bg_fff;
          width: 350px;
          @include flexEnter2;
          box-sizing: border-box;
          margin: 0 30px;
          img {
            height: 40px;
            width: 40px;
            margin-right: 20px;
          }
          div {
            color: $clr_son;
            font-size: $size_24;
          }
        }
      }
    }
  }
}
</style>
