<template>
  <div class="privacyPolicy">
    <!-- 导航栏 -->
    <HeadNav pageName />
    <div class="xqzxBox">
<p>
    <span style=";font-family:宋体;font-size:14px">为保障</span><span style=";font-family:宋体;font-size:14px">人在海南</span><span style=";font-family:宋体;font-size:14px"><span style="font-family:Calibri">App</span><span style="font-family:宋体">相关功能的实现与应用安全稳定的运行，我们可能会接入由第三方提供的软件开发包（</span><span style="font-family:Calibri">SDK</span><span style="font-family:宋体">）实现相关目的。</span></span>
</p>
<p>
    <span style=";font-family:宋体;font-size:14px"><span style="font-family:宋体">我们会对合作方获取信息的软件工具开发包（</span><span style="font-family:Calibri">SDK</span><span style="font-family:宋体">）进行严格的安全监测，以保护数据安全。</span></span>
</p>
<p>
    <span style=";font-family:宋体;font-size:14px"><span style="font-family:宋体">我们对接入的相关第三方</span><span style="font-family:Calibri">SDK</span><span style="font-family:宋体">在目录中列明。</span></span>
</p>
<p>
    <span style=";font-family:宋体;font-size:14px"><span style="font-family:宋体">请注意，第三方</span><span style="font-family:Calibri">SDK</span><span style="font-family:宋体">可能因为其版本升级、策略调整等原因导致数据处理类型存在一定变化，请以其公示的官方说明为准。</span></span>
</p>
<p>
    <span style=";font-family:宋体;font-size:14px">&nbsp;</span>
</p>
<p>
    <span style=";font-family:宋体;font-size:14px"><span style="font-family:宋体">人在海南</span><span style="font-family:Calibri">APP</span><span style="font-family:宋体">应用集成第三方内容如下：</span></span>
</p>
<p>
    <span style="font-family:宋体;font-size:14px">1.&nbsp;</span><span style=";font-family:宋体;font-size:14px"><span style="font-family:宋体">第三方</span><span style="font-family:Calibri">SDK</span><span style="font-family:宋体">名称：极光</span></span>
</p>
<p style="text-indent:14px">
    <span style=";font-family:宋体;font-size:14px"><span style="font-family:宋体">第三方</span><span style="font-family:Calibri">SDK</span><span style="font-family:宋体">收集使用个人信息：</span></span><span style=";font-family:宋体;font-size:14px">收集了用户</span><span style=";font-family:宋体;font-size:14px">的</span><span style=";font-family:宋体;font-size:14px">手机号码</span>
</p>
<p style="text-indent:14px">
    <span style=";font-family:宋体;font-size:14px"><span style="font-family:宋体">第三方</span><span style="font-family:Calibri">SDK</span><span style="font-family:宋体">使用目的：</span></span><span style=";font-family:宋体;font-size:14px"><span style="font-family:Calibri">APP</span><span style="font-family:宋体">一键登录</span></span>
</p>
<p style="text-indent:14px">
    <span style=";font-family:宋体;font-size:14px">&nbsp;</span>
</p>
<p>
    <span style="font-family:宋体;font-size:14px">2.&nbsp;</span><span style=";font-family:宋体;font-size:14px"><span style="font-family:宋体">第三方</span><span style="font-family:Calibri">SDK</span><span style="font-family:宋体">名称：网易云信</span></span>
</p>
<p style="text-indent:14px">
    <span style=";font-family:宋体;font-size:14px"><span style="font-family:宋体">第三方</span><span style="font-family:Calibri">SDK</span><span style="font-family:宋体">收集使用个人信息：</span></span><span style=";font-family:宋体;font-size:14px">收集了用户</span><span style=";font-family:宋体;font-size:14px">的聊天记录</span>
</p>
<p style="text-indent:14px">
    <span style=";font-family:宋体;font-size:14px"><span style="font-family:宋体">第三方</span><span style="font-family:Calibri">SDK</span><span style="font-family:宋体">使用目的：</span><span style="font-family:Calibri">IM</span><span style="font-family:宋体">通讯聊天</span></span>
</p>
<p style="text-indent:14px">
    <span style=";font-family:宋体;font-size:14px">&nbsp;</span>
</p>
<p>
    <span style="font-family:宋体;font-size:14px">3.&nbsp;</span><span style=";font-family:宋体;font-size:14px"><span style="font-family:宋体">第三方</span><span style="font-family:Calibri">SDK</span><span style="font-family:宋体">名称：高德地图</span></span>
</p>
<p style="text-indent:14px">
    <span style=";font-family:宋体;font-size:14px"><span style="font-family:宋体">第三方</span><span style="font-family:Calibri">SDK</span><span style="font-family:宋体">收集使用个人信息：</span></span><span style=";font-family:宋体;font-size:14px">收集了用户</span><span style=";font-family:宋体;font-size:14px">的定位</span>
</p>
<p style="text-indent:14px">
    <span style=";font-family:宋体;font-size:14px"><span style="font-family:宋体">第三方</span><span style="font-family:Calibri">SDK</span><span style="font-family:宋体">使用目的：</span></span><span style=";font-family:宋体;font-size:14px">做定位和地图展示</span>
</p>
<p style="text-indent:14px">
    <span style=";font-family:Calibri;font-size:14px">&nbsp;</span>
</p>
<p>
    <span style="font-family:宋体;font-size:14px">4.&nbsp;</span><span style=";font-family:宋体;font-size:14px"><span style="font-family:宋体">第三方</span><span style="font-family:Calibri">SDK</span><span style="font-family:宋体">名称：支付宝</span></span>
</p>
<p style="text-indent:14px">
    <span style=";font-family:宋体;font-size:14px"><span style="font-family:宋体">第三方</span><span style="font-family:Calibri">SDK</span><span style="font-family:宋体">收集使用个人信息：</span></span><span style=";font-family:宋体;font-size:14px">收集了用户</span><span style=";font-family:宋体;font-size:14px">的身份信息</span>
</p>
<p style="text-indent:14px">
    <span style=";font-family:宋体;font-size:14px"><span style="font-family:宋体">第三方</span><span style="font-family:Calibri">SDK</span><span style="font-family:宋体">使用目的：</span></span><span style=";font-family:宋体;font-size:14px">做</span><span style=";font-family:宋体;font-size:14px">刷脸认证</span>
</p>
<p style="text-indent:14px">
    <span style=";font-family:Calibri;font-size:14px">&nbsp;</span>
</p>
<p>
    <span style="font-family:宋体;font-size:14px">5.&nbsp;</span><span style=";font-family:宋体;font-size:14px"><span style="font-family:宋体">第三方</span><span style="font-family:Calibri">SDK</span><span style="font-family:宋体">名称：小米推送</span></span>
</p>
<p style="text-indent:14px">
    <span style=";font-family:宋体;font-size:14px"><span style="font-family:宋体">第三方</span><span style="font-family:Calibri">SDK</span><span style="font-family:宋体">收集使用个人信息：收集了推送信息</span></span>
</p>
<p style="text-indent:14px">
    <span style=";font-family:宋体;font-size:14px"><span style="font-family:宋体">第三方</span><span style="font-family:Calibri">SDK</span><span style="font-family:宋体">使用目的：消息通知推送</span></span>
</p>
<p style="text-indent:14px">
    <span style=";font-family:Calibri;font-size:14px">&nbsp;</span>
</p>
<p>
    <span style="font-family:宋体;font-size:14px">6.&nbsp;</span><span style=";font-family:宋体;font-size:14px"><span style="font-family:宋体">第三方</span><span style="font-family:Calibri">SDK</span><span style="font-family:宋体">名称：华为推送、华为联运</span></span>
</p>
<p style="text-indent:14px">
    <span style=";font-family:宋体;font-size:14px"><span style="font-family:宋体">第三方</span><span style="font-family:Calibri">SDK</span><span style="font-family:宋体">收集使用个人信息：收集了推送信息</span></span>
</p>
<p style="text-indent:14px">
    <span style=";font-family:宋体;font-size:14px"><span style="font-family:宋体">第三方</span><span style="font-family:Calibri">SDK</span><span style="font-family:宋体">使用目的：消息通知推送</span></span>
</p>
<p style="text-indent:14px">
    <span style=";font-family:Calibri;font-size:14px">&nbsp;</span>
</p>
<p style="text-indent:14px">
    <span style=";font-family:Calibri;font-size:14px">&nbsp;</span>
</p>
<p>
    <span style="font-family:宋体;font-size:14px">7.&nbsp;</span><span style=";font-family:宋体;font-size:14px"><span style="font-family:宋体">第三方</span><span style="font-family:Calibri">SDK</span><span style="font-family:宋体">名称：友盟</span></span>
</p>
<p style="text-indent:14px">
    <span style=";font-family:宋体;font-size:14px"><span style="font-family:宋体">第三方</span><span style="font-family:Calibri">SDK</span><span style="font-family:宋体">收集使用个人信息：收集了推送信息</span></span>
</p>
<p style="text-indent:14px">
    <span style=";font-family:宋体;font-size:14px"><span style="font-family:宋体">第三方</span><span style="font-family:Calibri">SDK</span><span style="font-family:宋体">使用目的：消息通知推送与页面统计</span></span>
</p>
<p style="text-indent:14px">
    <span style=";font-family:宋体;font-size:14px">&nbsp;</span>
</p>
<p>
    <span style="font-family:宋体;font-size:14px">8.&nbsp;</span><span style=";font-family:宋体;font-size:14px"><span style="font-family:宋体">第三方</span><span style="font-family:Calibri">SDK</span><span style="font-family:宋体">名称：</span><span style="font-family:Calibri">OPPO</span><span style="font-family:宋体">推送</span></span>
</p>
<p style="text-indent:14px">
    <span style=";font-family:宋体;font-size:14px"><span style="font-family:宋体">第三方</span><span style="font-family:Calibri">SDK</span><span style="font-family:宋体">收集使用个人信息：收集了推送信息</span></span>
</p>
<p style="text-indent:14px">
    <span style=";font-family:宋体;font-size:14px"><span style="font-family:宋体">第三方</span><span style="font-family:Calibri">SDK</span><span style="font-family:宋体">使用目的：消息通知推送</span></span>
</p>
<p style="text-indent:14px">
    <span style=";font-family:Calibri;font-size:14px">&nbsp;</span>
</p>
<p>
    <span style="font-family:宋体;font-size:14px">9.&nbsp;</span><span style=";font-family:宋体;font-size:14px"><span style="font-family:宋体">第三方</span><span style="font-family:Calibri">SDK</span><span style="font-family:宋体">名称：</span></span><span style=";font-family:Calibri;font-size:14px">VIVO</span><span style=";font-family:宋体;font-size:14px">推送</span>
</p>
<p style="text-indent:14px">
    <span style=";font-family:宋体;font-size:14px"><span style="font-family:宋体">第三方</span><span style="font-family:Calibri">SDK</span><span style="font-family:宋体">收集使用个人信息：收集了推送信息</span></span>
</p>
<p style="text-indent:14px">
    <span style=";font-family:宋体;font-size:14px"><span style="font-family:宋体">第三方</span><span style="font-family:Calibri">SDK</span><span style="font-family:宋体">使用目的：消息通知推送</span></span>
</p>
<p style="text-indent:14px">
    <span style=";font-family:宋体;font-size:14px">&nbsp;</span>
</p>
<p>
    <span style="font-family:宋体;font-size:14px">10.&nbsp;</span><span style=";font-family:宋体;font-size:14px"><span style="font-family:宋体">第三方</span><span style="font-family:Calibri">SDK</span><span style="font-family:宋体">名称：</span></span><span style=";font-family:Calibri;font-size:14px"><span style="font-family:宋体">魅族</span></span><span style=";font-family:宋体;font-size:14px">推送</span>
</p>
<p style="text-indent:14px">
    <span style=";font-family:宋体;font-size:14px"><span style="font-family:宋体">第三方</span><span style="font-family:Calibri">SDK</span><span style="font-family:宋体">收集使用个人信息：收集了推送信息</span></span>
</p>
<p style="text-indent:14px">
    <span style=";font-family:宋体;font-size:14px"><span style="font-family:宋体">第三方</span><span style="font-family:Calibri">SDK</span><span style="font-family:宋体">使用目的：消息通知推送</span></span>
</p>
<p style="text-indent:14px">
    <span style=";font-family:Calibri;font-size:14px">&nbsp;</span>
</p>
<p>
    <span style="font-family:宋体;font-size:14px">11.&nbsp;</span><span style=";font-family:宋体;font-size:14px"><span style="font-family:宋体">第三方</span><span style="font-family:Calibri">SDK</span><span style="font-family:宋体">名称：</span></span><span style=";font-family:Calibri;font-size:14px"><span style="font-family:宋体">神策</span></span><span style=";font-family:宋体;font-size:14px">埋点</span>
</p>
<p style="text-indent:14px">
    <span style=";font-family:宋体;font-size:14px"><span style="font-family:宋体">第三方</span><span style="font-family:Calibri">SDK</span><span style="font-family:宋体">收集使用个人信息：采集您的 IDFA 信息</span></span>
</p>
<p style="text-indent:14px">
    <span style=";font-family:宋体;font-size:14px"><span style="font-family:宋体">第三方</span><span style="font-family:Calibri">SDK</span><span style="font-family:宋体">使用目的：用于统计分析您在 App 内的使用效果</span></span>
</p>
<p style="text-indent:14px">
    <span style=";font-family:Calibri;font-size:14px">&nbsp;</span>
</p>
<p style="text-indent:14px">
    <span style=";font-family:Calibri;font-size:14px">&nbsp;</span>
</p>
<p>
    <br/>
</p>
    </div>
    <!-- footerBox -->
    <div class="gwFooter">
      <FooterBox />
    </div>
  </div>
</template>

<script>
import HeadNav from "@/components/HeadNav.vue";
import FooterBox from "@/components/FooterBox.vue";
export default {
  name: "privacyPolicy",
  components: {
    HeadNav,
    FooterBox
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    sbqxlbUrl() {
      this.$router.push({ path: "/sbqxlb" });
    },
    sdkUrl() {
      this.$router.push({ path: "/sdk" });
    }
  }
};
</script>
<style lang="scss">
</style>