<template>
  <div class="communityNorms">
    <!-- 导航栏 -->
    <HeadNav pageName />
    <div class="xqzxBox">
      <pre>
<p class="titles"><span style="font-family: 宋体;">《社区管理规范》</span></p>
<p style="text-indent:0;text-autospace:ideograph-numeric;text-align:center"><span style="font-family: 宋体;color: rgb(255, 0, 0);font-size: 14px">&nbsp;</span></p>
<p style="text-indent: 0"><span style="font-family: 宋体;font-size: 14px">人在海南</span><span
  style="font-family: 宋体;font-size: 14px"
>的使命：鼓励创造，多元生活。我们一直致力于提供一个幸福的高质量好玩有趣的生活分享平台，全面提升用户获得感、幸福感和安全感。</span></p><p
  style="text-indent: 0"
><span style="font-family: 宋体;font-size: 14px">&nbsp;</span></p><p
  style="text-indent: 0"
><span style="font-family: 宋体;font-size: 14px">人在海南</span><span
  style="font-family: 宋体;font-size: 14px"
><span style="font-family:宋体">坚持和弘扬社会主义核心价值观，宣扬中华民族传统文化和美德，鼓励积极向上的生活方式，不断传递正能量。</span> <span style="font-family:宋体">作为一个创造、分享型的网络社区，维护好平台的健康生态，营造良好的社区氛围，离不开大家的共同努力。</span></span></p><p
  style="text-indent: 0"
><span style="font-family: 宋体;font-size: 14px">&nbsp;</span></p><p
  style="text-indent: 0"
><span style="font-family: 宋体;font-size: 14px">我们为此修订了</span><span
  style="font-family: 宋体;font-size: 14px"
>人在海南</span><span
  style="font-family: 宋体;font-size: 14px"
><span style="font-family:宋体">《社区管理规范》（以下简称</span>“本规范”）由</span><span
  style="font-family: 宋体;font-size: 14px"
>人在海南</span><span
  style="font-family: 宋体;font-size: 14px"
>和所有</span><span
  style="font-family: 宋体;font-size: 14px"
>人在海南</span><span
  style="font-family: 宋体;font-size: 14px"
>用户共同遵守！</span></p><p
  style="text-indent: 0"
><strong><span style="font-family: 宋体;font-size: 14px">&nbsp;</span></strong></p><p
  style="text-indent:0;text-autospace:ideograph-numeric;text-align:center"
><strong><span style="font-family: 宋体;font-size: 19px">共同遵守的社群管理行为准则</span></strong></p><p
  style="text-indent:0;text-autospace:ideograph-numeric;text-align:center"
><strong><span style="font-family: 宋体;font-size: 19px">&nbsp;</span></strong></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><strong><span style="font-family: 宋体;font-size: 14px"><span style="font-family:宋体">第一章</span> <span style="font-family:宋体">总则</span>&nbsp;</span></strong></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><span style=";font-family:宋体;font-size:14px">为了保护用户创造的内容、鼓励平台内容的多元性、维护良好的社区环境</span><span
  style=";font-family:宋体;font-size:14px"
>，</span><span
  style=";font-family:宋体;font-size:14px"
>人在海南</span><span
  style=";font-family:宋体;font-size:14px"
>平台依据法律法规包括但不限于《中华人民共和国网络安全法》、《互联网信息服务管理办法》、《互联网直播服务管理规定》、《互联网危险物品信息发布管理规定》、《网络短视频内容审核标准细则》、《网络信息内容生态治理规定》等相关内容的规定</span><span
  style=";font-family:宋体;font-size:14px"
>，</span><span
  style=";font-family:宋体;font-size:14px"
>对用户发布的信息和用户行为</span><span
  style=";font-family:宋体;font-size:14px"
>，</span><span
  style=";font-family:宋体;font-size:14px"
>包括但不限于短视频、图片、标题、评论</span><span
  style=";font-family:宋体;font-size:14px"
>、</span><span
  style=";font-family:宋体;font-size:14px"
>背景音、表情包等</span><span
  style=";font-family:宋体;font-size:14px"
>，</span><span
  style=";font-family:宋体;font-size:14px"
>进行合规审核和管理。</span></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><span style=";font-family:宋体;font-size:14px">&nbsp;</span></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><strong><span style="font-family: 宋体;font-size: 14px"><span style="font-family:宋体">第二章</span> <span style="font-family:宋体">基本原则</span>&nbsp;</span></strong></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><span style=";font-family:宋体;font-size:14px">一、本规范适用于</span><span
  style=";font-family:宋体;font-size:14px"
>人在海南</span><span
  style=";font-family:宋体;font-size:14px"
>所有用户。</span></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><span style=";font-family:宋体;font-size:14px">二、</span><span
  style=";font-family:宋体;font-size:14px"
>人在海南</span><span
  style=";font-family:宋体;font-size:14px"
>社区管理中心</span><span
  style=";font-family:宋体;font-size:14px"
>（</span><span style=";font-family:宋体;font-size:14px"><span style="font-family:宋体">以下简称</span>“</span><span
  style=";font-family:宋体;font-size:14px"
>人在海南</span><span
  style=";font-family:宋体;font-size:14px"
><span style="font-family:宋体">团队</span>” </span><span
  style=";font-family:宋体;font-size:14px"
>）</span><span
  style=";font-family:宋体;font-size:14px"
>将根据现行法律法规和</span><span
  style=";font-family:宋体;font-size:14px"
>人在海南</span><strong><span style="font-family: 宋体;color: rgb(190, 143, 0);font-size: 14px"><span style="font-family:宋体">《社区管理规范》</span> </span></strong><span
  style=";font-family:宋体;font-size:14px"
>（</span><span style=";font-family:宋体;font-size:14px"><span style="font-family:宋体">以下简称</span>“本规范” </span><span
  style=";font-family:宋体;font-size:14px"
>）</span><span
  style=";font-family:宋体;font-size:14px"
>中的条款对</span><span
  style=";font-family:宋体;font-size:14px"
>人在海南</span><span
  style=";font-family:宋体;font-size:14px"
>的用户和发布在</span><span
  style=";font-family:宋体;font-size:14px"
>人在海南</span><span
  style=";font-family:宋体;font-size:14px"
>的内容进行管理。采取的措施包括但不限于更改或删除用户发布的内容、暂停或终止用户使用</span><span
  style=";font-family:宋体;font-size:14px"
>人在海南</span><span
  style=";font-family:宋体;font-size:14px"
>的权利。</span></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><span style=";font-family:宋体;font-size:14px">三、</span><span
  style=";font-family:宋体;font-size:14px"
>人在海南</span><span
  style=";font-family:宋体;font-size:14px"
>团队通过主动发现和接受用户举报两种方式发现违规行为。</span></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><span style=";font-family:宋体;font-size:14px">四、违规处理的方式包括内容处理、帐号处理和</span><span
  style=";font-family:宋体;font-size:14px"
>其他</span><span
  style=";font-family:宋体;font-size:14px"
>处理</span><span
  style=";font-family:宋体;font-size:14px"
>：</span></p><p
  style="margin-left:28px;text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family:宋体;font-size:14px">1.&nbsp;</span><span
  style=";font-family:宋体;font-size:14px"
>内容处理包括</span><span
  style=";font-family:宋体;font-size:14px"
>：</span><span
  style=";font-family:宋体;font-size:14px"
>禁止被评论、限制播放范围、删除等；</span></p><p
  style="margin-left:28px;text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family:宋体;font-size:14px">2.&nbsp;</span><span
  style=";font-family:宋体;font-size:14px"
>帐号处理包括：禁言、禁被关注、限制播放范围、冻结等</span><span
  style=";font-family:宋体;font-size:14px"
>；</span></p><p
  style="margin-left:28px;text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family:宋体;font-size:14px">3.&nbsp;</span><span
  style=";font-family:宋体;font-size:14px"
>其他</span><span
  style=";font-family:宋体;font-size:14px"
>处理包括</span><span
  style=";font-family:宋体;font-size:14px"
>：</span><span
  style=";font-family:宋体;font-size:14px"
>限制权限、冻结</span><span
  style=";font-family:宋体;font-size:14px"
>，视频下架，动态内容下架</span><span
  style=";font-family:宋体;font-size:14px"
>等。</span></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><span style=";font-family:宋体;font-size:14px">五、用户访问或使用我方</span><span
  style=";font-family:宋体;font-size:14px"
>所有</span><span
  style=";font-family:宋体;font-size:14px"
>产品及网站</span><span
  style=";font-family:宋体;font-size:14px"
>，</span><span style=";font-family:宋体;font-size:14px"><span style="font-family:宋体">即视为同意接受本规范的全部内容。本管理规范中所称</span>“社区”“</span><span
  style=";font-family:宋体;font-size:14px"
>人在海南</span><span
  style=";font-family:宋体;font-size:14px"
><span style="font-family:宋体">社区</span>”指</span><span
  style=";font-family:宋体;font-size:14px"
>：</span><span
  style=";font-family:宋体;font-size:14px"
>人在海南</span><span
  style=";font-family:宋体;font-size:14px"
>网站</span><span
  style=";font-family:宋体;font-size:14px"
>（</span><span
  style=";font-family:宋体;font-size:14px"
>域名为</span><span
  style=";font-family:宋体;font-size:14px"
>：</span><strong><span style="font-family: 宋体;color: rgb(190, 143, 0);font-size: 14px">www.renzaihainan.com</span></strong><span
  style=";font-family:宋体;font-size:14px"
>）</span><span
  style=";font-family:宋体;font-size:14px"
>及相关客户端</span><span
  style=";font-family:宋体;font-size:14px"
>（</span><span
  style=";font-family:宋体;font-size:14px"
>app</span><span
  style=";font-family:宋体;font-size:14px"
>）</span><span style=";font-family:宋体;font-size:14px">。</span></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><span style=";font-family:宋体;font-size:14px">&nbsp;</span></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family:宋体;font-weight:bold;font-size:14px">第三章&nbsp;</span><strong><span style="font-family: 宋体;font-size: 14px">人在海南</span></strong><strong><span style="font-family: 宋体;font-size: 14px">平台禁止以下行为</span></strong></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><strong><span style="font-family: 宋体;letter-spacing: 0;font-size: 14px">一、危害国家及社会安全行为</span></strong></p><p
  style="margin-left:28px;text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family:宋体;letter-spacing:0;font-size:14px">1.&nbsp;</span><span
  style=";font-family:宋体;letter-spacing:0;font-size:14px"
>反对宪法所确定的基本原则；</span></p><p
  style="margin-left:28px;text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family:宋体;letter-spacing:0;font-size:14px">2.&nbsp;</span><span
  style=";font-family:宋体;letter-spacing:0;font-size:14px"
>危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一；</span></p><p
  style="margin-left:28px;text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family:宋体;font-size:14px">3.&nbsp;</span><span
  style=";font-family:宋体;font-size:14px"
>损害国家尊严、荣誉和利益</span><span
  style=";font-family:宋体;font-size:14px"
>；</span></p><p
  style="margin-left:28px;text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family:宋体;font-size:14px">4.&nbsp;</span><span
  style=";font-family:宋体;font-size:14px"
>煽动民族仇恨、民族歧视</span><span
  style=";font-family:宋体;font-size:14px"
>，</span><span
  style=";font-family:宋体;font-size:14px"
>歪曲民族历史</span><span
  style=";font-family:宋体;font-size:14px"
>，</span><span
  style=";font-family:宋体;font-size:14px"
>破坏民族团结</span><span
  style=";font-family:宋体;font-size:14px"
>；</span></p><p
  style="margin-left:28px;text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family:宋体;letter-spacing:0;font-size:14px">5.&nbsp;</span><span
  style=";font-family:宋体;letter-spacing:0;font-size:14px"
><span style="font-family:宋体">违背</span>/破坏国家宗教政策，损害宗教和睦与团结，宣扬邪教、封建迷信、伪科学；</span></p><p
  style="margin-left:28px;text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family:宋体;letter-spacing:0;font-size:14px">6.&nbsp;</span><span
  style=";font-family:宋体;letter-spacing:0;font-size:14px"
><span style="font-family:宋体">破坏社会稳定、影响</span>/扰乱公共秩序与公共安全；</span></p><p
  style="margin-left:28px;text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family:宋体;font-size:14px">7.&nbsp;</span><span
  style=";font-family:宋体;font-size:14px"
>捕猎、杀害、贩卖、非法饲养、食用野生及保护动植物</span><span
  style=";font-family:宋体;font-size:14px"
>；</span><span
  style=";font-family:宋体;font-size:14px"
>制作、加工、买卖保护动植物相关制品</span><span
  style=";font-family:宋体;font-size:14px"
>；</span><span
  style=";font-family:宋体;font-size:14px"
>制作、销售狩猎工具</span><span
  style=";font-family:宋体;font-size:14px"
>；</span><span
  style=";font-family:宋体;font-size:14px"
>踩踏、破坏保护植被或自然保护区等；</span></p><p
  style="margin-left:28px;text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family:宋体;font-size:14px">8.&nbsp;</span><span
  style=";font-family:宋体;font-size:14px"
>刻画、涂污、破坏国家保护文物、名胜古迹</span><span
  style=";font-family:宋体;font-size:14px"
>；</span><span
  style=";font-family:宋体;font-size:14px"
>盗墓探宝，盗挖、销售文物等</span><span
  style=";font-family:宋体;font-size:14px"
>；</span></p><p
  style="margin-left:28px;text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family:宋体;font-size:14px">9.&nbsp;</span><span
  style=";font-family:宋体;font-size:14px"
>其他违反法律法规、国家政策条例及任何具有法律效力规范的相关内容</span><span
  style=";font-family:宋体;font-size:14px"
>；</span></p><p
  style="margin-left:28px;text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family:宋体;letter-spacing:0;font-size:14px">10.&nbsp;</span><span
  style=";font-family:宋体;letter-spacing:0;font-size:14px"
>侮辱或者诽谤他人，侵害他人名誉、隐私和其他合法权益等等；</span></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><strong><span style="font-family: 宋体;letter-spacing: 0;font-size: 14px">二、开展、传播违法犯罪行为</span></strong></p><p
  style="margin-left:28px;text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family:宋体;letter-spacing:0;font-size:14px">1.&nbsp;</span><span
  style=";font-family:宋体;letter-spacing:0;font-size:14px"
>损害党和国家尊严与利益，破坏领导人、革命领袖、英雄烈士名誉及形象；</span></p><p
  style="margin-left:28px;text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family:宋体;letter-spacing:0;font-size:14px">2.&nbsp;</span><span
  style=";font-family:宋体;letter-spacing:0;font-size:14px"
>以损毁、践踏、恶搞等方式，侮辱国旗、国徽、国歌及其他国家标志；</span></p><p
  style="margin-left:28px;text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family:宋体;letter-spacing:0;font-size:14px">3.&nbsp;</span><span
  style=";font-family:宋体;letter-spacing:0;font-size:14px"
><span style="font-family:宋体">宣扬暴力、恐怖、极端主义，煽动实施暴力、恐怖、</span> <span style="font-family:宋体">极端主义活动；</span></span></p><p
  style="margin-left:28px;text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family:宋体;letter-spacing:0;font-size:14px">4.&nbsp;</span><span
  style=";font-family:宋体;letter-spacing:0;font-size:14px"
>歪曲、丑化、亵渎、否定英雄烈士及其事迹和精神；</span></p><p
  style="margin-left:28px;text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family:宋体;letter-spacing:0;font-size:14px">5.&nbsp;</span><span
  style=";font-family:宋体;letter-spacing:0;font-size:14px"
>利用领导人、革命领袖、英雄烈士形象或信息牟利，侵害其名誉及权益；</span></p><p
  style="margin-left:28px;text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family:宋体;letter-spacing:0;font-size:14px">6.&nbsp;</span><span
  style=";font-family:宋体;letter-spacing:0;font-size:14px"
><span style="font-family:宋体">传播淫秽、色情、赌博、毒品、暴力、凶杀、恐怖、</span> <span style="font-family:宋体">教唆犯罪等行为；</span></span></p><p
  style="margin-left:28px;text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family:宋体;letter-spacing:0;font-size:14px">7.&nbsp;</span><span
  style=";font-family:宋体;letter-spacing:0;font-size:14px"
>传播恐怖主义、极端主义内容或煽动实施恐怖主义及暴力犯罪活动的内容；</span></p><p
  style="margin-left:28px;text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family:宋体;letter-spacing:0;font-size:14px">8.&nbsp;</span><span
  style=";font-family:宋体;letter-spacing:0;font-size:14px"
>泄漏国家各级党政机关、科研机构、军事机构等未经公开的会议、文件、决策、科研成果等保密信息；</span></p><p
  style="margin-left:28px;text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family:宋体;letter-spacing:0;font-size:14px">9.&nbsp;</span><span
  style=";font-family:宋体;letter-spacing:0;font-size:14px"
>恶意抹黑、辱骂、攻击人民军队、国安、警察、行政、司法等国家公务人员和共产党员形象的内容；</span></p><p
  style="margin-left:28px;text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family:宋体;letter-spacing:0;font-size:14px">10.&nbsp;</span><span
  style=";font-family:宋体;letter-spacing:0;font-size:14px"
>侵占、破坏、污损英雄烈士纪念设施，在纪念英雄烈士环境中举办娱乐化活动，破坏纪念环境和氛围</span></p><p
  style="margin-left:28px;text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family:宋体;letter-spacing:0;font-size:14px">11.&nbsp;</span><span
  style=";font-family:宋体;letter-spacing:0;font-size:14px"
>伪造、售卖、恶意损坏、恶搞、涂鸦人民币；</span></p><p
  style="margin-left:28px;text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family:宋体;letter-spacing:0;font-size:14px">12.&nbsp;</span><span
  style=";font-family:宋体;letter-spacing:0;font-size:14px"
>违法开展募集、募捐活动；</span></p><p
  style="margin-left:28px;text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family:宋体;letter-spacing:0;font-size:14px">13.&nbsp;</span><span
  style=";font-family:宋体;letter-spacing:0;font-size:14px"
>群架斗殴、欺凌霸凌等违反治安管理的内容；</span></p><p
  style="margin-left:28px;text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family:宋体;letter-spacing:0;font-size:14px">14.&nbsp;</span><span
  style=";font-family:宋体;letter-spacing:0;font-size:14px"
>利用</span><span
  style=";font-family:宋体;letter-spacing:0;font-size:14px"
>人在海南</span><span
  style=";font-family:宋体;letter-spacing:0;font-size:14px"
>平台实施诈骗、传销、走私等违法犯罪行为；</span></p><p
  style="margin-left:28px;text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family:宋体;letter-spacing:0;font-size:14px">15.&nbsp;</span><span
  style=";font-family:宋体;letter-spacing:0;font-size:14px"
><span style="font-family:宋体">违法销售任何国家法律法规禁止或限制销售的商品</span>/服务，包括但不限于枪支弹药、爆炸物、管制刀具、生动 植物' 医疗服务、药物和保健品、三无产品、烟草等。</span></p><p
  style=";text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family:宋体;letter-spacing:0;font-weight:bold;font-size:14px">三、</span><strong><span style="font-family: 宋体;letter-spacing: 0;font-size: 14px">危及未成年人安全行为</span></strong></p><p
  style="margin-left:28px;text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family:宋体;letter-spacing:0;font-size:14px">1.&nbsp;</span><span
  style=";font-family:宋体;letter-spacing:0;font-size:14px"
>人在海南</span><span
  style=";font-family:宋体;letter-spacing:0;font-size:14px"
>平台禁止任何下列损害未成年人身心健康和合法权益的行为和内容：</span></p><p
  style="margin-left:0;text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family:宋体;letter-spacing:0;font-size:14px">①　</span><span
  style=";font-family:宋体;letter-spacing:0;font-size:14px"
>涉及未成年人性侵害、未成年人的裸露、色情及性行为；</span></p><p
  style="margin-left:0;text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family:宋体;letter-spacing:0;font-size:14px">②　</span><span
  style=";font-family:宋体;letter-spacing:0;font-size:14px"
>涉及残害、虐待、体罚、侮辱未成年人；</span></p><p
  style="margin-left:0;text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family:宋体;letter-spacing:0;font-size:14px">③　</span><span
  style=";font-family:宋体;letter-spacing:0;font-size:14px"
>展示校园霸凌、</span><span
  style=";font-family:宋体;font-size:14px"
>欺侮、殴打、歧视、孤立</span><span
  style=";font-family:宋体;letter-spacing:0;font-size:14px"
>或有未成年人参与的打架斗殴；</span></p><p
  style="margin-left:0;text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family:宋体;letter-spacing:0;font-size:14px">④　</span><span
  style=";font-family:宋体;letter-spacing:0;font-size:14px"
>展示</span><span
  style=";font-family:宋体;font-size:14px"
>威胁、恐吓、肋迫、侵害、猥亵未成年人</span><span
  style=";font-family:宋体;letter-spacing:0;font-size:14px"
>行为；</span></p><p
  style="margin-left:0;text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family:宋体;letter-spacing:0;font-size:14px">⑤　</span><span
  style=";font-family:宋体;letter-spacing:0;font-size:14px"
>推销或演示可能危害未成年人人身安全或身心健康的玩具物品、暴力漫画等；</span></p><p
  style="margin-left:0;text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family:宋体;letter-spacing:0;font-size:14px">⑥　</span><span
  style=";font-family:宋体;letter-spacing:0;font-size:14px"
>由未成年人展示地可能造成人身伤害的危险行为、恶作剧等；</span></p><p
  style="margin-left:0;text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family:宋体;letter-spacing:0;font-size:14px">⑦　</span><span
  style=";font-family:宋体;letter-spacing:0;font-size:14px"
>未成年人饮酒、吸烟、吸毒、纹身、出入酒吧、棋牌室等营业性娱乐场所；</span></p><p
  style="margin-left:0;text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family:宋体;font-size:14px">⑧　</span><span
  style=";font-family:宋体;font-size:14px"
>未成年人实施或参与实施的影响个人人身安全和健康的危险行为</span><span
  style=";font-family:宋体;font-size:14px"
>，</span><span
  style=";font-family:宋体;font-size:14px"
>如野泳、跳水、高空攀爬等</span><span
  style=";font-family:宋体;font-size:14px"
>；</span></p><p
  style="margin-left:0;text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family:宋体;letter-spacing:0;font-size:14px">⑨　</span><span
  style=";font-family:宋体;letter-spacing:0;font-size:14px"
>披露未成年人的个人隐私或有损未成年人人格尊严；</span></p><p
  style="margin-left:0;text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family:宋体;letter-spacing:0;font-size:14px">⑩　</span><span
  style=";font-family:宋体;letter-spacing:0;font-size:14px"
>展示未成年人婚育、早恋的内容；</span></p><p
  style="margin-left:0;text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family:宋体;letter-spacing:0;font-size:14px">⑪　</span><span
  style=";font-family:宋体;letter-spacing:0;font-size:14px"
>宣扬和鼓励未成年人厌学、弃学的内容；</span></p><p
  style="margin-left:0;text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family:宋体;letter-spacing:0;font-size:14px">⑫　</span><span
  style=";font-family:宋体;letter-spacing:0;font-size:14px"
><span style="font-family:宋体">通过歪曲和恶搞经典卡通形象向未成年人展示暴力、</span> <span style="font-family:宋体">色情等可能影响未成年人身心健康的其他文艺作品；</span></span></p><p
  style="margin-left:0;text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family:宋体;letter-spacing:0;font-size:14px">⑬　</span><span
  style=";font-family:宋体;letter-spacing:0;font-size:14px"
>过度消费未成年群体，或恶意利用未成年人形象进行炒作、博眼球的内容；</span></p><p
  style="margin-left:0;text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family:宋体;letter-spacing:0;font-size:14px">⑭　</span><span
  style=";font-family:宋体;letter-spacing:0;font-size:14px"
>其他危害未成年人人身安全和健康的内容。</span></p><p
  style="margin-left:28px;text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family:宋体;letter-spacing:0;font-size:14px">2.&nbsp;</span><span
  style=";font-family:宋体;letter-spacing:0;font-size:14px"
>未成年人在</span><span
  style=";font-family:宋体;letter-spacing:0;font-size:14px"
>人在海南</span><span
  style=";font-family:宋体;letter-spacing:0;font-size:14px"
>平台注册帐号应当得到其监护人的同意，否则监护人可以通过合理方式通知</span><span
  style=";font-family:宋体;letter-spacing:0;font-size:14px"
>人在海南</span><span
  style=";font-family:宋体;letter-spacing:0;font-size:14px"
>平台处理相关帐号。</span></p><p
  style="margin-left:28px;text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family:宋体;letter-spacing:0;font-size:14px">3.&nbsp;</span><span
  style=";font-family:宋体;letter-spacing:0;font-size:14px"
>未成年人帐号将被限制特定民事行为权利，包括但不限于平台交易或付费打赏等行为。</span></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><span style=";font-family:宋体;letter-spacing:0;font-size:14px">※</span><span
  style=";font-family:宋体;letter-spacing:0;font-size:14px"
>人在海南</span><span
  style=";font-family:宋体;letter-spacing:0;font-size:14px"
>平台立足于未成年人保护，目前已推出了青少年模式、青少年守护官等一系列保护机制，为未成年人在内容使用上的安全与健康提供保障。</span></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><strong><span style="font-family: 宋体;letter-spacing: 0;font-size: 14px">四、涉及危险行为、易造成人身伤害</span></strong></p><p
  style="margin-left:28px;text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family:宋体;letter-spacing:0;font-size:14px">1.&nbsp;</span><span
  style=";font-family:宋体;letter-spacing:0;font-size:14px"
>展现厌世、轻生情绪等悲观消极内容；</span></p><p
  style="margin-left:28px;text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family:宋体;letter-spacing:0;font-size:14px">2.&nbsp;</span><span
  style=";font-family:宋体;letter-spacing:0;font-size:14px"
><span style="font-family:宋体">展示自残、自虐、自杀行为，突出聚焦伤痕、伤疤、</span> <span style="font-family:宋体">伤口，开展自杀游戏等；</span></span></p><p
  style="margin-left:28px;text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family:宋体;letter-spacing:0;font-size:14px">3.&nbsp;</span><span
  style=";font-family:宋体;letter-spacing:0;font-size:14px"
>描述、演绎、宣传、美化自我伤害或饮食障碍的内容；</span></p><p
  style="margin-left:28px;text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family:宋体;letter-spacing:0;font-size:14px">4.&nbsp;</span><span
  style=";font-family:宋体;letter-spacing:0;font-size:14px"
><span style="font-family:宋体">展示、实施危险行为，包括但不限于：危险驾驶、含有安全隐患的玩具</span>/道具、易导致危险结果的内容；</span></p><p
  style="margin-left:28px;text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family:宋体;letter-spacing:0;font-size:14px">5.&nbsp;</span><span
  style=";font-family:宋体;letter-spacing:0;font-size:14px"
>传播血腥暴力惊悚的游戏、图片、视频等引人不适的内容。</span></p><p
  style=";text-indent:0;text-autospace:ideograph-numeric"
><strong><span style="font-family: 宋体;letter-spacing: 0;font-size: 14px">五、违背诚信与真实性</span></strong></p><p
  style="margin-left:28px;text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family: 宋体;letter-spacing: 0;font-size: 14px">1.&nbsp;</span><span
  style="font-family: 宋体;letter-spacing: 0;font-size: 14px"
>侵犯他人名誉权、肖像权、隐私权、专利収、著作权、商标权等合法权益；</span></p><p
  style="margin-left:28px;text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family: 宋体;letter-spacing: 0;font-size: 14px">2.&nbsp;</span><span
  style="font-family: 宋体;letter-spacing: 0;font-size: 14px"
>未经授权使用他人商号、商标和标识；</span></p><p
  style="margin-left:28px;text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family: 宋体;letter-spacing: 0;font-size: 14px">3.&nbsp;</span><span
  style="font-family: 宋体;letter-spacing: 0;font-size: 14px"
>散播虚假、谣言等不实、误导性信息；</span></p><p
  style="margin-left:28px;text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family: 宋体;letter-spacing: 0;font-size: 14px">4.&nbsp;</span><span
  style="font-family: 宋体;letter-spacing: 0;font-size: 14px"
>注册和使用帐号时，冒用国家机构、新闻媒体、社会组织及他人信息、提供虚假注册信息或证明材料；</span></p><p
  style="margin-left:28px;text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family: 宋体;letter-spacing: 0;font-size: 14px">5.&nbsp;</span><span
  style="font-family: 宋体;letter-spacing: 0;font-size: 14px"
>使用任何非正常手段获取包括但不限于粉丝、播放量、评论、点赞等虚假数据作弊行为；</span></p><p
  style="margin-left:28px;text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family: 宋体;letter-spacing: 0;font-size: 14px">6.&nbsp;</span><span
  style="font-family: 宋体;letter-spacing: 0;font-size: 14px"
>涉及游戏代打、游戏外挂、游戏虚拟装备，买卖各平台帐号等作弊内容。</span></p><p
  style=";text-indent:0;text-autospace:ideograph-numeric"
><strong><span style="font-family: 宋体;letter-spacing: 0;font-size: 14px">六、传播不当的价值观</span></strong></p><p
  style="margin-left:28px;text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family: 宋体;letter-spacing: 0;font-size: 14px">1.&nbsp;</span><span
  style="font-family: 宋体;letter-spacing: 0;font-size: 14px"
>传播软色情、低俗或含有性暗示、性挑逗等易使人产生性联想的内容，展示庸俗、媚俗、低级趣味、粗俗文化；</span></p><p
  style="margin-left:28px;text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family: 宋体;letter-spacing: 0;font-size: 14px">2.&nbsp;</span><span
  style="font-family: 宋体;letter-spacing: 0;font-size: 14px"
>宣扬畸形的婚恋观，传播、开展情感操控课程；传播低俗婚闹等落后糟粕思想；</span></p><p
  style="margin-left:28px;text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family: 宋体;letter-spacing: 0;font-size: 14px">3.&nbsp;</span><span
  style="font-family: 宋体;letter-spacing: 0;font-size: 14px"
>展示各类丧葬过程、丧葬场景，利用逝者炒作、博热度、蹭流量等卖惨内容；</span></p><p
  style="margin-left:28px;text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family: 宋体;letter-spacing: 0;font-size: 14px">4.&nbsp;</span><span
  style="font-family: 宋体;letter-spacing: 0;font-size: 14px"
>宣扬炫富、拜金主义，歪曲的利益观、金钱观，追崇奢靡腐朽的不良生活观念；</span></p><p
  style="margin-left:28px;text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family: 宋体;letter-spacing: 0;font-size: 14px">5.&nbsp;</span><span
  style="font-family: 宋体;letter-spacing: 0;font-size: 14px"
>哗众取宠、恶意审丑等企图博眼球的不当行为；</span></p><p
  style="margin-left:28px;text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family: 宋体;letter-spacing: 0;font-size: 14px">6.&nbsp;</span><span
  style="font-family: 宋体;letter-spacing: 0;font-size: 14px"
>不尊重他人隐私，传播未经当事人允许的偷拍；</span></p><p
  style="margin-left:28px;text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family: 宋体;letter-spacing: 0;font-size: 14px">7.&nbsp;</span><span
  style="font-family: 宋体;letter-spacing: 0;font-size: 14px"
>其他违反公序良俗、传播社会不文明现象的内容。</span></p><p
  style=";text-indent:0;text-autospace:ideograph-numeric"
><strong><span style="font-family: 宋体;letter-spacing: 0;font-size: 14px">七、不利于平台良好生态</span></strong></p><p
  style="margin-left:28px;text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family: 宋体;letter-spacing: 0;font-size: 14px">1.&nbsp;</span><span
  style="font-family: 宋体;letter-spacing: 0;font-size: 14px"
>展示不符合</span><span
  style="font-family: 宋体;letter-spacing: 0;font-size: 14px"
>人在海南</span><span
  style="font-family: 宋体;letter-spacing: 0;font-size: 14px"
>用户协议的商业广告或类似的商业招揽信息、过度营销信息、导流内容及垃圾信息；</span></p><p
  style="margin-left:28px;text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family: 宋体;letter-spacing: 0;font-size: 14px">2.&nbsp;</span><span
  style="font-family: 宋体;letter-spacing: 0;font-size: 14px"
>未经</span><span
  style="font-family: 宋体;letter-spacing: 0;font-size: 14px"
>人在海南</span><span
  style="font-family: 宋体;letter-spacing: 0;font-size: 14px"
>平台授权，发布涉及医疗类风险内容，包括但不限于：医疗广告、医药广告、医美广告、医疗器械广告、医疗机构广告。未经</span><span
  style="font-family: 宋体;letter-spacing: 0;font-size: 14px"
>人在海南</span><span
  style="font-family: 宋体;letter-spacing: 0;font-size: 14px"
><span style="font-family:宋体">医疗健康认证，发布涉及医疗科普性内容，如疾病诊断、分析、治疗、</span> <span style="font-family:宋体">预防、改善等；</span></span></p><p
  style="margin-left:28px;text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family:宋体;font-size:14px">3.&nbsp;</span><span
  style=";font-family:宋体;font-size:14px"
>未经</span><span
  style=";font-family:宋体;font-size:14px"
>人在海南</span><span
  style=";font-family:宋体;font-size:14px"
>平台授权擅自使用</span><span
  style=";font-family:宋体;font-size:14px"
>人在海南</span><span
  style=";font-family:宋体;font-size:14px"
>商标、标志或其他知识产权</span><span
  style="font-family: 宋体;letter-spacing: 0;font-size: 14px"
>。未经</span><span
  style="font-family: 宋体;letter-spacing: 0;font-size: 14px"
>人在海南</span><span
  style=";font-family:宋体;font-size:14px"
>授权认证擅自冒充、伪装</span><span
  style=";font-family:宋体;font-size:14px"
>人在海南</span><span
  style=";font-family:宋体;font-size:14px"
>官方帐号或</span><span
  style=";font-family:宋体;font-size:14px"
>人在海南</span><span
  style=";font-family:宋体;font-size:14px"
>工作人员</span><span
  style=";font-family:宋体;font-size:14px"
>。</span><span
  style=";font-family:宋体;font-size:14px"
>未经</span><span
  style=";font-family:宋体;font-size:14px"
>人在海南</span><span
  style=";font-family:宋体;font-size:14px"
>平台授权擅自使用</span><span
  style=";font-family:宋体;font-size:14px"
>人在海南</span><span
  style=";font-family:宋体;font-size:14px"
>名义宣传推广帐号或其他营销活动的</span><span
  style=";font-family:宋体;font-size:14px"
>；</span></p><p
  style="margin-left:28px;text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family:宋体;font-size:14px">4.&nbsp;</span><span
  style=";font-family:宋体;font-size:14px"
>利用</span><span
  style=";font-family:宋体;font-size:14px"
>人在海南</span><span
  style=";font-family:宋体;font-size:14px"
>平台或系统漏洞</span><span
  style=";font-family:宋体;font-size:14px"
>，</span><span
  style=";font-family:宋体;font-size:14px"
>通过任何非正常手段实施数据作弊行为</span><span
  style=";font-family:宋体;font-size:14px"
>，</span><span
  style=";font-family:宋体;font-size:14px"
>包括但不限于粉丝量、播放量、点赞量、评论量等业务数据造假行为</span><span
  style=";font-family:宋体;font-size:14px"
>；</span></p><p
  style="margin-left:28px;text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family:宋体;font-size:14px">5.&nbsp;</span><span
  style=";font-family:宋体;font-size:14px"
>利用人工或技术手段</span><span
  style=";font-family:宋体;font-size:14px"
>，</span><span
  style=";font-family:宋体;font-size:14px"
>虚假注册帐号、非法交易帐号或用其他方式操纵用户帐号的行为</span><span
  style=";font-family:宋体;font-size:14px"
>；</span></p><p
  style="margin-left:28px;text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family: 宋体;letter-spacing: 0;font-size: 14px">6.&nbsp;</span><span
  style="font-family: 宋体;letter-spacing: 0;font-size: 14px"
>批量发布同质化、低质量、无意义的内容，批量注册虚拟帐号；</span></p><p
  style="margin-left:28px;text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family: 宋体;letter-spacing: 0;font-size: 14px">7.&nbsp;</span><span
  style="font-family: 宋体;letter-spacing: 0;font-size: 14px"
>利用</span><span
  style="font-family: 宋体;letter-spacing: 0;font-size: 14px"
>人在海南</span><span
  style="font-family: 宋体;letter-spacing: 0;font-size: 14px"
>平台骚扰他人，包括但不限于无视他人明确拒绝的态度，</span><span
  style=";font-family:宋体;font-size:14px"
><span style="font-family:宋体">以评论、私信、</span>@他人、求关注、发布垃圾信息等方式</span><span
  style=";font-family:宋体;font-size:14px"
>，</span><span
  style=";font-family:宋体;font-size:14px"
>重复、频繁地骚扰他人</span><span
  style="font-family: 宋体;letter-spacing: 0;font-size: 14px"
>；</span></p><p
  style="margin-left:28px;text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family: 宋体;letter-spacing: 0;font-size: 14px">8.&nbsp;</span><span
  style="font-family: 宋体;letter-spacing: 0;font-size: 14px"
>侵犯</span><span
  style="font-family: 宋体;letter-spacing: 0;font-size: 14px"
>人在海南</span><span
  style="font-family: 宋体;letter-spacing: 0;font-size: 14px"
><span style="font-family:宋体">平台权益、扰乱社区氛围，包括但不限于：</span> <span style="font-family:宋体">利用平台漏洞实施帐号作弊行为、非法交易帐号、劫持其他帐号；</span></span></p><p
  style="margin-left:28px;text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family:宋体;font-size:14px">9.&nbsp;</span><span
  style=";font-family:宋体;font-size:14px"
>通过</span><span
  style=";font-family:宋体;font-size:14px"
>人在海南</span><span
  style=";font-family:宋体;font-size:14px"
>平台推广其他产品</span><span
  style=";font-family:宋体;font-size:14px"
>，</span><span
  style=";font-family:宋体;font-size:14px"
>鼓动用户转移至其他平台消费内容等</span><span
  style=";font-family:宋体;font-size:14px"
>。</span></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><strong><span style="font-family: 宋体;font-size: 14px">&nbsp;</span></strong></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family:宋体;font-weight:bold;font-size:14px">第四章&nbsp;</span><strong><span style="font-family: 宋体;font-size: 14px">人在海南</span></strong><strong><span style="font-family: 宋体;font-size: 14px">平台倡导的行为</span></strong></p><p
  style="margin-left:0;text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family: 宋体;letter-spacing: 0;font-size: 14px">一、</span><span
  style="font-family: 宋体;letter-spacing: 0;font-size: 14px"
>尊重</span><span
  style="font-family: 宋体;letter-spacing: 0;font-size: 14px"
>人在海南</span><span
  style="font-family: 宋体;letter-spacing: 0;font-size: 14px"
>社区内的其他用户，平等、友好地传递温暖的社区氛围。不攻击、谩骂、侮辱、诽谤、歧视他人，不侵犯他人合法权益；</span></p><p
  style="margin-left:0;text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family: 宋体;letter-spacing: 0;font-size: 14px">二、</span><span
  style="font-family: 宋体;letter-spacing: 0;font-size: 14px"
>我们呼吁建立有爱的</span><span
  style="font-family: 宋体;letter-spacing: 0;font-size: 14px"
>人在海南</span><span
  style="font-family: 宋体;letter-spacing: 0;font-size: 14px"
><span style="font-family:宋体">社区，关爱未成年人、关照</span> <span style="font-family:宋体">老年群体、尊重性别平等；</span></span></p><p
  style="margin-left:0;text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family: 宋体;letter-spacing: 0;font-size: 14px">三、</span><span
  style="font-family: 宋体;letter-spacing: 0;font-size: 14px"
>我们鼓励个人原创内容，倡导展现积极正向的作品，提倡记录美好有趣健康的生活，表达真实的自己；</span></p><p
  style="margin-left:0;text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family: 宋体;letter-spacing: 0;font-size: 14px">四、</span><span
  style="font-family: 宋体;letter-spacing: 0;font-size: 14px"
>我们建议</span><span
  style="font-family: 宋体;letter-spacing: 0;font-size: 14px"
>人在海南</span><span
  style="font-family: 宋体;letter-spacing: 0;font-size: 14px"
>用户重视文字的正确使用，避免出现错别字，自觉遵守语言文字规范。建议</span><span
  style="font-family: 宋体;letter-spacing: 0;font-size: 14px"
>人在海南</span><span
  style="font-family: 宋体;letter-spacing: 0;font-size: 14px"
>用户重视图片及视频的正确传播，避免出现劣质水印图片及视频，自觉遵守图片视频使用规范；</span></p><p
  style="margin-left:0;text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family: 宋体;letter-spacing: 0;font-size: 14px">五、</span><span
  style="font-family: 宋体;letter-spacing: 0;font-size: 14px"
>我们倡导健康游戏，传播积极向上的内容；</span></p><p
  style="margin-left:0;text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family: 宋体;letter-spacing: 0;font-size: 14px">六、</span><span
  style="font-family: 宋体;letter-spacing: 0;font-size: 14px"
>我们倡导勤俭节约、合理饮食，反对餐饮浪费；</span></p><p
  style="margin-left:0;text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family: 宋体;letter-spacing: 0;font-size: 14px">七、</span><span
  style="font-family: 宋体;letter-spacing: 0;font-size: 14px"
><span style="font-family:宋体">请敬畏生命，反对捕杀、盗采珍稀野生动植物，拒绝养殖和食用野生动植物。关爱动物，不虐待、猎杀、</span> <span style="font-family:宋体">交易活体动物；</span></span></p><p
  style="margin-left:0;text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family: 宋体;letter-spacing: 0;font-size: 14px">八、</span><span
  style="font-family: 宋体;letter-spacing: 0;font-size: 14px"
>做文明公民，不闯入、不破坏国家自然保护区，善待自然资源；</span></p><p
  style="margin-left:0;text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family: 宋体;letter-spacing: 0;font-size: 14px">九、</span><span
  style="font-family: 宋体;letter-spacing: 0;font-size: 14px"
>我们建议</span><span
  style="font-family: 宋体;letter-spacing: 0;font-size: 14px"
>人在海南</span><span
  style="font-family: 宋体;letter-spacing: 0;font-size: 14px"
>用户提高网络安全防范意识，对网络交友诱导赌博、贷款、返利、中奖、网络兼职点赞员等网络诈骗行为提高警惕，如有异常可随时向</span><span
  style="font-family: 宋体;letter-spacing: 0;font-size: 14px"
>人在海南</span><span
  style="font-family: 宋体;letter-spacing: 0;font-size: 14px"
>平台举报；</span></p><p
  style="margin-left:0;text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family: 宋体;letter-spacing: 0;font-size: 14px">十、</span><span
  style="font-family: 宋体;letter-spacing: 0;font-size: 14px"
>我们鼓励经济、教育、医疗卫生、司法、互联网等专业人士通过平台认证发布权威真实的信息，分享行业知识，促进行行业繁荣。</span></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><strong><span style="font-family: 宋体;font-size: 14px">&nbsp;</span></strong></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family:宋体;font-weight:bold;font-size:14px">第五章&nbsp;</span><strong><span style="font-family: 宋体;font-size: 14px">人在海南</span></strong><strong><span style="font-family: 宋体;font-size: 14px">社区管理方式</span></strong></p><p
  style=";text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family: 宋体;letter-spacing: 0;font-size: 14px"><span style="font-family:宋体">为营造美好、正向的社区氛围，打造健康、有价值的平台，</span> </span><span
  style="font-family: 宋体;letter-spacing: 0;font-size: 14px"
>人在海南</span><span
  style="font-family: 宋体;letter-spacing: 0;font-size: 14px"
>平台将持续通过技术手段、产品手段、运营手段，打击、治理违规帐号和内容，并定期公布处罚通告。</span></p><p
  style=";text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family: 宋体;letter-spacing: 0;font-size: 14px">&nbsp;</span></p><p
  style=";text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family: 宋体;letter-spacing: 0;font-size: 14px">对于</span><span
  style="font-family: 宋体;letter-spacing: 0;font-size: 14px"
>人在海南</span><span
  style="font-family: 宋体;letter-spacing: 0;font-size: 14px"
>用户违反本社区公约的行为，</span><span
  style="font-family: 宋体;letter-spacing: 0;font-size: 14px"
>人在海南</span><span
  style="font-family: 宋体;letter-spacing: 0;font-size: 14px"
><span style="font-family:宋体">平台将采取包括但不限于删除</span>/屏蔽违规内容（包括视频、音频、直播、图像、评论和文字）、暂停或终止违规用户帐号功能等措施， 并通知相关违规的</span><span
  style="font-family: 宋体;letter-spacing: 0;font-size: 14px"
>人在海南</span><span
  style="font-family: 宋体;letter-spacing: 0;font-size: 14px"
>用户，</span><span
  style="font-family: 宋体;letter-spacing: 0;font-size: 14px"
>人在海南</span><span
  style="font-family: 宋体;letter-spacing: 0;font-size: 14px"
>用户对处置措施如有异议可进行申诉。</span></p><p
  style=";text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family: 宋体;letter-spacing: 0;font-size: 14px">&nbsp;</span></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family: 宋体;letter-spacing: 0;font-size: 14px">对于屡次违反社区公约或造成恶劣影响的，</span><span
  style="font-family: 宋体;letter-spacing: 0;font-size: 14px"
>人在海南</span><span
  style="font-family: 宋体;letter-spacing: 0;font-size: 14px"
>平台将评估暂停该</span><span
  style="font-family: 宋体;letter-spacing: 0;font-size: 14px"
>人在海南</span><span
  style="font-family: 宋体;letter-spacing: 0;font-size: 14px"
>帐号的使用能力，保存有关记录，并向主管部门报告</span></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family: 宋体;letter-spacing: 0;font-size: 14px">&nbsp;</span></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><strong><span style="font-family: 宋体;font-size: 14px">第</span></strong><strong><span style="font-family: 宋体;font-size: 14px">六</span></strong><strong><span style="font-family: 宋体;font-size: 14px"><span style="font-family:宋体">章</span> <span style="font-family:宋体">附则</span>&nbsp;</span></strong></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><span
  style=";font-family:宋体;font-size:14px"
>一、本规定的订立、执行和解释及争议的解决均应适用中国法律并受中国法院管辖。如双方就本规定内容或其执行发生任何争议</span><span
  style=";font-family:宋体;font-size:14px"
>，</span><span
  style=";font-family:宋体;font-size:14px"
>双方应尽量友好协商解决</span><span
  style=";font-family:宋体;font-size:14px"
>；</span><span
  style=";font-family:宋体;font-size:14px"
>协商不成时，任何一方均可向</span><span
  style=";font-family:宋体;font-size:14px"
>人在海南</span><span
  style=";font-family:宋体;font-size:14px"
>平台所在地的人民法院提起诉讼。</span></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><span style=";font-family:宋体;font-size:14px">二、使用</span><span
  style=";font-family:宋体;font-size:14px"
>人在海南</span><span
  style=";font-family:宋体;font-size:14px"
>服务的过程中应当遵守当地相关的法律法规</span><span
  style=";font-family:宋体;font-size:14px"
>，</span><span
  style=";font-family:宋体;font-size:14px"
>并尊重当地的道德和风俗习惯。如果用户行为违反了当地法律法规或道德风俗</span><span
  style=";font-family:宋体;font-size:14px"
>，</span><span
  style=";font-family:宋体;font-size:14px"
>用户应当为此独立承担责任。用户应避免因使用本服务而使</span><span
  style=";font-family:宋体;font-size:14px"
>人在海南</span><span
  style=";font-family:宋体;font-size:14px"
>卷入政治和公共事件</span><span
  style=";font-family:宋体;font-size:14px"
>，</span><span
  style=";font-family:宋体;font-size:14px"
>否则</span><span
  style=";font-family:宋体;font-size:14px"
>人在海南</span><span
  style=";font-family:宋体;font-size:14px"
>有权暂停或终止其服务。</span></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><span style=";font-family:宋体;font-size:14px">三、</span><span
  style=";font-family:宋体;font-size:14px"
>人在海南</span><span
  style=";font-family:宋体;font-size:14px"
>团队可依照互联网发展的不同阶段</span><span
  style=";font-family:宋体;font-size:14px"
>，</span><span
  style=";font-family:宋体;font-size:14px"
>随着社区管理经验的不断丰富</span><span
  style=";font-family:宋体;font-size:14px"
>，</span><span
  style=";font-family:宋体;font-size:14px"
>出于维护</span><span
  style=";font-family:宋体;font-size:14px"
>人在海南</span><span
  style=";font-family:宋体;font-size:14px"
>社区秩序的目的</span><span
  style=";font-family:宋体;font-size:14px"
>，</span><span
  style=";font-family:宋体;font-size:14px"
>不断完善本规定。用户应能反复查看本规定</span><span
  style=";font-family:宋体;font-size:14px"
>，</span><span
  style=";font-family:宋体;font-size:14px"
>以便获得最新信息。</span></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><span style=";font-family:宋体;font-size:14px">四、本规定自发布之日起施行</span><span
  style=";font-family:宋体;font-size:14px"
>。</span></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><span style=";font-family:宋体;font-size:14px">&nbsp;</span></p><p
  style=";text-indent:0;text-autospace:ideograph-numeric;text-align:center"
><strong><span style="font-family: 宋体;letter-spacing: 0;font-size: 14px">&nbsp;</span></strong></p><p
  style=";text-indent:0;text-autospace:ideograph-numeric;text-align:center"
><strong><span style="font-family: 宋体;letter-spacing: 0;font-size: 19px">广大用户的力量</span></strong></p><p
  style=";text-indent:0;text-autospace:ideograph-numeric;text-align:center"
><strong><span style="font-family: 宋体;letter-spacing: 0;font-size: 14px">&nbsp;</span></strong></p><p
  style=";text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family: 宋体;letter-spacing: 0;font-size: 14px">人在海南</span><span
  style="font-family: 宋体;letter-spacing: 0;font-size: 14px"
>聆听每一位</span><span
  style="font-family: 宋体;letter-spacing: 0;font-size: 14px"
>人在海南</span><span
  style="font-family: 宋体;letter-spacing: 0;font-size: 14px"
>用户的反馈声音，鼓励广大</span><span
  style="font-family: 宋体;letter-spacing: 0;font-size: 14px"
>人在海南</span><span
  style="font-family: 宋体;letter-spacing: 0;font-size: 14px"
>用户共同监督平台内发现的违规行为和内容，并积极提供多种类通道帮助</span><span
  style="font-family: 宋体;letter-spacing: 0;font-size: 14px"
>人在海南</span><span
  style="font-family: 宋体;letter-spacing: 0;font-size: 14px"
>用户反馈信息。</span></p><p
  style=";text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family: 宋体;letter-spacing: 0;font-size: 14px">我们期待与你一同共建温馨友爱的</span><span
  style="font-family: 宋体;letter-spacing: 0;font-size: 14px"
>人在海南</span><span
  style="font-family: 宋体;letter-spacing: 0;font-size: 14px"
>社区。</span></p><p
  style=";text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family: 宋体;letter-spacing: 0;font-size: 14px">&nbsp;</span></p><p
  style=";text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family: 宋体;letter-spacing: 0;font-size: 14px">目前反馈渠道包括：站内举报、邮箱举报、客服举报。</span></p><p
  style=";text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family: 宋体;font-size: 14px">在线</span><span
  style="font-family: 宋体;font-size: 14px"
>客服</span><span
  style="font-family: 宋体;font-size: 14px"
><span style="font-family:宋体">：我的</span>-在线客服</span></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family: 宋体;font-size: 14px">负责人</span><span
  style="font-family: 宋体;font-size: 14px"
><span style="font-family:宋体">联系邮箱：</span>pinhn@zhongke.club</span></p><p
  style=";text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family: 宋体;letter-spacing: 0;font-size: 14px">人在海南</span><span
  style="font-family: 宋体;letter-spacing: 0;font-size: 14px"
>官方网站：</span><strong><span
  style="font-family: 宋体;color: rgb(190, 143, 0);letter-spacing: 0;font-size: 14px"
>www.renzaihainan.com</span></strong></p><p
  style=";text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family: 宋体;letter-spacing: 0;font-size: 14px">人在海南</span><span
  style="font-family: 宋体;letter-spacing: 0;font-size: 14px"
>公众号：</span><strong><span style="font-family: 宋体;color: rgb(190, 143, 0);letter-spacing: 0;font-size: 14px">renzaihainan</span></strong></p><p
  style=";text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family: 宋体;letter-spacing: 0;font-size: 14px">&nbsp;</span></p><p><span style="font-family: 宋体;font-size: 14px"><span style="font-family:宋体">更新日期：</span>2021年</span><span
  style="font-family: 宋体;font-size: 14px"
>11</span><span
  style="font-family: 宋体;font-size: 14px"
>月</span><span
  style="font-family: 宋体;font-size: 14px"
>26</span><span
  style="font-family: 宋体;font-size: 14px"
>日</span></p><p><br /></p>
        </pre>
    </div>
    <!-- footerBox -->
    <div class="gwFooter">
      <FooterBox />
    </div>
  </div>
</template>

<script>
import HeadNav from "@/components/HeadNav.vue";
import FooterBox from "@/components/FooterBox.vue";
export default {
  name: "communityNorms",
  components: {
    HeadNav,
    FooterBox
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {}
};
</script>
<style lang="scss">
</style>