import Vue from 'vue'
import axios from 'axios'
import store from '@/store/index'
import { Toast } from 'vant';
import { ascScort,getStr,getSign } from "@/lib/sign"
import { urlParams } from "@/lib/utils"

// import { gettime_sr, joinStr } from "@/lib/signature"

Vue.use(Toast);

export const baseRestUrl = process.env.NODE_ENV==='development'? '':process.env.VUE_APP_BASE_API;   //baseURL: process.env.VUE_APP_BASE_API,
let request = axios.create({
    baseURL: baseRestUrl,
    timeout: 10000,
    headers: {
      'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
    }
})

// 请求拦截器
request.interceptors.request.use(    
   async config => {        
    // 每次发送请求之前判断vuex中是否存在token        
    // 如果存在，则统一在http请求的header都加上token，这样后台根据token判断你的登录情况
    // 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断 
    // config.url += "?"+joinStr(gettime_sr(config.data))
    // const token = store.state.token;        
    // token && (config.headers.Authorization = token);        
    // return config;    
        let ascObj = ascScort(config.method.toUpperCase() == "GET"  ? config.params: config.data);
        let str = getStr(ascObj.default,urlParams('token'));
        config.headers.sign = await getSign(str);
        if (config.method.toUpperCase()==="POST") {
            config.data.nonceStr = ascObj.nonceStr;
            config.data.timestamp = ascObj.timestamp;
        }
        config.headers.platform='h5'
        config.headers.token = urlParams('token') || "";
        config.headers.contentType = 'application/x-www-form-urlencoded';
        return config
    },    
    error => {        
        return Promise.error(error);    
    }
);

// 响应拦截器
request.interceptors.response.use(    
    response => { 
        // 如果返回的状态码为200，说明接口请求成功，可以正常拿到数据     
        // 否则的话抛出错误
        if (response.status === 200) {
            //延长qr_code?
            if(response.data.success==0 && (response.data.message == '扫码过期' || response.data.message == '未登录')){
                //在这里通知APP去登录 2020-5-14 具体能否走通，需要当前webview(APP)方法支持
              //  let _system= until.getSystem();
                try{
                /*     if(_system.isAndroid){
                        window.js.callAndroidLogin();
                    }else if(_system.isIOS){        
                        window.webkit.messageHandlers.methodName.postMessage("login")
                    } */
                }catch(errmsg){
                    console.log(errmsg)
                }
            }          
            return Promise.resolve(response.data);        
        } else {       
            return Promise.reject(response);        
        }    
    },    
    // 服务器状态码不是2开头的的情况
    // 这里可以跟你们的后台开发人员协商好统一的错误状态码    
    // 然后根据返回的状态码进行一些操作，例如登录过期提示，错误提示等等
    // 下面列举几个常见的操作，其他需求可自行扩展
    error => { 
        if(typeof error !== 'object'){
            Toast({
               message: error,
               duration: 2000,
               forbidClick: true
           });
        }     
        if (error.response.data.code) {   
            switch (error.response.data.code) {                
                // 401: 未登录 则提示未登录               
                case '401000':    
                    Toast({
                        message: '登录凭证失效，请重新登录',
                        duration: 2000,
                        forbidClick: true
                    });       
                    break;

                // 403 token过期
                // 登录过期对用户进行提示
                // 清除本地token和清空vuex中token对象
                // 跳转登录页面                
                case 403:
                     Toast({
                        message: '登录过期，请重新登录',
                        duration: 1000,
                        forbidClick: true
                    });
                    // 清除token
                    localStorage.removeItem('token');
                    store.commit('clearToken');
                    store.commit('loginSuccess', null);
                    // 跳转登录页面，并将要浏览的页面fullPath传过去，登录成功后跳转需要访问的页面 
                    setTimeout(() => {                        
                        router.replace({                            
                            path: '/login',                            
                            query: { 
                                redirect: router.currentRoute.fullPath 
                            }                        
                        });                    
                    }, 1000);                    
                    break; 

                // 404请求不存在
                case 404:
                    Toast({
                        message: '网络请求不存在',
                        duration: 1500,
                        forbidClick: true
                    });
                    break;
                // 其他错误，直接抛出错误提示
                default:
                    Toast({
                        message: error.response.data.message,
                        duration: 1500,
                        forbidClick: true
                    });
            }
            return Promise.reject(error.response);
        }   
});

export default request