<template>
  <div class="qrCodeBox" v-if="isShow">
    <div class="popContainer">
      <div class="box">
        <div class="head">
          <img src="@/assets/images/close.png" @click="closeDialog" />
        </div>
        <div class="content">
          <p>{{ url === "wx" ? "人在海南微信公众号" : "人在海南APP" }}</p>
          <div id="codes" ref="codes"></div>
          <p>
            {{ url === "wx" ? "微信扫一扫 , 立即关注" : "扫一扫 , 立即下载" }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import QRCode from "qrcodejs2";
export default {
  name: "qrCode",
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    url: {
      type: String,
      default: "app",
    },
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  created() {
    console.log("userAgent:", navigator.userAgent);
    this.codeChange();
  },
  mounted() {},
  methods: {
    codeChange() {
      let link = "";
      if (this.url === "wx") {
        link =
          "https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=MzkyODI4OTg0Ng==&scene=123&from=groupmessage&isappinstalled=0#wechat_redirect"; //微信公众号
      } else {
        link = "https://static.renzaihainan.com/pinhn-apph5/download"; //app下载链接
      }

      let isWx = this.isWeixin();
      let phone = this.isMobile();

      let nums = 200;

      if(phone&&isWx) {
        nums = 116;
      } else if(phone&&!isWx) {
        nums = 106;  
      } else {
        nums = 200;
      }

      this.$nextTick(() => {
        let qrcode = new QRCode("codes", {
          width: nums, // 二维码宽度，单位像素
          height: nums, // 二维码高度，单位像素
          text: link
        });
      });
    },
    closeDialog: function () {
      this.$emit("update:isShow", false);
    },
    isWeixin() {
      var ua = navigator.userAgent.toLowerCase();
      // 搜狐新闻APP && ios时
      if (
        navigator.userAgent.match(/(iPhone|iPod|iPad);?/i) &&
        ua.match(/(SoHu);?/i)
      ) {
        return false;
      } else {
        return !!ua.match(/(micromessenger|WeiBo|SoHu|iPod|iPad);?/i);
      }
    },
    isMobile() {
      return /(iPhone|iPad|iPod|iOS|Android|android|Linux armv8l|Linux armv7l|Linux aarch64)/i.test(
        navigator.platform
      );
    },
  },
};
</script>

<style scoped lang="scss">
.qrCodeBox {
  .popContainer {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 998;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    .box {
      display: flex;
      flex-direction: column;
      width: 650px;
      height: 550px;
      background: #333333;
      .head {
        width: 100%;
        height: 35px;
        background: #dee1e6;
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        img {
          width: 20px;
          height: 20px;
          margin-right: 15px;
          cursor: pointer;
        }
      }
      .content {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        p {
          color: #ffffff;
          &:nth-child(1) {
            font-size: 20px;
          }
        }
      }
    }
    #codes {
      z-index: 999;
      margin: 20px 0;
      border: 15px solid #ffffff;
      width: 280px;
      height: 280px;
    }
  }
}
</style>
