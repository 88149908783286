<template>
<div class="navigationBar">
  <head class="navList">
    <div class="logoFlex">
      <img src="@/assets/images/logo.png" alt />
      <h1>人在海南</h1>
    </div>
    <div class="type">
      <ul>
        <li
          v-for="(row, i) in urlData"
          :key="i"
          :class="{'current': pageName == row.url}"
          @click="pageUrl(row.url)"
        >{{row.name}}</li>
      </ul>
      <div class="btns">
        <div @click="showCode=true">我要求职</div>
        <div @click="showCode=true">我要招人</div>
      </div>
    </div>
  </head>
  <div class="height"></div>
  <!-- app下载二维码 -->
  <QrCode v-if="showCode" :isShow.sync="showCode" :url="'app'" />
</div>
</template>

<script>
import QrCode from "@/components/qrCode.vue";

export default {
  name: "headNav",
  components: {
    QrCode
  },
  props: {
    pageName: {
      type: String,
      default: "Home"
    }
  },
  data() {
    return {
      showCode: false,
      urlData: [
        {
          name: "首页",
          url: "Home"
        },
        {
          name: "海聘",
          url: "haiPin"
        },
        {
          name: "海脉圈",
          url: "haiMaiQuan"
        },
        {
          name: "关于我们",
          url: "aboutUs"
        }
      ]
    };
  },
  methods: {
    pageUrl(url) {
      if (url == "Home") {
        this.$router.push({ path: `/` });
      } else {
        this.$router.push({ path: `/${url}` });
      }
    }
  }
};
</script>

<style scoped lang="scss">
.navList {
  position: fixed;
  top: 0;
  z-index: 999;
  width: $wd_1920;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 10%;
  box-sizing: border-box;
  background: $bg_fff;
  box-shadow: 0 4px 5px -3px rgba(0,0,0,0.1);
  .logoFlex {
    display: flex;
    align-items: center;
    flex: 2;
    cursor: pointer;
    img {
      width: 60px;
      height: 60px;
    }
    h1 {
      font-size: 32px;
      font-weight: bold;
      color: $clr_son;
      margin-left: 20px;
    }
  }
  .type {
    display: flex;
    align-content: center;
    justify-content: space-between;
    cursor: pointer;
    ul {
      display: flex;
      align-content: center;
      li {
        font-size: 22px;
        color: $clr_333;
        margin-right: 80px;
        line-height: 45px;
      }
      li:hover {
        color: #707070;
      }
      .current {
        color: $clr_son;
        font-weight: bold;
        position: relative;
        &::before {
          content: "";
          position: absolute;
          width: 55%;
          height: 4px;
          background: $bg_son;
          left: 0;
          right: 0;
          margin: auto;
          bottom: -13px;
          z-index: 22;
        }
      }
      .current:hover {
        color: $clr_son;
      }
    }
    .btns {
      display: flex;
      align-items: center;
      div {
        background: $bg_son;
        border-radius: 20px;
        color: $clr_fff;
        font-size: 18px;
        font-weight: bold;
        height: 45px;
        line-height: 45px;
        padding: 0 20px;
        margin-right: 40px;
      }
    }
  }
}
.height {
  height: 100px;
}
</style>
