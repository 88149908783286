<template>
  <div class="footerBox">
    <div class="footList">
      <div class="leftBox">
        <ul>
          <li>产品</li>
          <li>产品介绍</li>
          <li>产品服务</li>
        </ul>
        <ul>
          <li>企业介绍</li>
          <li>社区</li>
          <li @click="aboutUsUrl">关于我们</li>
          <li class="fwxy" @click="serviceAgreementUrl">人在海南《服务协议》</li>
          <li class="fwxy" @click="privacyPolicyUrl">人在海南《隐私政策》</li>
          <li class="fwxy" @click="communityNormsUrl">人在海南《社区管理规范》</li>
        </ul>
      </div>
      <div class="rightBox">
        <ul>
          <li>海南博众人力资源有限公司</li>
          <li>地址：海南省海口市龙华区金贸街道滨海大道117号滨海国际金融中心A座2702</li>
          <li>电话：0898-68602235</li>
        </ul>
      </div>
    </div>
    <div class="copyrightBox">
      <div class="bq_left">
        <div>© 2021-2022 海南博众人力资源有限公司. 版权所有.</div>
        <a href="https://beian.miit.gov.cn/">琼ICP备2021009568号</a>
      </div>
      <li class="loginFlex">
        <img @click="weibUrl" src="@/assets/images/weib.png" alt />
        <img @click="showCode=true" src="@/assets/images/weix.png" alt />
      </li>
    </div>
    <!-- 微信关注号二维码 -->
    <QrCode v-if="showCode" :isShow.sync="showCode" :url="'wx'" />
  </div>
</template>

<script>
import QrCode from "@/components/qrCode.vue";
export default {
  name: "footerBox",
  props: {},
  components: {
    QrCode
  },
  data() {
    return {
      showCode: false
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    aboutUsUrl() {
      this.$router.push({ path: "/aboutUs" });
    },
    privacyPolicyUrl() {
      this.$router.push({ path: "/privacyPolicy" });
    },
    serviceAgreementUrl() {
      this.$router.push({ path: "/serviceAgreement" });
    },
    communityNormsUrl() {
      this.$router.push({ path: "/communityNorms" });
    },
    weibUrl() {
      window.open("https://www.weibo.com/u/7673802581");
    }
  }
};
</script>

<style scoped lang="scss">
.footerBox {
  padding: 100px 0 100px;
  .footList {
    padding: 0 10% 0 370px;
    display: flex;
    ul {
      padding-right: 90px;
      li {
        font-size: $size_20;
        padding-bottom: 30px;
        line-height: 32px;
        cursor: pointer;
      }
      li:first-child {
        font-weight: bold;
        font-size: $size_24;
      }
      li:hover {
        color: $clr_son;
      }
      .fwxy:hover {
        text-decoration: underline;
      }
    }
    .leftBox {
      width: 40%;
      display: flex;
    }
    .rightBox {
      flex: 2;
      ul {
        padding-right: 0;
      }
    }
  }
  .copyrightBox {
    .bq_left {
      width: 40%;
      div {
        margin-bottom: 8px;
        font-size: $size_20;
      }
    }
    padding: 0 10% 0 370px;
    @include flexEnter;
    margin-top: 10px;
    a {
      font-size: $size_20;
      color: $clr_333;
    }
    a:hover {
      text-decoration: underline;
      color: $clr_son;
    }
    .loginFlex {
      @include flexEnter;
      flex: 2;
      img {
        width: 100px;
        height: 100px;
        margin-right: 40px;
        cursor: pointer;
      }
    }
  }
}
</style>
