<template>
  <div class="serviceAgreement">
    <!-- 导航栏 -->
    <HeadNav pageName />
    <div class="xqzxBox">
      <pre>
<p class="titles"><strong><span style="font-family: 宋体;">《</span></strong><strong><span style="font-family: 宋体;">服务</span></strong><strong><span style="font-family: 宋体;">协议》</span></strong></p>
<p style="text-indent:0;text-autospace:ideograph-numeric;text-align:center"><br /></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><strong><span style="font-family: 宋体;font-size: 14px">&ZeroWidthSpace;一、服务条款的确认和接受</span></strong></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family: 宋体;font-size: 14px">欢迎您</span><span
  style="font-family: 宋体;font-size: 14px"
><span style="font-family:宋体">使用</span>“</span><span
  style="font-family: 宋体;font-size: 14px"
>人在海南</span><span
  style="font-family: 宋体;font-size: 14px"
>”软件及相关服务！</span><span
  style="font-family: 宋体;font-size: 14px"
>请您仔细</span><span
  style="font-family: 宋体;font-size: 14px"
><span style="font-family:宋体">阅读《用户服务协议》（以下简称</span>“本协议”），本协议是您与海南博众人力资源有限公司（下称“我们”）就您使用</span><span
  style="font-family: 宋体;font-size: 14px"
>人在海南</span><span
  style="font-family: 宋体;font-size: 14px"
>软件及相关服务所订立的协议。请您知悉，在您接受本协议并注册成功后，</span><span
  style="font-family: 宋体;font-size: 14px"
>人在海南</span><span
  style="font-family: 宋体;font-size: 14px"
>将通过</span><span
  style="font-family: 宋体;font-size: 14px"
>人在海南</span><span
  style="font-family: 宋体;font-size: 14px"
>软件为您提供一个记录</span><span
  style="font-family: 宋体;font-size: 14px"
>工作</span><span
  style="font-family: 宋体;font-size: 14px"
>生活、分享</span><span
  style="font-family: 宋体;font-size: 14px"
>职场</span><span
  style="font-family: 宋体;font-size: 14px"
><span style="font-family:宋体">生活的服务平台（下称</span>“</span><span
  style="font-family: 宋体;font-size: 14px"
>人在海南</span><span
  style="font-family: 宋体;font-size: 14px"
><span style="font-family:宋体">平台</span>”），且为了更好的为您提供服务，就</span><span
  style="font-family: 宋体;font-size: 14px"
>人在海南</span><span
  style="font-family: 宋体;font-size: 14px"
><span style="font-family:宋体">软件及相关服务或运营方的需求及</span>/或</span><span
  style="font-family: 宋体;font-size: 14px"
>人在海南</span><span
  style="font-family: 宋体;font-size: 14px"
>软件涉及的某些服务，可能会由</span><span
  style="font-family: 宋体;font-size: 14px"
>人在海南</span><span
  style="font-family: 宋体;font-size: 14px"
>的关联公司或</span><span
  style="font-family: 宋体;font-size: 14px"
>人在海南</span><span
  style="font-family: 宋体;font-size: 14px"
>认可的第三方公司向您提供。</span></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><strong><span style="font-family: 宋体;font-size: 14px">&nbsp;</span></strong></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><strong><span style="font-family: 宋体;font-size: 14px">在您开始使用</span></strong><strong><span style="font-family: 宋体;font-size: 14px">人在海南</span></strong><strong><span style="font-family: 宋体;font-size: 14px">软件及相关服务前，请您务必认真阅读并充分理解本协议（您确认，在您开始注册使用</span></strong><strong><span style="font-family: 宋体;font-size: 14px">人在海南</span></strong><strong><span
  style="font-family: 宋体;font-size: 14px"
>软件及服务前，您应当具备中华人民共和国法律规定的与您行为相适应的民事行为能力。若您不具备前述与您行为相适应的民事行为能力，则您及您的监护人应依照法律规定承担因此而导致的一切后果。），特别是免除或限制责任的条款、权利许可和信息使用的条款以及开通或使用某项服务的单独协议或条款等，前述条款可能以黑体加粗形式提示您注意。当您注册成功，即表示您（即「用户」）已详细阅读并完全接受本协议的所有内容，如果您对本协议的任何条款表示异议，您可以选择不使用</span></strong><strong><span style="font-family: 宋体;font-size: 14px">人在海南</span></strong><strong><span style="font-family: 宋体;font-size: 14px">软件及相关服务。</span></strong></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><strong><span style="font-family: 宋体;font-size: 14px">&nbsp;</span></strong></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><strong><span style="font-family: 宋体;font-size: 14px">如您需要使用</span></strong><strong><span style="font-family: 宋体;font-size: 14px">人在海南</span></strong><strong><span style="font-family: 宋体;font-size: 14px">提供的其他服务内容，您还应遵守与这些服务有关的服务协议或规则。本协议、《隐私权保护政策》及</span></strong><strong><span style="font-family: 宋体;font-size: 14px">人在海南</span></strong><strong><span style="font-family: 宋体;font-size: 14px"><span style="font-family:宋体">软件中已经发布的或将来可能发布的规则为本协议不可分割的组成部分（以下统称</span>“</span></strong><strong><span style="font-family: 宋体;font-size: 14px">人在海南</span></strong><strong><span style="font-family: 宋体;font-size: 14px"><span style="font-family:宋体">平台规则</span>”），与本协议具有同等法律效力。</span></strong></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><strong><span style="font-family: 宋体;font-size: 14px">&nbsp;</span></strong></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><strong><span style="font-family: 宋体;font-size: 14px">二、您的权益</span></strong></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family: 宋体;font-size: 14px">1、当您阅读并同意本协议并完成全部注册程序后，您可获得</span><span
  style="font-family: 宋体;font-size: 14px"
>人在海南</span><span
  style="font-family: 宋体;font-size: 14px"
>平台账户。</span><strong><span style="font-family: 宋体;font-size: 14px">您为取得的注册账户的合法使用权人，有权自行保管账户并设置、更改账户密码，</span></strong><strong><span style="font-family: 宋体;font-size: 14px">人在海南</span></strong><strong><span style="font-family: 宋体;font-size: 14px">任何时候均不会主动要求您提供您的账户密码。</span></strong></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><strong><span style="font-family: 宋体;font-size: 14px">&nbsp;</span></strong></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family: 宋体;font-size: 14px">2、</span><span
  style="font-family: 宋体;font-size: 14px"
>人在海南</span><span
  style="font-family: 宋体;font-size: 14px"
>尊重并保护您的账户名称权。您在点击微信快捷登录按钮的时候即可关联您的微信昵称作为</span><span
  style="font-family: 宋体;font-size: 14px"
>人在海南</span><span
  style="font-family: 宋体;font-size: 14px"
><span style="font-family:宋体">用户名（以下简称</span>“账户名称”），</span><span
  style="font-family: 宋体;font-size: 14px"
>人在海南</span><span
  style="font-family: 宋体;font-size: 14px"
>将实时同步您的账户名称与微信昵称一致。</span></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family: 宋体;font-size: 14px">&nbsp;</span></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family: 宋体;font-size: 14px">3</span><span
  style="font-family: 宋体;font-size: 14px"
>、</span><span
  style="font-family: 宋体;font-size: 14px"
>人在海南</span><span
  style="font-family: 宋体;font-size: 14px"
>为您提供一个记录</span><span
  style="font-family: 宋体;font-size: 14px"
>职场</span><span
  style="font-family: 宋体;font-size: 14px"
>生活的平台。您可使用</span><span
  style="font-family: 宋体;font-size: 14px"
>人在海南</span><span
  style="font-family: 宋体;font-size: 14px"
>软件提供的视频录制功能来创作您的作品，</span><span
  style="font-family: 宋体;font-size: 14px"
>人在海南</span><span
  style="font-family: 宋体;font-size: 14px"
>愿免费向您提供上述服务。</span><span
  style="font-family: 宋体;font-size: 14px"
>人在海南</span><span
  style="font-family: 宋体;font-size: 14px"
>将不断提高并优化技术水平，以提供您更多个性化服务。</span></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family: 宋体;font-size: 14px">&nbsp;</span></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family: 宋体;font-size: 14px">4</span><span
  style="font-family: 宋体;font-size: 14px"
>、</span><span
  style="font-family: 宋体;font-size: 14px"
>人在海南</span><span
  style="font-family: 宋体;font-size: 14px"
>为您提供一个分享</span><span
  style="font-family: 宋体;font-size: 14px"
>职场</span><span
  style="font-family: 宋体;font-size: 14px"
>生活的平台。您可将您创作的或取得合法授权的作品分享给</span><span
  style="font-family: 宋体;font-size: 14px"
>人在海南</span><span
  style="font-family: 宋体;font-size: 14px"
>平台用户，如您希望将上述作品分享给更多用户，</span><span
  style="font-family: 宋体;font-size: 14px"
>人在海南</span><span
  style="font-family: 宋体;font-size: 14px"
>同时支持您将上述作品分享到第三方平台，</span><strong><span style="font-family: 宋体;font-size: 14px">但第三方平台对此有限制或禁止的除外。</span></strong></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><strong><span style="font-family: 宋体;font-size: 14px">&nbsp;</span></strong></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family: 宋体;font-size: 14px">5</span><span
  style="font-family: 宋体;font-size: 14px"
>、</span><span
  style="font-family: 宋体;font-size: 14px"
>人在海南</span><span
  style="font-family: 宋体;font-size: 14px"
>尊重并保护您的发表权。</span><strong><span style="font-family: 宋体;font-size: 14px">人在海南</span></strong><strong><span style="font-family: 宋体;font-size: 14px">承诺为您的上述作品提供云储存空间，您可在任何时间、任何地点查看您的全部作品。</span></strong><strong><span style="font-family: 宋体;font-size: 14px">人在海南</span></strong><strong><span style="font-family: 宋体;font-size: 14px">将不会删除您的作品，但违反法规政策及</span></strong><strong><span style="font-family: 宋体;font-size: 14px">人在海南</span></strong><strong><span style="font-family: 宋体;font-size: 14px">用户规则的作品除外。</span></strong></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><strong><span style="font-family: 宋体;font-size: 14px">&nbsp;</span></strong></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family: 宋体;font-size: 14px">6</span><span
  style="font-family: 宋体;font-size: 14px"
>、</span><span
  style="font-family: 宋体;font-size: 14px"
>人在海南</span><span
  style="font-family: 宋体;font-size: 14px"
>尊重您的修改权和保护作品完整权。您的作品仅您的账户有权修改。如其他人未经您的授权修改您的作品，或对您的作品进行歪曲、丑化、篡改，您可以通过司法、行政等救济途径向侵权行为人追偿，如您需要</span><span
  style="font-family: 宋体;font-size: 14px"
>人在海南</span><span
  style="font-family: 宋体;font-size: 14px"
>平台</span><span
  style="font-family: 宋体;font-size: 14px"
>协助的，</span><span
  style="font-family: 宋体;font-size: 14px"
>人在海南平</span><span
  style="font-family: 宋体;font-size: 14px"
>台</span><span
  style="font-family: 宋体;font-size: 14px"
>会尽力协助。</span></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family: 宋体;font-size: 14px">&nbsp;</span></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family: 宋体;font-size: 14px">7</span><span
  style="font-family: 宋体;font-size: 14px"
>、</span><span
  style="font-family: 宋体;font-size: 14px"
>人在海南</span><span
  style="font-family: 宋体;font-size: 14px"
>尊重并保护您的署名权。</span><span
  style="font-family: 宋体;font-size: 14px"
>人在海南</span><span
  style="font-family: 宋体;font-size: 14px"
>将您的头像及账户名称在您作品页以显著形式进行展示，如其他用户观看您的作品，可通过点击您的头像或账户名称将您添加为关注。如您将作品分享给其他</span><span
  style="font-family: 宋体;font-size: 14px"
>人在海南</span><span
  style="font-family: 宋体;font-size: 14px"
>用户或分享到第三方平台，</span><span
  style="font-family: 宋体;font-size: 14px"
>人在海南</span><span
  style="font-family: 宋体;font-size: 14px"
>将通过技术手段展示您的账户名称及作品标题。</span></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family: 宋体;font-size: 14px">&nbsp;</span></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family: 宋体;font-size: 14px">8</span><span
  style="font-family: 宋体;font-size: 14px"
>、</span><span
  style="font-family: 宋体;font-size: 14px"
>人在海南</span><span
  style="font-family: 宋体;font-size: 14px"
>尊重并努力维护您的肖像权、名誉权等合法权益。您在使用</span><span
  style="font-family: 宋体;font-size: 14px"
>人在海南</span><span
  style="font-family: 宋体;font-size: 14px"
>软件及相关服务时，如您有合理证据认为您的肖像权、名誉权等合法权益受到侵犯的，您可以通过司法、行政等救济途径向侵权行为人追偿，如您需要我们协助的，我们会尽力协助。</span></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family: 宋体;font-size: 14px">&nbsp;</span></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family: 宋体;font-size: 14px">9</span><span
  style="font-family: 宋体;font-size: 14px"
>、为了您的作品得到及时的分享与推广，</span><span
  style="font-family: 宋体;font-size: 14px"
>人在海南</span><span
  style="font-family: 宋体;font-size: 14px"
>提供了作品下载功能。</span><strong><span style="font-family: 宋体;font-size: 14px">如您已开放查看权限，则默认其他用户可以下载您的作品，</span></strong><strong><span style="font-family: 宋体;font-size: 14px">人在海南</span></strong><strong><span style="font-family: 宋体;font-size: 14px">将尝试通过多种技术手段尽最大努力保护您的作品的知识产权。</span></strong></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family: 宋体;font-size: 14px">&nbsp;</span></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family: 宋体;font-size: 14px">1</span><span
  style="font-family: 宋体;font-size: 14px"
>0</span><span
  style="font-family: 宋体;font-size: 14px"
>、当您使用</span><span
  style="font-family: 宋体;font-size: 14px"
>人在海南</span><span
  style="font-family: 宋体;font-size: 14px"
>提供的服务时，您可以关注您感兴趣的用户并随时查看其作品。如您希望获知您关注用户的最新动态，您可以接受</span><span
  style="font-family: 宋体;font-size: 14px"
>人在海南</span><span
  style="font-family: 宋体;font-size: 14px"
>提供的通知服务。您也可通过</span><span
  style="font-family: 宋体;font-size: 14px"
>人在海南</span><span
  style="font-family: 宋体;font-size: 14px"
>提供的关注页面查看所有您关注的用户作品，</span><span
  style="font-family: 宋体;font-size: 14px"
>人在海南</span><span
  style="font-family: 宋体;font-size: 14px"
>通过技术手段提供您筛选服务，即您的关注页面不会出现您未关注的用户或作品。</span></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family: 宋体;font-size: 14px">&nbsp;</span></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family: 宋体;font-size: 14px">1</span><span
  style="font-family: 宋体;font-size: 14px"
>1</span><span
  style="font-family: 宋体;font-size: 14px"
>、您可使用</span><span
  style="font-family: 宋体;font-size: 14px"
>人在海南</span><span
  style="font-family: 宋体;font-size: 14px"
>提供的服务与其他</span><span
  style="font-family: 宋体;font-size: 14px"
>人在海南</span><span
  style="font-family: 宋体;font-size: 14px"
>用户进行交流。您可通过私信、群聊方式与其他</span><span
  style="font-family: 宋体;font-size: 14px"
>人在海南</span><span
  style="font-family: 宋体;font-size: 14px"
>用户建立联系、表达您的想法，也可在自己或其他用户的作品下留言。同时，</span><span
  style="font-family: 宋体;font-size: 14px"
>人在海南</span><span
  style="font-family: 宋体;font-size: 14px"
>完全尊重用户的选择，您可以通过系统设置，选择不接收其他用户的留言或私信。</span><strong><span style="font-family: 宋体;font-size: 14px">您应知悉并同意，您在使用</span></strong><strong><span style="font-family: 宋体;font-size: 14px">人在海南</span></strong><strong><span
  style="font-family: 宋体;font-size: 14px"
>提供的包括但不限于私信、群聊、留言、评论等用户交流服务能时，应遵守国家有关即时通信、互联网群聊、跟帖评论、论坛社区等的法律规定及《</span></strong><strong><span style="font-family: 宋体;font-size: 14px">人在海南</span></strong><strong><span style="font-family: 宋体;font-size: 14px">社区管理规定》等</span></strong><strong><span style="font-family: 宋体;font-size: 14px">人在海南</span></strong><strong><span style="font-family: 宋体;font-size: 14px">平台相关规定。</span></strong></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family: 宋体;font-size: 14px">&nbsp;</span></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family: 宋体;font-size: 14px">1</span><span
  style="font-family: 宋体;font-size: 14px"
>2</span><span
  style="font-family: 宋体;font-size: 14px"
>、</span><span
  style="font-family: 宋体;font-size: 14px"
>人在海南</span><span
  style="font-family: 宋体;font-size: 14px"
>认识到维护您的隐私对您使用</span><span
  style="font-family: 宋体;font-size: 14px"
>人在海南</span><span
  style="font-family: 宋体;font-size: 14px"
>服务的重要作用。</span><span
  style="font-family: 宋体;font-size: 14px"
>人在海南</span><span
  style="font-family: 宋体;font-size: 14px"
>努力为您的信息安全提供保障，</span><span
  style="font-family: 宋体;font-size: 14px"
>人在海南</span><span
  style="font-family: 宋体;font-size: 14px"
>通过加密技术保护您的个人信息，同时</span><span
  style="font-family: 宋体;font-size: 14px"
>人在海南</span><span
  style="font-family: 宋体;font-size: 14px"
>通过建立专门的管理制度、严格限制访问信息的人员范围、建立数据安全专项部门及安全应急响应组织等方式来保障信息的安全。您可通过查阅</span><strong><span style="font-family: 宋体;font-size: 14px">《隐私权保护政策》</span></strong><span
  style="font-family: 宋体;font-size: 14px"
>来了解更多</span><span
  style="font-family: 宋体;font-size: 14px"
>人在海南</span><span
  style="font-family: 宋体;font-size: 14px"
>隐私保障服务。</span></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family: 宋体;font-size: 14px">&nbsp;</span></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family: 宋体;font-size: 14px">1</span><span
  style="font-family: 宋体;font-size: 14px"
>3</span><span
  style="font-family: 宋体;font-size: 14px"
>、</span><span
  style="font-family: 宋体;font-size: 14px"
>人在海南</span><span
  style="font-family: 宋体;font-size: 14px"
>尊重用户的正当权利、鼓励您在合法权利受到侵犯时采取维权行动。当您的权利受到侵犯时，您可以通过司法、行政等救济途径向侵权行为人追偿。您知悉，协助用户维护权利并非</span><span
  style="font-family: 宋体;font-size: 14px"
>人在海南</span><span
  style="font-family: 宋体;font-size: 14px"
>义务，但</span><span
  style="font-family: 宋体;font-size: 14px"
>人在海南</span><span
  style="font-family: 宋体;font-size: 14px"
>愿为维护您的权益、建立良好有序的社区尽一切努力。如您需要我们协助的，我们愿尽力协助。</span></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><strong><span style="font-family: 宋体;font-size: 14px">&nbsp;</span></strong></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><strong><span style="font-family: 宋体;font-size: 14px">三、知识产权</span></strong></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family: 宋体;font-size: 14px">1、</span><span
  style="font-family: 宋体;font-size: 14px"
>人在海南</span><span
  style="font-family: 宋体;font-size: 14px"
>致力于为您打造一个平等的用户</span><span
  style="font-family: 宋体;font-size: 14px"
>职场</span><span
  style="font-family: 宋体;font-size: 14px"
>生活分享平台，</span><span
  style="font-family: 宋体;font-size: 14px"
>人在海南</span><span
  style="font-family: 宋体;font-size: 14px"
>尊重和鼓励您创作内容，认识到鼓励和保护知识产权对</span><span
  style="font-family: 宋体;font-size: 14px"
>人在海南</span><span
  style="font-family: 宋体;font-size: 14px"
>生存和发展的重要性，</span><span
  style="font-family: 宋体;font-size: 14px"
>人在海南</span><span
  style="font-family: 宋体;font-size: 14px"
>将保护知识产权作为运营的重要原则之一；为了更好地保护您的合法权益，您确认并同意授权</span><span
  style="font-family: 宋体;font-size: 14px"
>人在海南</span><span
  style="font-family: 宋体;font-size: 14px"
>以</span><span
  style="font-family: 宋体;font-size: 14px"
>人在海南</span><span
  style="font-family: 宋体;font-size: 14px"
>自己的名义或委托专业第三方对侵犯您上传发布的享有合法权利的作品进行维权。</span></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><strong><span style="font-family: 宋体;font-size: 14px">&nbsp;</span></strong></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><strong><span style="font-family: 宋体;font-size: 14px">2、</span></strong><strong><span style="font-family: 宋体;font-size: 14px">人在海南</span></strong><strong><span style="font-family: 宋体;font-size: 14px"><span style="font-family:宋体">尊重并保护用户及他人的知识产权、名誉权、姓名权、隐私权等合法权益。您保证，在使用</span>“</span></strong><strong><span style="font-family: 宋体;font-size: 14px">人在海南</span></strong><strong><span
  style="font-family: 宋体;font-size: 14px"
>”软件及相关服务时上传的文字、图片、视频、音频、链接等均属于您原创或已合法授权，不侵犯任何第三方的知识产权、名誉权、姓名权、隐私权等权利及合法权益，其中需要获得授权的，已获得第三方授权及转授权。您使用</span></strong><strong><span style="font-family: 宋体;font-size: 14px">人在海南</span></strong><strong><span
  style="font-family: 宋体;font-size: 14px"
>软件及相关服务时上传、发布的任何内容的知识产权归属您或原始著作权人所有。针对第三方提出的全部权利主张，您应自行处理并承担可能因此产生的全部法律责任；如因您的侵权行为导致公司及其关联方、控制公司、继承公司遭受损失的（包括但不限于经济、商誉等损失），您还应足额赔偿公司及其关联方、控制公司、继承公司遭受的全部损失。基于部分服务功能的特性，您通过</span></strong><strong><span style="font-family: 宋体;font-size: 14px">人在海南</span></strong><strong><span style="font-family: 宋体;font-size: 14px"><span style="font-family:宋体">软件上传的内容（包括但不限于音频、声音、对话及视频</span>/音频中包含的音频、声音、对话等）可供其他</span></strong><strong><span style="font-family: 宋体;font-size: 14px">人在海南</span></strong><strong><span style="font-family: 宋体;font-size: 14px">用户使用</span></strong><strong><span style="font-family: 宋体;font-size: 14px">人在海南</span></strong><strong><span style="font-family: 宋体;font-size: 14px">软件创作并发布内容时使用，用户根据前述约定再创作的作品仅能于</span></strong><strong><span style="font-family: 宋体;font-size: 14px">人在海南</span></strong><strong><span style="font-family: 宋体;font-size: 14px">平台或</span></strong><strong><span style="font-family: 宋体;font-size: 14px">人在海南</span></strong><strong><span style="font-family: 宋体;font-size: 14px">合作的第三方平台传播，未经</span></strong><strong><span style="font-family: 宋体;font-size: 14px">人在海南</span></strong><strong><span style="font-family: 宋体;font-size: 14px">同意，不得用于任何商业用途。</span></strong></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><strong><span style="font-family: 宋体;font-size: 14px">&nbsp;</span></strong></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><strong><span style="font-family: 宋体;font-size: 14px">3、您应知悉并承诺，您使用</span></strong><strong><span style="font-family: 宋体;font-size: 14px">人在海南</span></strong><strong><span style="font-family: 宋体;font-size: 14px">软件内的下载功能下载的用户作品仅供您个人学习、研究或欣赏，未经</span></strong><strong><span style="font-family: 宋体;font-size: 14px">人在海南</span></strong><strong><span style="font-family: 宋体;font-size: 14px"><span style="font-family:宋体">及</span>/或作品权利人的许可，您不得将作品用于本条约定的使用范围之外的任何其他用途，否则，您应承担由此导致的一切法律责任。</span></strong></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><strong><span style="font-family: 宋体;font-size: 14px">&nbsp;</span></strong></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><strong><span style="font-family: 宋体;font-size: 14px">4、您应确保对您在使用</span></strong><strong><span style="font-family: 宋体;font-size: 14px">人在海南</span></strong><strong><span
  style="font-family: 宋体;font-size: 14px"
>软件及相关服务期间发表、上传的全部内容拥有合法权利或取得相应授权，如果第三方提出关于知识产权的异议，为了保护合法权利人的知识产权，</span></strong><strong><span style="font-family: 宋体;font-size: 14px">人在海南</span></strong><strong><span style="font-family: 宋体;font-size: 14px">有权根据实际情况删除相关内容，并尽可能在处理之后通知您，以便与您与权利人沟通解决相关事宜。</span></strong></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><strong><span style="font-family: 宋体;font-size: 14px">&nbsp;</span></strong></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><strong><span style="font-family: 宋体;font-size: 14px">5、为提高您作品曝光量及发布效率，以便供给您更优质的服务，您同意您在</span></strong><strong><span style="font-family: 宋体;font-size: 14px">人在海南</span></strong><strong><span style="font-family: 宋体;font-size: 14px">账号所发布的全部内容均授权</span></strong><strong><span style="font-family: 宋体;font-size: 14px">人在海南</span></strong><strong><span style="font-family: 宋体;font-size: 14px">以您的账号在各版本间自动同步。您在</span></strong><strong><span style="font-family: 宋体;font-size: 14px">人在海南</span></strong><strong><span style="font-family: 宋体;font-size: 14px">发布、修改、删除内容、使用评论、点赞、私信等使用</span></strong><strong><span style="font-family: 宋体;font-size: 14px">人在海南</span></strong><strong><span style="font-family: 宋体;font-size: 14px">软件中各功能的操作，以及因使用前述功能、关注、消息、设置、搜索等功能而获得信息或结果，均会同步到</span></strong><strong><span style="font-family: 宋体;font-size: 14px">人在海南</span></strong><strong><span style="font-family: 宋体;font-size: 14px">各版本客户端软件及网站。</span></strong></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><strong><span style="font-family: 宋体;font-size: 14px">&nbsp;</span></strong></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><strong><span style="font-family: 宋体;font-size: 14px">6、为使您的作品得到更好的分享及推广，扩大您的影响力，</span></strong><strong><span style="font-family: 宋体;font-size: 14px">人在海南</span></strong><strong><span
  style="font-family: 宋体;font-size: 14px"
>会在获得您授权及不影响您权利完整性的前提下，为您的作品提供额外的一些展示和推广机会。在您可以撤销授权的前提下，您同意将您在使用</span></strong><strong><span style="font-family: 宋体;font-size: 14px">人在海南</span></strong><strong><span style="font-family: 宋体;font-size: 14px"><span style="font-family:宋体">软件及相关服务时发表</span>/上传的文字、图片、视频、音频等内容，授予</span></strong><strong><span style="font-family: 宋体;font-size: 14px">人在海南</span></strong><strong><span
  style="font-family: 宋体;font-size: 14px"
>及其关联公司、承继公司全球范围内免费的、非独家使用、可再许可的权利（包括但不限于复制权、改编权、翻译权、汇编权、表演权、信息网络传播权、制作衍生品等），前述授权使用的范围包括使用您授权内容中展示的肖像、音频内容、姓名、商标、品牌、名称、标识等内容。您同意</span></strong><strong><span style="font-family: 宋体;font-size: 14px">人在海南</span></strong><strong><span style="font-family: 宋体;font-size: 14px">可以为宣传推广之目的自行或许可第三方使用上述权利。若您不希望</span></strong><strong><span style="font-family: 宋体;font-size: 14px">人在海南</span></strong><strong><span style="font-family: 宋体;font-size: 14px">继续使用全部或部分已授权内容，可通过</span></strong><strong><span style="font-family: 宋体;font-size: 14px">人在海南</span></strong><strong><span style="font-family: 宋体;font-size: 14px">公示的方式通知</span></strong><strong><span style="font-family: 宋体;font-size: 14px">人在海南</span></strong><strong><span style="font-family: 宋体;font-size: 14px">撤销该授权。</span></strong></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><strong><span style="font-family: 宋体;font-size: 14px">&nbsp;</span></strong></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><strong><span style="font-family: 宋体;font-size: 14px"><span style="font-family:宋体">四、监护人特别提示</span>&nbsp;</span></strong></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><strong><span style="font-family: 宋体;font-size: 14px">1、若您的被监护人使用</span></strong><strong><span style="font-family: 宋体;font-size: 14px">人在海南</span></strong><strong><span style="font-family: 宋体;font-size: 14px">软件和服务的，您作为监护人，应指导并监督被监护人的注册和使用行为，如您的被监护人在</span></strong><strong><span style="font-family: 宋体;font-size: 14px">人在海南</span></strong><strong><span style="font-family: 宋体;font-size: 14px">平台上申请注册帐号，</span></strong><strong><span style="font-family: 宋体;font-size: 14px">人在海南</span></strong><strong><span style="font-family: 宋体;font-size: 14px">将默认其已得到您的同意。</span></strong></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><strong><span style="font-family: 宋体;font-size: 14px">&nbsp;</span></strong></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><strong><span style="font-family: 宋体;font-size: 14px">2</span></strong><strong><span style="font-family: 宋体;font-size: 14px">、</span></strong><strong><span style="font-family: 宋体;font-size: 14px">人在海南</span></strong><strong><span style="font-family: 宋体;font-size: 14px">一直以来重视未成年人的健康成长，</span></strong><strong><span style="font-family: 宋体;font-size: 14px">人在海南</span></strong><strong><span style="font-family: 宋体;font-size: 14px">愿和您一起给予未成年人正面鼓励和引导，如您需要</span></strong><strong><span style="font-family: 宋体;font-size: 14px">人在海南</span></strong><strong><span style="font-family: 宋体;font-size: 14px">协助时请及时通知</span></strong><strong><span style="font-family: 宋体;font-size: 14px">人在海南</span></strong><strong><span style="font-family: 宋体;font-size: 14px">。</span></strong><strong><span style="font-family: 宋体;font-size: 14px">人在海南</span></strong><strong><span style="font-family: 宋体;font-size: 14px">特别提示未成年人用户及监护人，应在合理程度内正确使用网络，在使用</span></strong><strong><span style="font-family: 宋体;font-size: 14px">人在海南</span></strong><strong><span style="font-family: 宋体;font-size: 14px">提供的服务时应遵守全国青少年网络文明公约：</span></strong></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><strong><span style="font-family: 宋体;font-size: 14px">1) 要善于网上学习，不浏览不良信息；</span></strong></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><strong><span style="font-family: 宋体;font-size: 14px">2) 要诚实友好交流，不侮辱欺诈他人；</span></strong></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><strong><span style="font-family: 宋体;font-size: 14px">3) 要增强自护意识，不随意约会网友；</span></strong></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><strong><span style="font-family: 宋体;font-size: 14px">4) 要维护网络安全，不破坏网络秩序；</span></strong></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><strong><span style="font-family: 宋体;font-size: 14px">5) 要有益身心健康，不沉溺虚拟时空。</span></strong></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><strong><span style="font-family: 宋体;font-size: 14px">&nbsp;</span></strong></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><strong><span style="font-family: 宋体;font-size: 14px">3</span></strong><strong><span style="font-family: 宋体;font-size: 14px">、特别的，为更好的保护未成年人隐私权益，</span></strong><strong><span style="font-family: 宋体;font-size: 14px">人在海南</span></strong><strong><span
  style="font-family: 宋体;font-size: 14px"
>提醒您慎重发布包含未成年人影像的内容，一经发布，即视为您已取得权利人同意展示未成年人的肖像、声音等信息，且允许</span></strong><strong><span style="font-family: 宋体;font-size: 14px">人在海南</span></strong><strong><span style="font-family: 宋体;font-size: 14px">依据本协议使用、管理该与未成年人相关的内容。如权利人通知</span></strong><strong><span style="font-family: 宋体;font-size: 14px">人在海南</span></strong><strong><span style="font-family: 宋体;font-size: 14px">您发布的内容侵犯未成年人权利，出于保护权利人及未成年人权利的考虑，</span></strong><strong><span style="font-family: 宋体;font-size: 14px">人在海南</span></strong><strong><span style="font-family: 宋体;font-size: 14px">有权对您发布的内容进行处理并通知您。</span></strong></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><strong><span style="font-family: 宋体;font-size: 14px">&nbsp;</span></strong></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><strong><span style="font-family: 宋体;font-size: 14px">五、服务注册与使用</span></strong></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family: 宋体;font-size: 14px">1、</span><span
  style="font-family: 宋体;font-size: 14px"
>人在海南</span><span
  style="font-family: 宋体;font-size: 14px"
>特别提醒您应该妥善保管您的账户。</span><span
  style="font-family: 宋体;font-size: 14px"
>人在海南</span><span
  style="font-family: 宋体;font-size: 14px"
>与您共同负有维护账户安全的责任。</span><span
  style="font-family: 宋体;font-size: 14px"
>人在海南</span><span
  style="font-family: 宋体;font-size: 14px"
>在目前技术水平下努力保护您的账户在服务器端的安全，并不断更新技术措施。因您主动泄露或因您遭受他人攻击、诈骗等行为导致的损失及后果，您可以通过司法、行政等救济途径向侵权行为人追偿。如您需要</span><span
  style="font-family: 宋体;font-size: 14px"
>人在海南</span><span
  style="font-family: 宋体;font-size: 14px"
>协助的，</span><span
  style="font-family: 宋体;font-size: 14px"
>人在海南</span><span
  style="font-family: 宋体;font-size: 14px"
>会尽力协助。</span></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family: 宋体;font-size: 14px">&nbsp;</span></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><span
  style="font-family: 宋体;font-size: 14px"
>2、由于您的账户行为（包括但不限于在线签署各类协议、发布信息、购买商品及服务及披露信息等）均代表您本人行为，您应妥善保管您的账户信息及密码并对您账户行为的结果负责，</span><strong><span style="font-family: 宋体;font-size: 14px">故</span></strong><strong><span style="font-family: 宋体;font-size: 14px">人在海南</span></strong><strong><span style="font-family: 宋体;font-size: 14px">提示您勿将账户出借、赠与、出租、转让、售卖或以其他方式许可他人使用。</span></strong><span
  style="font-family: 宋体;font-size: 14px"
>如您的账户遭到未经授权的使用，您应当立即通知</span><span
  style="font-family: 宋体;font-size: 14px"
>人在海南</span><span
  style="font-family: 宋体;font-size: 14px"
>，以避免扩大的损失和后果；如</span><span
  style="font-family: 宋体;font-size: 14px"
>人在海南</span><span
  style="font-family: 宋体;font-size: 14px"
>判断您</span><span
  style="font-family: 宋体;font-size: 14px"
>人在海南</span><span
  style="font-family: 宋体;font-size: 14px"
><span style="font-family:宋体">账户的使用可能危及您的账户安全及</span>/或</span><span
  style="font-family: 宋体;font-size: 14px"
>人在海南</span><span
  style="font-family: 宋体;font-size: 14px"
>软件信息安全的，基于维护您的财产及信息安全考虑，您同意</span><span
  style="font-family: 宋体;font-size: 14px"
>人在海南</span><span
  style="font-family: 宋体;font-size: 14px"
>可中止提供相应服务直至您本人通过可信方式要求恢复。</span></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><strong><span style="font-family: 宋体;font-size: 14px">&nbsp;</span></strong></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><strong><span style="font-family: 宋体;font-size: 14px">3、您应理解并承诺，您所设置的</span></strong><strong><span style="font-family: 宋体;font-size: 14px">人在海南</span></strong><strong><span style="font-family: 宋体;font-size: 14px">账户信息不得违反国家法律法规及</span></strong><strong><span style="font-family: 宋体;font-size: 14px">人在海南</span></strong><strong><span style="font-family: 宋体;font-size: 14px">所提供的服务的相关规则。您设置的账户名称、</span></strong><strong><span style="font-family: 宋体;font-size: 14px">人在海南</span></strong><strong><span style="font-family: 宋体;font-size: 14px">账</span></strong><strong><span
  style="font-family: 宋体;font-size: 14px"
>号、头像、个人介绍及个人资料等注册信息中不得出现违法或不良信息，未经合法权利人同意不得使用其享有合法权利的信息（包括但不限于他人姓名、名称、商标、字号、肖像、头像等他人享有合法权利的信息）注册</span></strong><strong><span style="font-family: 宋体;font-size: 14px">人在海南</span></strong><strong><span style="font-family: 宋体;font-size: 14px"><span style="font-family:宋体">账户，不得通过频繁</span> <span style="font-family:宋体">注册、批量注册等行为恶意注册</span></span></strong><strong><span style="font-family: 宋体;font-size: 14px">人在海南</span></strong><strong><span style="font-family: 宋体;font-size: 14px">账户。</span></strong></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><strong><span style="font-family: 宋体;font-size: 14px">&nbsp;</span></strong></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><strong><span style="font-family: 宋体;font-size: 14px">4、根据国家相关法律法规规定，为使用</span></strong><strong><span style="font-family: 宋体;font-size: 14px">人在海南</span></strong><strong><span
  style="font-family: 宋体;font-size: 14px"
>软件及相关服务，您需要填写真实的身份信息并根据法律规定完成实名认证；您应保证注册及实名认证时使用真实、准确、合法、有效的身份证明材料及必要信息，当您使用的信息发生变更时，您应及时向</span></strong><strong><span style="font-family: 宋体;font-size: 14px">人在海南</span></strong><strong><span style="font-family: 宋体;font-size: 14px">申请更新，以保证您能更好的享受</span></strong><strong><span style="font-family: 宋体;font-size: 14px">人在海南</span></strong><strong><span style="font-family: 宋体;font-size: 14px">提供的服务。</span></strong></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><strong><span style="font-family: 宋体;font-size: 14px">&nbsp;</span></strong></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family: 宋体;font-size: 14px">5、为充分保护您的合法权益，当</span><span
  style="font-family: 宋体;font-size: 14px"
>人在海南</span><span
  style="font-family: 宋体;font-size: 14px"
><span style="font-family:宋体">发现您的账户处于异常状态（如疑似盗号、</span>6个自然月以上停止使用等）时，</span><span
  style="font-family: 宋体;font-size: 14px"
>人在海南</span><span
  style="font-family: 宋体;font-size: 14px"
>将视情况对该账号采取相应保护措施（如：暂停使用等），直至确认为您本人再次登录、使用该账号。</span><span
  style="font-family: 宋体;font-size: 14px"
>人在海南</span><span
  style="font-family: 宋体;font-size: 14px"
>在对此类账户进行清理前，将以包括但不限于弹窗、网站公告、站内消息、客户端推送信息等方式通知您。</span></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family: 宋体;font-size: 14px">&nbsp;</span></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family: 宋体;font-size: 14px">6、您不得利用</span><span
  style="font-family: 宋体;font-size: 14px"
>人在海南</span><span
  style="font-family: 宋体;font-size: 14px"
>软件进行任何形式的洗钱活动。</span><span
  style="font-family: 宋体;font-size: 14px"
>人在海南</span><span
  style="font-family: 宋体;font-size: 14px"
>将严密监控此类行为，对异常的、数额较大的虚拟资产流转进行调查，并有权冻结相应账号。</span><span
  style="font-family: 宋体;font-size: 14px"
>人在海南</span><span
  style="font-family: 宋体;font-size: 14px"
>亦有权配合相关司法行政等主管机关对洗钱活动进行调查取证，向其提供所涉及账户的身份信息、相关账户的交易记录等。</span></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><strong><span style="font-family: 宋体;font-size: 14px">&nbsp;</span></strong></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><strong><span style="font-family: 宋体;font-size: 14px">7、您可通过</span></strong><strong><span style="font-family: 宋体;font-size: 14px">人在海南</span></strong><strong><span style="font-family: 宋体;font-size: 14px"><span style="font-family:宋体">官网（</span>https://www.</span></strong><strong><span style="font-family: 宋体;font-size: 14px">renzaihainan</span></strong><strong><span style="font-family: 宋体;font-size: 14px">.com）上公示的联系方式及信息，联系官方客服人员注销帐号，如您对</span></strong><strong><span style="font-family: 宋体;font-size: 14px">人在海南</span></strong><strong><span style="font-family: 宋体;font-size: 14px">有任何意见或建议，亦可通过前述联系方式投诉举报。</span></strong></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><strong><span style="font-family: 宋体;font-size: 14px">&nbsp;</span></strong></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family: 宋体;font-size: 14px">8、</span><span
  style="font-family: 宋体;font-size: 14px"
>人在海南</span><span
  style="font-family: 宋体;font-size: 14px"
>在为您服务过程中可能以各种方式投放商业性广告或其他任何类型的商业信息（包括但不限于在</span><span
  style="font-family: 宋体;font-size: 14px"
>人在海南</span><span
  style="font-family: 宋体;font-size: 14px"
>平台的任何页面上投放广告），您同意并将收到</span><span
  style="font-family: 宋体;font-size: 14px"
>人在海南</span><span
  style="font-family: 宋体;font-size: 14px"
>通过电子邮件、私信或其他方式向您发送商品促销或其他相关商业信息。</span><strong><span style="font-family: 宋体;font-size: 14px">如您不愿意接收此类信息，您可以通过短信中提供的退订方式进行退订，也可以直接与我们联系进行退订。</span></strong></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><strong><span style="font-family: 宋体;font-size: 14px">&nbsp;</span></strong></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family: 宋体;font-size: 14px">9、</span><span
  style="font-family: 宋体;font-size: 14px"
>人在海南</span><span
  style="font-family: 宋体;font-size: 14px"
>将根据自身产品推广及维护等需求举办各类用户活动，详细活动规则见《</span><span
  style="font-family: 宋体;font-size: 14px"
>人在海南</span><span
  style="font-family: 宋体;font-size: 14px"
>用户活动规则》，用户参与活动前请先行阅读并知悉活动规则及相关条款内容。凡参与相应活动，即视为用户已阅读、理解、并同意按照活动规则约定执行。</span></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><strong><span style="font-family: 宋体;font-size: 14px">&nbsp;</span></strong></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><strong><span style="font-family: 宋体;font-size: 14px"><span style="font-family:宋体">六、服务或使用说明</span>&nbsp;</span></strong></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family: 宋体;font-size: 14px">人在海南</span><span
  style="font-family: 宋体;font-size: 14px"
>致力于提供一个高质量的有趣生活分享平台。为了保护用户创造的内容、鼓励平台内容的多元性、维护良好的社区环境，</span><span
  style="font-family: 宋体;font-size: 14px"
>人在海南</span><span
  style="font-family: 宋体;font-size: 14px"
>将依据本协议和社区管理规范中的条款对用户发布的信息和用户行为进行管理。</span></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family: 宋体;font-size: 14px">&nbsp;</span></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family: 宋体;font-size: 14px">1、违规信息界定：主要指用户利用</span><span
  style="font-family: 宋体;font-size: 14px"
>人在海南</span><span
  style="font-family: 宋体;font-size: 14px"
>提供的技术或服务上传、下载、发送或传播敏感信息和违反国家法律制度的信息，包括但不限于下列信息：</span></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family: 宋体;font-size: 14px">1）反对宪法所确定的基本原则的；</span></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family: 宋体;font-size: 14px">2）危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；</span></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family: 宋体;font-size: 14px">3）损害国家荣誉和利益的；</span></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family: 宋体;font-size: 14px">4）煽动民族仇恨、民族歧视，破坏民族团结的；</span></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family: 宋体;font-size: 14px">5）破坏国家宗教政策，宣扬邪教和封建迷信的；</span></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family: 宋体;font-size: 14px">6）散布谣言，扰乱社会秩序，破坏社会稳定的；</span></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family: 宋体;font-size: 14px">7）散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；</span></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family: 宋体;font-size: 14px">8）侮辱或者诽谤他人，侵害他人合法权益的</span></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family: 宋体;font-size: 14px">9）有可能涉及版权纠纷的非本人作品；</span></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family: 宋体;font-size: 14px">10）含有法律、行政法规禁止的其他内容的。</span></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family: 宋体;font-size: 14px">&nbsp;</span></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family: 宋体;font-size: 14px">2、违规行为界定：指用户利用</span><span
  style="font-family: 宋体;font-size: 14px"
>人在海南</span><span
  style="font-family: 宋体;font-size: 14px"
>提供的服务进行不尊重</span><span
  style="font-family: 宋体;font-size: 14px"
>人在海南</span><span
  style="font-family: 宋体;font-size: 14px"
>用户及其所分享内容、发布扰乱</span><span
  style="font-family: 宋体;font-size: 14px"
>人在海南</span><span
  style="font-family: 宋体;font-size: 14px"
>社区秩序的内容、影响用户体验、危及平台安全及损害他人权益的行为，包括但不限于：</span></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family: 宋体;font-size: 14px">1）为非法目的而使用</span><span
  style="font-family: 宋体;font-size: 14px"
>人在海南</span><span
  style="font-family: 宋体;font-size: 14px"
><span
  style="font-family:宋体"
>软件及相关服务，如冒充他人或机构，或讹称与任何人或实体有联系（包括设置失实的账户名称或接入另一您的账户），或恶意使用注册账户导致其他用户误认；上传</span>/发布骚扰性的、中伤他人的、辱骂性的、恐吓性的、庸俗淫秽的或其他任何非法或恶意的信息；</span></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><span
  style="font-family: 宋体;font-size: 14px"
>2）将无权传输的内容（例如内部资料、机密资料、侵犯任何人的专利、商标、著作权、商业秘密等专属权利之内、未经授权发布的个人信息等）进行上传、发布、以电子邮件或以其他方式传输；</span></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family: 宋体;font-size: 14px">3）参与或可能参与（由</span><span
  style="font-family: 宋体;font-size: 14px"
>人在海南</span><span
  style="font-family: 宋体;font-size: 14px"
>判断）任何违法违规、违反公共秩序或公序良俗的活动或交易，包括传授犯罪方法、出售任何非法药物、从事传销、洗钱、诈骗等行为；赌博、提供赌博数据或透过任何方法诱使他人参与赌博活动；</span></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family: 宋体;font-size: 14px">4）进行侵犯</span><span
  style="font-family: 宋体;font-size: 14px"
>人在海南</span><span
  style="font-family: 宋体;font-size: 14px"
><span style="font-family:宋体">及</span>/或关联公司合法权益的行为，包括但不限于：擅自使用</span><span
  style="font-family: 宋体;font-size: 14px"
>人在海南</span><span
  style="font-family: 宋体;font-size: 14px"
><span style="font-family:宋体">及</span>/或知识产权（包括但不限于</span><span
  style="font-family: 宋体;font-size: 14px"
>人在海南</span><span
  style="font-family: 宋体;font-size: 14px"
><span style="font-family:宋体">及</span>/或关联公司的商标、标志等专有数据或任何网页的布局或设计等享有的合法权益）；模仿</span><span
  style="font-family: 宋体;font-size: 14px"
>人在海南</span><span
  style="font-family: 宋体;font-size: 14px"
><span style="font-family:宋体">及</span>/或关联公司服务的外观和功能；使用任何自动化程序、软件、引擎、网络爬虫、网页分析工具、数据挖掘工具或类似工具，接入</span><span
  style="font-family: 宋体;font-size: 14px"
>人在海南</span><span
  style="font-family: 宋体;font-size: 14px"
><span style="font-family:宋体">及</span>/或关联公司服务、收集或处理通过</span><span
  style="font-family: 宋体;font-size: 14px"
>人在海南</span><span
  style="font-family: 宋体;font-size: 14px"
><span style="font-family:宋体">及</span>/或关联公司服务所提供的内容；对</span><span
  style="font-family: 宋体;font-size: 14px"
>人在海南</span><span
  style="font-family: 宋体;font-size: 14px"
><span style="font-family:宋体">及</span>/或关联公司服务所用的软件进行解编、反向编译或逆向工程；规避（或试图规避）</span><span
  style="font-family: 宋体;font-size: 14px"
>人在海南</span><span
  style="font-family: 宋体;font-size: 14px"
><span style="font-family:宋体">及</span>/或关联公司服务、系统或其他您的系统的任何安全功能；</span></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family: 宋体;font-size: 14px">5）利用</span><span
  style="font-family: 宋体;font-size: 14px"
>人在海南</span><span
  style="font-family: 宋体;font-size: 14px"
>软件或相关服务系统进行任何可能对互联网的正常运转造成不利影响的行为，如故意散播含有干扰、破坏或限制计算机软件、硬件或通讯设备功能、</span><span
  style="font-family: 宋体;font-size: 14px"
>人在海南</span><span
  style="font-family: 宋体;font-size: 14px"
><span style="font-family:宋体">及</span>/或关联公司服务、与</span><span
  style="font-family: 宋体;font-size: 14px"
>人在海南</span><span
  style="font-family: 宋体;font-size: 14px"
><span style="font-family:宋体">及</span>/或关联公司服务相连的服务器和网络的病毒、网络蠕虫、特洛伊木马病毒、已损毁的档案或其他恶意代码或项目等资料；</span></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family: 宋体;font-size: 14px">6）为破坏或滥用的目的开设多个账户，或恶意上传重复的、无效的大容量数据和信息；</span></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family: 宋体;font-size: 14px">7）进行破坏</span><span
  style="font-family: 宋体;font-size: 14px"
>人在海南</span><span
  style="font-family: 宋体;font-size: 14px"
><span style="font-family:宋体">及</span>/或关联公司服务公平性或者其他影响应用正常秩序的行为，如主动或被动刷分、合伙作弊、使用外挂或者其他的作弊软件、利用BUG（又叫“漏洞”或者“缺陷”）来获得不正当的非法利益，或者利用互联网或其他方式将外挂、作弊软件、BUG公之于众；</span></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family: 宋体;font-size: 14px">8）未取得</span><span
  style="font-family: 宋体;font-size: 14px"
>人在海南</span><span
  style="font-family: 宋体;font-size: 14px"
><span style="font-family:宋体">及</span>/或关联公司之书面同意，通过任何渠道或媒体（包括但不限于自媒体等）发出“与</span><span
  style="font-family: 宋体;font-size: 14px"
>人在海南</span><span
  style="font-family: 宋体;font-size: 14px"
><span style="font-family:宋体">合作</span>”、“与</span><span
  style="font-family: 宋体;font-size: 14px"
>人在海南</span><span
  style="font-family: 宋体;font-size: 14px"
><span style="font-family:宋体">共同出品</span>”等任何携带“</span><span
  style="font-family: 宋体;font-size: 14px"
>人在海南</span><span
  style="font-family: 宋体;font-size: 14px"
>”品牌的字样；</span></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family: 宋体;font-size: 14px">9）其他任何违反中国法律、法规、规章、条例等其他具有法律效力的规定或</span><span
  style="font-family: 宋体;font-size: 14px"
>人在海南</span><span
  style="font-family: 宋体;font-size: 14px"
>平台规则的行为。</span></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><strong><span style="font-family: 宋体;font-size: 14px">&nbsp;</span></strong></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><strong><span style="font-family: 宋体;font-size: 14px">3、</span></strong><strong><span style="font-family: 宋体;font-size: 14px">人在海南</span></strong><strong><span style="font-family: 宋体;font-size: 14px">有权根据国家法律及相关部门的要求对您上传、发布的内容进行审核，如您上传、发布违规信息或您在使用</span></strong><strong><span style="font-family: 宋体;font-size: 14px">人在海南</span></strong><strong><span style="font-family: 宋体;font-size: 14px">软件及相关服务中从事违法违规行为，</span></strong><strong><span style="font-family: 宋体;font-size: 14px">人在海南</span></strong><strong><span style="font-family: 宋体;font-size: 14px">有权根据相关证据结合现行法律法规、规章及</span></strong><strong><span style="font-family: 宋体;font-size: 14px">人在海南</span></strong><strong><span style="font-family: 宋体;font-size: 14px">平台社区规则对违法违规信息进行处理。</span></strong></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><strong><span style="font-family: 宋体;font-size: 14px">&nbsp;</span></strong></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><strong><span style="font-family: 宋体;font-size: 14px">七、不可抗力与例外</span></strong></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><strong><span style="font-family: 宋体;font-size: 14px">1、鉴于网络服务的特殊性（包括但不限于服务器的稳定性问题、恶意的网络攻击等行为的存在及其他</span></strong><strong><span style="font-family: 宋体;font-size: 14px">人在海南</span></strong><strong><span style="font-family: 宋体;font-size: 14px">无法控制的情形），</span></strong><strong><span style="font-family: 宋体;font-size: 14px">人在海南</span></strong><strong><span style="font-family: 宋体;font-size: 14px">提供的服务可能会出现中断、不能及时传送信息或其他不能满足您的要求的情况，请您知悉并理解，该情况将不视为</span></strong><strong><span style="font-family: 宋体;font-size: 14px">人在海南</span></strong><strong><span style="font-family: 宋体;font-size: 14px">违反本协议约定；但</span></strong><strong><span style="font-family: 宋体;font-size: 14px">人在海南</span></strong><strong><span
  style="font-family: 宋体;font-size: 14px"
>将尽可能及时通过网页公告、系统通知、私信、短信提醒或其他合理方式通知您，并尽最大努力避免或减轻您可能受到的影响。</span></strong></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><strong><span style="font-family: 宋体;font-size: 14px">&nbsp;</span></strong></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><strong><span style="font-family: 宋体;font-size: 14px">2、</span></strong><strong><span style="font-family: 宋体;font-size: 14px">人在海南</span></strong><strong><span style="font-family: 宋体;font-size: 14px">通过中华人民共和国境内的设施控制和提供服务，</span></strong><strong><span style="font-family: 宋体;font-size: 14px">人在海南</span></strong><strong><span style="font-family: 宋体;font-size: 14px">无法担保您的使用行为、发布的信息及内容在其他国家或地区是适当的、可行的，当您在其他司法辖区使用</span></strong><strong><span style="font-family: 宋体;font-size: 14px">人在海南</span></strong><strong><span style="font-family: 宋体;font-size: 14px">软件及相关服务时，您应自行确保其遵守当地的法律法规，</span></strong><strong><span style="font-family: 宋体;font-size: 14px">人在海南</span></strong><strong><span style="font-family: 宋体;font-size: 14px">对此不承担任何责任。</span></strong></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><strong><span style="font-family: 宋体;font-size: 14px">&nbsp;</span></strong></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family:宋体;font-weight:bold;font-size:14px">3、</span><strong><span style="font-family: 宋体;font-size: 14px">如</span></strong><strong><span style="font-family: 宋体;font-size: 14px">人在海南</span></strong><strong><span style="font-family: 宋体;font-size: 14px">发现、或通过第三方举报或投诉获知，您发布的内容存在或涉嫌违反国家法律法规或</span></strong><strong><span style="font-family: 宋体;font-size: 14px">人在海南</span></strong><strong><span style="font-family: 宋体;font-size: 14px">平台规则的，</span></strong><strong><span style="font-family: 宋体;font-size: 14px">人在海南</span></strong><strong><span
  style="font-family: 宋体;font-size: 14px"
>平台有权依据其合理判断不经通知立即采取一切必要措施（包括但不限于冻结账户、封号、向公安机关报案等），或配合公检法等国家机关的执法要求对账户做相应处理，以减轻或消除您行为造成的影响。账户被封号处理期间，您的</span></strong><strong><span style="font-family: 宋体;font-size: 14px">人在海南</span></strong><strong><span style="font-family: 宋体;font-size: 14px">账户内的财产将被冻结。</span></strong></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><strong><span style="font-family: 宋体;font-size: 14px">&nbsp;</span></strong></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><strong><span style="font-family: 宋体;font-size: 14px">八、</span></strong><strong><span style="font-family: 宋体;font-size: 14px">风险与免责</span></strong></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><strong><span style="font-family: 宋体;font-size: 14px">1、用户在使用本软件及服务时，须自行承担如下不可掌控的风险内容，包括但不限于：</span></strong></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><strong><span style="font-family: 宋体;font-size: 14px">1）由于受到计算机病毒、木马或其他恶意程序、黑客攻击的破坏等不可抗拒因素可能引起的信息丢失、泄漏等风险；</span></strong></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><strong><span style="font-family: 宋体;font-size: 14px">2）用户电脑软件、系统、硬件和通信线路出现故障；</span></strong></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><strong><span style="font-family: 宋体;font-size: 14px">3）用户操作不当或通过非“</span></strong><strong><span style="font-family: 宋体;font-size: 14px">人在海南</span></strong><strong><span style="font-family: 宋体;font-size: 14px">”授权的方式使用本服务；</span></strong></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><strong><span style="font-family: 宋体;font-size: 14px">4）用户发布的内容被他人转发、分享，因此等传播可能带来的风险和责任；</span></strong></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><strong><span style="font-family: 宋体;font-size: 14px">5）由于网络信号不稳定等原因，所引起的场景应用登录失败、资料同步不完整、页面打开速度慢等风险。</span></strong></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><strong><span style="font-family: 宋体;font-size: 14px">6）其他“</span></strong><strong><span style="font-family: 宋体;font-size: 14px">人在海南</span></strong><strong><span style="font-family: 宋体;font-size: 14px">”无法控制或合理预见的情形。</span></strong></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><strong><span
  style="font-family: 宋体;font-size: 14px"
>2、您理解并同意，用户通过本服务发布的内容一经发布即向公众传播和共享，可能会被其他用户或第三方复制、转载、修改或做其他用途，脱离您的预期和控制，用户应充分意识到此类风险的存在。如果相关行为侵犯了您的合法权益，您可以向“</span></strong><strong><span style="font-family: 宋体;font-size: 14px">人在海南</span></strong><strong><span style="font-family: 宋体;font-size: 14px">”投诉，我们将依法进行处理。</span></strong></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><strong><span style="font-family: 宋体;font-size: 14px">3、“</span></strong><strong><span style="font-family: 宋体;font-size: 14px">人在海南</span></strong><strong><span style="font-family: 宋体;font-size: 14px">”依据本协议约定获得处理违法违规内容或行为的权利，该权利不构成“</span></strong><strong><span style="font-family: 宋体;font-size: 14px">人在海南</span></strong><strong><span style="font-family: 宋体;font-size: 14px">”的义务或承诺，“</span></strong><strong><span style="font-family: 宋体;font-size: 14px">人在海南</span></strong><strong><span style="font-family: 宋体;font-size: 14px">”不能保证及时发现违法违规情形或进行相应处理。</span></strong></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><strong><span style="font-family: 宋体;font-size: 14px">&nbsp;</span></strong></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family:宋体;font-weight:bold;font-size:14px">九、</span><strong><span style="font-family: 宋体;font-size: 14px">法律责任</span></strong></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><strong><span style="font-family: 宋体;font-size: 14px">1、如果“</span></strong><strong><span style="font-family: 宋体;font-size: 14px">人在海南</span></strong><strong><span style="font-family: 宋体;font-size: 14px">”发现或收到他人举报或投诉用户违反本协议约定的，“</span></strong><strong><span style="font-family: 宋体;font-size: 14px">人在海南</span></strong><strong><span
  style="font-family: 宋体;font-size: 14px"
>”有权不经通知随时对相关内容进行删除、屏蔽，并视行为情节对违规帐号处以包括但不限于警告、删除部分或全部违规内容、限制或禁止使用部分或全部功能、帐号封禁直至注销的处罚，并公告处理结果。如果您发现任何人违反本协议规定或以其他不当的方式使用“</span></strong><strong><span style="font-family: 宋体;font-size: 14px">人在海南</span></strong><strong><span style="font-family: 宋体;font-size: 14px">”在线浏览创作及场景应用服务，请立即向“</span></strong><strong><span style="font-family: 宋体;font-size: 14px">人在海南</span></strong><strong><span style="font-family: 宋体;font-size: 14px">”举报或投诉，我们将依法进行处理。</span></strong></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><strong><span style="font-family: 宋体;font-size: 14px">2、您理解并同意，“</span></strong><strong><span style="font-family: 宋体;font-size: 14px">人在海南</span></strong><strong><span
  style="font-family: 宋体;font-size: 14px"
>”有权依合理判断对违反有关法律法规或本协议规定的行为进行处罚，对违法违规的任何人士采取适当的法律行动，并依据法律法规保存有关信息向有关部门报告等，用户应独自承担由此而产生的一切法律责任。</span></strong></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><strong><span
  style="font-family: 宋体;font-size: 14px"
>3、您理解并同意，因您违反本协议或相关服务条款的规定，导致或产生第三方主张的任何索赔、要求或损失，您应当独立承担责任；“</span></strong><strong><span style="font-family: 宋体;font-size: 14px">人在海南</span></strong><strong><span style="font-family: 宋体;font-size: 14px">”因此遭受损失的，您也应当一并赔偿。</span></strong></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><strong><span style="font-family: 宋体;font-size: 14px">&nbsp;</span></strong></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><strong><span style="font-family: 宋体;font-size: 14px">十</span></strong><strong><span style="font-family: 宋体;font-size: 14px">、其他</span></strong></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family: 宋体;font-size: 14px">1、为更好的为您提供服务，</span><span
  style="font-family: 宋体;font-size: 14px"
>人在海南</span><span
  style="font-family: 宋体;font-size: 14px"
>可能不定期对本协议进行修订，修订后的协议将通过公示的方式实现对您的告知。若您不接受修改的内容，您可以停止使用</span><span
  style="font-family: 宋体;font-size: 14px"
>人在海南</span><span
  style="font-family: 宋体;font-size: 14px"
>软件及相关服务，并可通过公示的方式联系</span><span
  style="font-family: 宋体;font-size: 14px"
>人在海南</span><span
  style="font-family: 宋体;font-size: 14px"
>完成账号注销操作。如果您继续使用的，视为您已接受修改后的协议。</span></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family: 宋体;font-size: 14px">&nbsp;</span></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><span
  style="font-family: 宋体;font-size: 14px"
>2、本协议之订立、生效、解释、修订、补充、终止、执行与争议解决均适用中华人民共和国大陆地区法律；如法律无相关规定的，参照商业惯例及/或行业惯例。</span></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><strong><span style="font-family: 宋体;font-size: 14px">&nbsp;</span></strong></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><strong><span style="font-family: 宋体;font-size: 14px">3、本协议的版权为</span></strong><strong><span style="font-family: 宋体;font-size: 14px">人在海南</span></strong><strong><span style="font-family: 宋体;font-size: 14px">平台所有，在法律法规允许的范围内，</span></strong><strong><span style="font-family: 宋体;font-size: 14px">人在海南</span></strong><strong><span style="font-family: 宋体;font-size: 14px">保留解释和修改的权利。</span></strong></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><span style=";font-family:宋体;font-size:14px">&nbsp;</span></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><span style="font-family: 宋体;font-size: 14px"><span style="font-family:宋体">更新日期：</span>2021年</span><span
  style="font-family: 宋体;font-size: 14px"
>11</span><span
  style="font-family: 宋体;font-size: 14px"
>月</span><span
  style="font-family: 宋体;font-size: 14px"
>26</span><span
  style="font-family: 宋体;font-size: 14px"
>日</span></p><p
  style="text-indent:0;text-autospace:ideograph-numeric"
><span style=";font-family:宋体;font-size:14px">&nbsp;</span></p><p><br /></p>
        </pre>
    </div>
    <!-- footerBox -->
    <div class="gwFooter">
      <FooterBox />
    </div>
  </div>
</template>

<script>
import HeadNav from "@/components/HeadNav.vue";
import FooterBox from "@/components/FooterBox.vue";
export default {
  name: "serviceAgreement",
  components: {
    HeadNav,
    FooterBox
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {}
};
</script>
<style lang="scss">

</style>