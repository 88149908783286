<template>
  <div class="haiMaiQuan">
    <!-- 导航栏 -->
    <HeadNav pageName="haiMaiQuan" />
    <!-- 海聘&聘人才 -->
    <div class="aboutBanner">
      <img src="@/assets/images/banner3.png" alt />
      <div class="usBox">
        <h2>海脉圈</h2>
        <p>在提供基本社交的基础上，提供社区的功能，在社区中用户可以加圈子发布内容，也可以浏览自己感兴趣的内容</p>
      </div>
    </div>
    <!-- 海脉圈-圈子 - li -->
    <div class="typeBox" :class="{'navBarWrap':navBarFixed}">
      <ul>
        <li
          v-for="(row, i) in typeList"
          :key="i"
          :class="{'current': current == row.id}"
          @click="choiceTap(row.id)"
        >{{row.name}}</li>
      </ul>
    </div>
    <!-- 海脉圈-圈子 -->
    <div class="hpTypeBox">
      <div class="hpTypeList" v-for="(item, index) in typeData" :key="index">
        <div class="left">
          <h3>{{item.title}}</h3>
          <p>{{item.info}}</p>
          <div class="moreBox">
            <div class="hmq1" v-if="item.type == 1">
              <ul>
                <li v-for="(row, i) in item.dataArr" :key="i">
                  <img src="@/assets/images/hmq4.png" alt />
                  <div>{{row}}</div>
                </li>
                <div class="slh">
                  <img src="@/assets/images/slh.png" alt />
                </div>
              </ul>
            </div>
            <div class="hmq2" v-if="item.type == 2">
              <ul>
                <li v-for="(row, i) in item.dataArr" :key="i">
                  <img :src="row" :class="'img'+i" alt />
                </li>
                <div class="slh">
                  <img src="@/assets/images/slh.png" alt />
                </div>
              </ul>
            </div>
            <div class="hmq3" v-if="item.type == 3">
              <ul>
                <li v-for="(row, i) in item.dataArr" :key="i">{{row}}</li>
                <div class="slh">
                  <img src="@/assets/images/slh.png" alt />
                </div>
              </ul>
            </div>
          </div>
        </div>
        <div :class="'right' + index">
          <img :src="item.img" alt />
        </div>
      </div>
    </div>
    <!-- footerBox -->
    <div class="gwFooter">
      <FooterBox />
    </div>
  </div>
</template>

<script>
import HeadNav from "@/components/HeadNav.vue";
import FooterBox from "@/components/FooterBox.vue";
export default {
  name: "haiMaiQuan",
  components: {
    HeadNav,
    FooterBox
  },
  metaInfo: {
    title: '海脉圈',
    meta: [
      {
        name: 'keywords',
        content: '人在海南、人才引进、海南自贸港、自贸港政策、海南教育、自贸港、重点园区、海南人才落户、海南招聘、海南考试、海南公考、海南公务员、海南社保、海南公积金、海南房价、海南房产、海南摇号、海南免税店、海南电动车上牌、海南充电桩、海南医疗、乐城特药险、海南健康码、人在岛上、人在海上、岛内、岛外、海南职场、海南公司、海南产业园、海南高薪工作、海南招聘、海南社交、海南线上活动、海南线下活动、海聘、海脉圈、甘肃人在海南、广西人在海南、贵州人在海南、海南人在海南、黑龙江人在海南、吉林人在海南、辽宁人在海南、内蒙古人在海南、宁夏人在海南、青海人在海南、山西人在海南、西藏人在海南、云南人在海南、广东人在海南、北京人在海南、上海人在海南、东北人在海南、深圳人在海南，海聘，海聘圈，简历，公司企业，求职，职位，找工作，话题广场，圈子，个人简历，我的圈子，职场名片，我要求职'
      },
      {
        name: 'description',
        content: '岛上生活-人在海南”项目致力于成为海南人才引进的窗口——海南本地职场社交平台，吸引各类优秀人才来海南发展创业，共享海南自由贸易港发展新机遇。用户可在平台上进行求职招聘、建立连接，关注好友的职业动态及互动，浏览本地各行业的项目介绍、企业展示、创始人访谈等，跟踪行业资讯和动态，留意职场的变化与新机会。通过搭建人才线上交流平台，营造相同职业、相同行业的人员聚集交流氛围，促进信息的流转和整体的海南职场交流效率。'
      }
    ]
  },
  data() {
    return {
      navBarFixed: false,
      current: 0,
      typeList: [
        {
          name: "圈子",
          id: 0
        },
        {
          name: "兴趣群组",
          id: 1
        },
        {
          name: "活动",
          id: 2
        }
      ],
      typeData: [
        {
          title: "海脉圈-圈子",
          info: "海南本地职场社交圈&海南行业圈子，用观点制造行业影响力",
          img: require("@/assets/images/hmq1.png"),
          type: 1,
          dataArr: ["海南自贸港发展", "海南教育培训行业圈", "海南互联网公司"]
        },
        {
          title: "海脉圈-兴趣群组",
          info: "促进不同海南兴趣群组人员聚集，形成即时性行业交流与兴趣沟通",
          img: require("@/assets/images/hmq2.png"),
          type: 2,
          dataArr: [
            require("@/assets/images/group1.png"),
            require("@/assets/images/group2.png"),
            require("@/assets/images/group3.png"),
            require("@/assets/images/group4.png"),
            require("@/assets/images/group5.png"),
            require("@/assets/images/group6.png"),
            require("@/assets/images/group7.png"),
            require("@/assets/images/group8.png")
          ]
        },
        {
          title: "海脉圈-活动",
          info: "线上交流还不够？那来一场职场奔现吧，说不定就有你的伯乐",
          img: require("@/assets/images/hmq3.png"),
          type: 3,
          dataArr: ["剧本杀", "体育运动", "饭局", "户外", "学习", "演出"]
        }
      ]
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {
    // 事件监听滚动条
    this.$nextTick(() => {
      window.addEventListener("scroll", this.watchScroll); // 监听滚动事件
    }, 0);
  },
  destroyed() {

  },
  methods: {
    watchScroll(e) {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      //  当滚动超过 50 时，实现吸顶效果
      if (scrollTop > 1000) {
        this.navBarFixed = true;
      } else {
        this.navBarFixed = false;
      }
    },
    choiceTap(id) {
      this.current = id;
      var items = document.querySelectorAll(".hpTypeList");
      for (var i = 0; i < items.length; i++) {
        if (id === i) {
          items[id].scrollIntoView({
            block: "center", //默认跳转到顶部
            behavior: "smooth" //滚动的速度
          });
        }
      }
    }
  }
};
</script>
<style lang="scss">
.haiMaiQuan {
  .aboutBanner {
    position: relative;
    height: 682px;
    width: $wd_1920;
    .usBox {
      @include dwAbsolute;
      @include flexColumnJustify;
      padding: 0 9%;
      box-sizing: border-box;
      height: 80%;
      h2 {
        font-weight: bold;
        color: $clr_son;
        padding-bottom: 40px;
        font-size: 48px;
      }
      p {
        color: $clr_333;
        font-size: $size_24;
        line-height: 40px;
        width: 610px;
      }
    }
  }
  .typeBox {
    padding: 60px 13% 0;
    ul {
      @include flexEnter2;
      justify-content: center;
      li {
        font-size: $size_20;
        color: $clr_999;
        font-weight: 600;
        margin: 0 40px;
        cursor: pointer;
      }
      .current {
        color: $clr_son;
        font-weight: 900;
        padding-bottom: 10px;
        position: relative;
        &::before {
          content: "";
          position: absolute;
          width: 50%;
          height: 4px;
          background: $bg_son;
          left: 0;
          right: 0;
          margin: auto;
          bottom: -6px;
          z-index: 22;
        }
      }
    }
  }
  .navBarWrap {
    position: fixed;
    top: 100px;
    width: $wd_1920;
    box-shadow: 0 4px 5px -3px rgba(0, 0, 0, 0.1);
    height: 100px;
    box-sizing: border-box;
    background: $bg_fff;
    padding: 0 13%;
    z-index: 33;
    ul {
      height: 100px;
    }
  }
  .hpTypeList {
    padding: 110px 280px;
    box-sizing: border-box;
    @include flexBetween;
    .left {
      h3 {
        font-size: $size_40;
        font-weight: bold;
        padding-bottom: 30px;
      }
      p {
        font-size: $size_24;
        color: $clr_666;
        line-height: 32px;
        width: 516px;
      }
      .moreBox {
        margin-top: 50px;
        .hmq1 {
          li {
            @include flexEnter2;
            height: 40px;
            border-radius: 23px;
            background: #f4f8fe;
            padding: 0 20px;
            margin-bottom: 20px;
            width: fit-content;
            img {
              width: 24px;
              height: 24px;
              margin-right: 10px;
            }
          }
          .slh {
            margin-left: 10px;
            width: 20px;
            height: 4px;
          }
        }
        .hmq2 {
          ul {
            display: flex;
            flex-wrap: wrap;
            width: 516px;
            li {
              border-radius: 10px;
              width: 64px;
              height: 64px;
              background: $bg_fff;
              margin: 0 30px 30px 0;
              @include flexEnter2;
              justify-content: center;
              img {
                width: 42px;
                height: 41px;
              }
              .img0 {
                height: 34px;
              }
              .img2 {
                height: 35px;
              }
              .img3 {
                height: 42px;
              }
              .img5 {
                height: 42px;
              }
              .img6 {
                height: 40px;
              }
            }
            .slh {
              width: 64px;
              @include flexEnter2;
              height: 64px;
              justify-content: center;
              img {
                object-fit: contain;
                width: 20px;
                height: 4px;
              }
            }
          }
        }
        .hmq3 {
          ul {
            display: flex;
            flex-wrap: wrap;
            width: 516px;
            li {
              border-radius: 27px;
              height: 36px;
              line-height: 36px;
              background: #f4f8fe;
              margin: 0 30px 30px 0;
              padding: 0 20px;
              font-size: $size_20;
              color: $clr_666;
            }
            .slh {
              padding: 0 20px;
              @include flexEnter2;
              height: 36px;
              justify-content: center;
              img {
                object-fit: contain;
                width: 20px;
                height: 4px;
              }
            }
          }
        }
      }
    }
    .right0 {
      width: 616px;
      height: 602px;
    }
    .right1 {
      width: 667px;
      height: 637px;
    }
    .right2 {
      width: 667px;
      height: 630px;
    }
  }
  // 奇数行
  .hpTypeList:nth-child(odd) {
    background: $bg_fff;
  }
  // 偶数行
  .hpTypeList:nth-child(even) {
    background: #f4f8fe;
    flex-direction: row-reverse;
  }
  .gwFooter {
    background: #f1f3f5;
  }
}
</style>