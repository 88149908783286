import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "@/lib/vant"
import 'amfe-flexible';  //与postcss-pxtorem配套使用  设置 rem 基准值
import * as utils from '@/lib/utils'  //引入工具类函数
import '@/assets/css/index.scss'  //全局样式
import apis from "@/lib/apis/index"
import MetaInfo from 'vue-meta-info'
Vue.use(MetaInfo)

import VueAMap from 'vue-amap'
Vue.use(VueAMap)
VueAMap.initAMapApiLoader({
  key: '7e4c0aeaf5a8b89edab55d9734f81ab0',
  plugin: ['AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PlaceSearch', 'AMap.Geolocation', 'AMap.Geocoder'],
  v: '1.4.4',
  uiVersion: '1.0'
})




// var h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
// var width = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);  //772  400
// width = width >= 720 ? 1920 : width;
// if (width < 720) {
//   alert('width')
//   var userSettingRatio = parseFloat(window.getComputedStyle(document.documentElement).fontSize) / 16;//获取比例
//   console.log('userSettingRatio:', userSettingRatio);
//   var fontSize = (192 / userSettingRatio) + 'px';
//   document.getElementsByTagName('html')[0].style.fontSize = fontSize;//wFsize 是我们根据页面宽度计算得rem，根据比例重新计算rem的大小。
// }

Vue.prototype.$apis = apis
Vue.prototype.$utils = utils

Vue.config.productionTip = false

router.afterEach((to, from) => {
  //将qr_code存入localStorage
  // localStorage.setItem('token', utils.getQueryVariable('qr_code'));
})

new Vue({
  router,
  store,
  render: h => h(App),
  mounted: () => document.dispatchEvent(new Event("render-event")),  //mqr
}).$mount('#app')
