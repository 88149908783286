<template>
  <div class="haiPin">
    <!-- 导航栏 -->
    <HeadNav pageName="haiPin" />
    <!-- 海聘&聘人才 -->
    <div class="aboutBanner">
      <img src="@/assets/images/banner9.png" alt />
      <div class="usBox">
        <h2>海聘&聘人才</h2>
        <p>打造互联网高端人才体系，建设海南互联网人才梯队，减少人才流失，助力海南本土企业打造雇主品牌。</p>
      </div>
    </div>
    <!-- 本地职场 - li -->
    <div class="typeBox" :class="{'navBarWrap':navBarFixed}">
      <ul>
        <li
          v-for="(row, i) in typeList"
          :key="i"
          :class="{'current': current == row.id}"
          @click="choiceTap(row.id)"
        >{{row.name}}</li>
      </ul>
    </div>
    <!-- 本地职场 -->
    <div class="hpTypeBox">
      <div class="hpTypeList" v-for="(item, index) in typeData" :key="index">
        <div class="left">
          <h3>{{item.title}}</h3>
          <p>{{item.info}}</p>
        </div>
        <div class="right">
          <img :src="item.img" alt />
        </div>
      </div>
    </div>

    <!-- 是否对我们的产品感兴趣了呢？ -->
    <div class="productBox">
      <img class="dbBanner" src="@/assets/images/about.png" alt />
      <img class="mask" src="@/assets/images/mask.png" alt />
      <div class="download">
        <div class="t1">是否对我们的产品感兴趣了呢？</div>
        <div class="t2">如果您对我们的产品非常感兴趣，但还存在一点疑惑</div>
        <div class="lxwm" @click="aboutUsUrl">联系我们</div>
      </div>
    </div>
    <!-- footerBox -->
    <div class="gwFooter">
      <FooterBox />
    </div>
  </div>
</template>

<script>
import HeadNav from "@/components/HeadNav.vue";
import FooterBox from "@/components/FooterBox.vue";
export default {
  name: "haiPin",
  components: {
    HeadNav,
    FooterBox
  },
  metaInfo: {
    title: '海聘',
    meta: [
      {
        name: 'keywords',
        content: '人在海南、人才引进、海南自贸港、自贸港政策、海南教育、自贸港、重点园区、海南人才落户、海南招聘、海南考试、海南公考、海南公务员、海南社保、海南公积金、海南房价、海南房产、海南摇号、海南免税店、海南电动车上牌、海南充电桩、海南医疗、乐城特药险、海南健康码、人在岛上、人在海上、岛内、岛外、海南职场、海南公司、海南产业园、海南高薪工作、海南招聘、海南社交、海南线上活动、海南线下活动、海聘、海脉圈、甘肃人在海南、广西人在海南、贵州人在海南、海南人在海南、黑龙江人在海南、吉林人在海南、辽宁人在海南、内蒙古人在海南、宁夏人在海南、青海人在海南、山西人在海南、西藏人在海南、云南人在海南、广东人在海南、北京人在海南、上海人在海南、东北人在海南、深圳人在海南，海聘，海聘圈，简历，公司企业，求职，职位，找工作，话题广场，圈子，个人简历，我的圈子，职场名片，我要求职'
      },
      {
        name: 'description',
        content: '岛上生活-人在海南”项目致力于成为海南人才引进的窗口——海南本地职场社交平台，吸引各类优秀人才来海南发展创业，共享海南自由贸易港发展新机遇。用户可在平台上进行求职招聘、建立连接，关注好友的职业动态及互动，浏览本地各行业的项目介绍、企业展示、创始人访谈等，跟踪行业资讯和动态，留意职场的变化与新机会。通过搭建人才线上交流平台，营造相同职业、相同行业的人员聚集交流氛围，促进信息的流转和整体的海南职场交流效率。'
      }
    ]
  },
  data() {
    return {
      navBarFixed: false,
      current: 0,
      typeList: [
        {
          name: "本地职场",
          id: 0
        },
        {
          name: "拓展人脉",
          id: 1
        },
        {
          name: "职场社交",
          id: 2
        },
        {
          name: "行业资讯",
          id: 3
        },
        {
          name: "人才银行",
          id: 4
        },
        {
          name: "创业窗口",
          id: 5
        }
      ],
      typeData: [
        {
          title: "本地职场",
          info:
            "建立海南职场人才沟通平台，促进职场人员交流，营造人才线上聚集交流，与线下职场联动。",
          img: require("@/assets/images/img5.png")
        },
        {
          title: "拓展人脉",
          info:
            "搭建人才线上交流平台，营造相同职业、相同行业的人员聚集交流氛围，促进信息的流转和整体的职场交流效率。",
          img: require("@/assets/images/img1.png")
        },
        {
          title: "职场社交",
          info:
            "用户在上面建立连接，关注好友的职业动态并互动，同时可以浏览本地各行业企业的项目介绍、企业展示、创始人访谈等。",
          img: require("@/assets/images/img2.png")
        },
        {
          title: "行业资讯",
          info: "跟踪行业的资讯和动态，留意职场的变化和新机会。",
          img: require("@/assets/images/img3.png")
        },
        {
          title: "人才银行",
          info:
            "建立人才银行，为企业提供人才库，为项目孵化中心储备足够的人才资源。",
          img: require("@/assets/images/img4.png")
        },
        {
          title: "创业窗口",
          info:
            "需要找投资和找人才的用户也可以在相应的板块下获取资源，提供资源分享的平台。",
          img: require("@/assets/images/img6.png")
        }
      ]
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {
    // 事件监听滚动条
    window.addEventListener("scroll", this.watchScroll);
  },
  methods: {
    aboutUsUrl() {
      this.$router.push({ path: "/aboutUs" });
    },
    watchScroll() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      //  当滚动超过 50 时，实现吸顶效果
      if (scrollTop > 1000) {
        this.navBarFixed = true;
      } else {
        this.navBarFixed = false;
      }
    },
    choiceTap(id) {
      this.current = id;
      var items = document.querySelectorAll(".hpTypeList");
      for (var i = 0; i < items.length; i++) {
        if (id === i) {
          items[id].scrollIntoView({
            block: "center", //默认跳转到顶部
            behavior: "smooth" //滚动的速度
          });
        }
      }
    }
  }
};
</script>
<style lang="scss">
.haiPin {
  .aboutBanner {
    position: relative;
    height: 680px;
    width: $wd_1920;
    .usBox {
      @include dwAbsolute;
      @include flexColumnJustify;
      padding: 0 10%;
      box-sizing: border-box;
      height: 83%;
      h2 {
        font-weight: bold;
        color: $clr_son;
        padding-bottom: 40px;
        font-size: 48px;
      }
      p {
        color: #576281;
        font-size: $size_24;
        line-height: 40px;
        width: 596px;
      }
    }
  }
  .typeBox {
    padding: 60px 13% 0;
    ul {
      @include flexEnter2;
      justify-content: center;
      li {
        font-size: $size_20;
        color: $clr_999;
        font-weight: 600;
        margin: 0 40px;
        cursor: pointer;
      }
      .current {
        color: $clr_son;
        font-weight: 900;
        padding-bottom: 10px;
        position: relative;
        &::before {
          content: "";
          position: absolute;
          width: 50%;
          height: 4px;
          background: $bg_son;
          left: 0;
          right: 0;
          margin: auto;
          bottom: -6px;
          z-index: 22;
        }
      }
    }
  }
  .navBarWrap {
    position: fixed;
    top: 100px;
    width: $wd_1920;
    box-shadow: 0 4px 5px -3px rgba(0, 0, 0, 0.1);
    height: 100px;
    box-sizing: border-box;
    background: $bg_fff;
    padding: 0 13%;
    z-index: 33;
    ul {
      height: 100px;
    }
  }
  .hpTypeList {
    height: 600px;
    padding: 0 280px;
    box-sizing: border-box;
    @include flexBetween;
    .left {
      h3 {
        font-size: $size_40;
        font-weight: bold;
        padding-bottom: 30px;
      }
      p {
        font-size: $size_24;
        color: $clr_666;
        width: 516px;
        line-height: 32px;
      }
    }
    .right {
      width: 640px;
      height: 480px;
    }
  }
  // 奇数行
  .hpTypeList:nth-child(odd) {
    background: $bg_fff;
  }
  // 偶数行
  .hpTypeList:nth-child(even) {
    background: #f4f8fe;
    flex-direction: row-reverse;
  }
  .productBox {
    height: 808px;
    position: relative;
    .dbBanner {
      height: 808px;
      width: $wd_1920;
    }
    .mask {
      @include dwAbsolute;
      z-index: 2;
    }
    .download {
      padding: 160px 0 210px;
      box-sizing: border-box;
      @include dwAbsolute;
      z-index: 3;
      @include flexColumnAlign;
      .t1 {
        font-size: $size_32;
        color: $clr_fff;
        font-weight: bold;
        padding-bottom: 50px;
      }
      .t2 {
        font-size: 64px;
        color: $clr_fff;
        font-weight: bold;
        padding-bottom: 110px;
        width: 960px;
        text-align: center;
        line-height: 90px;
      }
      .lxwm {
        background: $bg_son;
        height: 96px;
        line-height: 96px;
        border-radius: 55px;
        width: 280px;
        text-align: center;
        color: $clr_fff;
        font-size: $size_32;
        margin-bottom: 130px;
        cursor: pointer;
        font-weight: bold;
      }
    }
  }
}
</style>