import Common from "./common"
class Active extends Common {
    constructor() {
        super()
    }

    getActiveInfo(params) {  //活动详情
        return this.get('/activity-api/activity', params)
    }
    // getActiveCancel(params){  //取消活动
    //     // return this.post('/activity-api/activity', params)
    // }
}

export default new Active()