<template>
  <div class="aboutUs">
    <!-- 导航栏 -->
    <HeadNav pageName="aboutUs" />
    <!-- 关于我们 -->
    <div class="aboutBanner">
      <img src="@/assets/images/banner4.png" alt />
      <div class="usBox">
        <h2>关于我们</h2>
        <p>助力海南本土企业打造雇主品牌，建立海南人才银行</p>
      </div>
    </div>
    <!-- 海南博众人力资源有限公司人在海南项目 -->
    <div class="company">
      <div class="left">
        <img src="@/assets/images/banner7.png" alt />
      </div>
      <div class="right">
        <h2>海南博众人力资源有限公司人在海南项目</h2>
        <p>人在海南是基于以海南为定位为高端化职场人群打造的以职场信息、职场动态、职场交友为核心驱动的职场社交平台，产品主要结合在自贸港人才引进的政策环境下建立属于海南本土化的职场精英交流平台，让职能赋能社交，让精英在此相识。</p>
      </div>
    </div>
    <!-- 我们的创作团队 -->
    <div class="team bgColor">
      <h4>我们的创作团队</h4>
      <div class="photosBox">
        <ul>
          <li v-for="(itme,i) in photoData" :key="i">
            <img :src="itme.img" alt />
            <div class="userName">{{itme.name}}</div>
            <div class="positionName">{{itme.position}}</div>
          </li>
        </ul>
      </div>
    </div>
    <!-- 让我们一起合作做一个了不起的项目吧 -->
    <div class="project">
      <div class="left">
        <input type="text" placeholder-class="fz" placeholder="您怎么称呼" />
        <input type="text" placeholder-class="fz" placeholder="请输入您的邮箱" />
        <input type="text" placeholder-class="fz" placeholder="请输入您的项目类型" />
        <textarea type="text" placeholder-class="fz" placeholder="请描述您的问题"></textarea>
      </div>
      <div class="right">
        <div class="title">
          <h3>让我们一起合作</h3>
          <h3>做一个了不起的项目吧</h3>
        </div>
        <div class="infoList">
          <div class="top">联系电话</div>
          <div class="bottom">0898-68602235</div>
        </div>
        <div class="infoList">
          <div class="top">联系地址</div>
          <div class="bottom">海南省海口市龙华区金贸街道滨海大道117号滨海国际金融中心A座2702</div>
        </div>
        <div class="infoList">
          <div class="top">联系邮箱</div>
          <div class="bottom">hr@zhongke.club</div>
        </div>
      </div>
    </div>
    <!-- map地图导航 -->
    <div class="mapBox">
      <el-amap vid="amap" class="amap-demo" :center="center" :zoom="zoom" ref="map">
        <el-amap-text :text="markers.text" :offset="markers.offset" :position="markers.position"></el-amap-text>
        <el-amap-marker
          :visible="markers.visible"
          :title="markers.title"
          :position="markers.position"
        ></el-amap-marker>
      </el-amap>
    </div>
    <!-- footerBox -->
    <div class="gwFooter">
      <FooterBox />
    </div>
  </div>
</template>

<script>
import HeadNav from "@/components/HeadNav.vue";
import FooterBox from "@/components/FooterBox.vue";

export default {
  name: "aboutUs",
  components: {
    HeadNav,
    FooterBox
  },
  metaInfo: {
    title: "关于我们",
    meta: [
      {
        name: "keywords",
        content:
          "人在海南、人才引进、海南自贸港、自贸港政策、海南教育、自贸港、重点园区、海南人才落户、海南招聘、海南考试、海南公考、海南公务员、海南社保、海南公积金、海南房价、海南房产、海南摇号、海南免税店、海南电动车上牌、海南充电桩、海南医疗、乐城特药险、海南健康码、人在岛上、人在海上、岛内、岛外、海南职场、海南公司、海南产业园、海南高薪工作、海南招聘、海南社交、海南线上活动、海南线下活动、海聘、海脉圈、甘肃人在海南、广西人在海南、贵州人在海南、海南人在海南、黑龙江人在海南、吉林人在海南、辽宁人在海南、内蒙古人在海南、宁夏人在海南、青海人在海南、山西人在海南、西藏人在海南、云南人在海南、广东人在海南、北京人在海南、上海人在海南、东北人在海南、深圳人在海南，海聘，海聘圈，简历，公司企业，求职，职位，找工作，话题广场，圈子，个人简历，我的圈子，职场名片，我要求职"
      },
      {
        name: "description",
        content:
          "岛上生活-人在海南”项目致力于成为海南人才引进的窗口——海南本地职场社交平台，吸引各类优秀人才来海南发展创业，共享海南自由贸易港发展新机遇。用户可在平台上进行求职招聘、建立连接，关注好友的职业动态及互动，浏览本地各行业的项目介绍、企业展示、创始人访谈等，跟踪行业资讯和动态，留意职场的变化与新机会。通过搭建人才线上交流平台，营造相同职业、相同行业的人员聚集交流氛围，促进信息的流转和整体的海南职场交流效率。"
      }
    ]
  },
  data() {
    let that = this;
    return {
      center: [110.301128, 20.022088],
      zoom: 18,
      markers: {
        position: [110.301128, 20.022088],
        offset: [0, -65],
        visible: true,
        title: "海南滨海国际金融中心",
        text: "海南滨海国际金融中心"
      },
      photoData: [
        {
          img: require("@/assets/images/zjl.png"),
          name: "崔家铭",
          position: "总经理"
        },
        {
          img: require("@/assets/images/fzjl.png"),
          name: "蔡丽娟",
          position: "副总经理"
        },
        {
          img: require("@/assets/images/cpzj.png"),
          name: "黄炜",
          position: "产品总监"
        },
        {
          img: require("@/assets/images/jsjl.png"),
          name: "徐威",
          position: "技术经理"
        },
        {
          img: require("@/assets/images/jszg.png"),
          name: "邢家鹏",
          position: "技术主管"
        },
        {
          img: require("@/assets/images/cpzg.png"),
          name: "张羽婷",
          position: "产品主管"
        },
        {
          img: require("@/assets/images/yyzg.png"),
          name: "符俊继",
          position: "运营主管"
        },
        {
          img: require("@/assets/images/sjzg.png"),
          name: "廖小清",
          position: "设计主管"
        }
      ]
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {}
};
</script>
<style lang="scss">
.aboutUs {
  .aboutBanner {
    position: relative;
    height: 480px;
    width: $wd_1920;
  }
  .usBox {
    @include dwAbsolute;
    @include flexColumnJustify;
    padding: 0 10%;
    box-sizing: border-box;
    height: 83%;
    h2 {
      font-weight: bold;
      color: $clr_son;
      padding-bottom: 40px;
      font-size: 48px;
    }
    p {
      color: #576281;
      font-size: $size_24;
    }
  }
  .company {
    padding: 140px 10%;
    display: flex;
    justify-content: space-between;
    .left {
      height: 480px;
      width: 800px;
    }
    .right {
      width: 640px;
      h2 {
        font-size: $size_40;
        font-weight: bold;
        line-height: 64px;
        padding-bottom: 40px;
      }
      p {
        line-height: 40px;
        font-size: $size_24;
        color: $clr_666;
      }
    }
  }
  .team {
    padding: 120px 18% 140px;
    h4 {
      font-size: $size_40;
      font-weight: bold;
      text-align: center;
      padding-bottom: 20px;
    }
    .photosBox ul {
      display: flex;
      flex-wrap: wrap;
      li {
        @include flexColumnAlign;
        width: 180px;
        margin: 110px 60px 0;
        img {
          height: 180px;
          margin-bottom: 25px;
        }
        .userName {
          font-weight: bold;
          font-size: $size_32;
          padding-bottom: 15px;
        }
        .positionName {
          font-size: $size_24;
          color: $clr_666;
        }
      }
    }
  }
  .project {
    padding: 130px 16%;
    @include flexBetween;
    .left {
      height: 552px;
      @include flexColumnBetween;
      /* 通用 */
      ::-webkit-input-placeholder {
        font-size: 24px;
        height: 64px;
        line-height: 64px;
      }
      ::-moz-placeholder {
        color: #f00;
        font-size: 24px;
        height: 64px;
        line-height: 64px;
      } /* firefox 19+ */
      :-ms-input-placeholder {
        color: #f00;
        font-size: 28px;
        height: 64px;
        line-height: 64px;
      } /* ie */
      input:-moz-placeholder {
        color: #f00;
        font-size: 24px;
        height: 64px;
        line-height: 64px;
      }
      input {
        border-radius: 10px;
        background: #f2f4f8;
        height: 64px;
        line-height: 64px;
        color: $clr_999;
        padding: 0 25px;
        font-size: 24px;
        width: 640px;
        border: none;
        box-sizing: border-box;
      }
      textarea {
        border-radius: 10px;
        background: #f2f4f8;
        height: 240px;
        line-height: 64px;
        color: $clr_999;
        padding: 10px 25px;
        line-height: 32px;
        width: 640px;
        font-size: 24px;
        border: none;
        box-sizing: border-box;
      }
    }
    .right {
      height: 552px;
      width: 560px;
      box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.1);
      box-sizing: border-box;
      border-radius: 15px;
      padding: 50px 30px 40px;
      @include flexColumnBetween;
      background: $bg_fff;
      .title {
        width: 400px;
        h3 {
          font-size: $size_40;
          font-weight: bold;
          padding-bottom: 10px;
        }
      }
      .infoList {
        width: 400px;
        .top {
          font-size: $size_24;
          color: $clr_666;
          padding-bottom: 10px;
        }
        .bottom {
          font-size: $size_20;
          color: $clr_son;
          line-height: 26px;
        }
      }
    }
  }
  .mapBox {
    height: 560px;
    .amap-demo {
      height: 560px;
      width: 100%;
      .amap-overlay-text-container {
        padding: 12px 21px;
        border: none;
        border-radius: 6px;
        font-size: 18px;
        font-weight: bold;
        box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.1);
        // position: relative;
        // &::before {
        //   position: absolute;
        //   content: "";
        //   height: 10px;
        //   width: 10px;
        //   background: #fff;
        //   bottom: 0;
        //   left: 0;
        //   right: 0;
        //   margin: auto;
        //   transform: translate(0, -50%) rotate(45deg);
        // }
      }
    }
  }
}
</style>