import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import newsDetails from '../views/newsDetails.vue'
import aboutUs from '../views/aboutUs.vue'
import haiPin from '../views/haiPin.vue'
import haiMaiQuan from '../views/haiMaiQuan.vue'
import serviceAgreement from '../views/serviceAgreement.vue'
import privacyPolicy from '../views/privacyPolicy.vue'
import communityNorms from '../views/communityNorms.vue'
import sdk from '../views/sdk.vue'
import sbqxlb from '../views/sbqxlb.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/newsDetails',
    name: 'newsDetails',
    component: newsDetails
  },
  {
    path: '/aboutUs',
    name: 'aboutUs',
    component: aboutUs
  },
  {
    path: '/haiPin',
    name: 'haiPin',
    component: haiPin
  },
  {
    path: '/haiMaiQuan',
    name: 'haiMaiQuan',
    component: haiMaiQuan
  },
  {
    path: '/serviceAgreement',
    name: 'serviceAgreement',
    component: serviceAgreement
  },
  {
    path: '/privacyPolicy',
    name: 'privacyPolicy',
    component: privacyPolicy
  },
  {
    path: '/communityNorms',
    name: 'communityNorms',
    component: communityNorms
  },
  {
    path: '/sdk',
    name: 'sdk',
    component: sdk
  },
  {
    path: '/sbqxlb',
    name: 'sbqxlb',
    component: sbqxlb
  },
  {
    path: '*',
    component: () => import('@/views/404'),
  },
 
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {    //页面跳转后不保留上一页滚动条的办法  
      if (savedPosition) {       
          return savedPosition     
      } else {       
          return { x: 0, y: 0 }     
      }   
  }
})

export default router
