<template>
  <div class="newsBox">
    <ul>
      <li @click="newDetailsUrl(row.id)" v-for="(row, i) in newsData" :key="i">
        <div class="top">
          <img :src="row.img" alt />
        </div>
        <div class="bottom">
          <h4>{{row.name}}</h4>
          <div class="time">{{row.time}}</div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "newsInformation",
  props: {},
  data() {
    return {
      newsData: [
        {
          name: "海南自贸港招才引智活动",
          id: "1",
          img: require("@/assets/images/news1.png"),
          time: "2021.10.24"
        },
        {
          name: "自贸港建设总体方案去年发布以来吸引人才26万人",
          id: "2",
          img: require("@/assets/images/news2.png"),
          time: "2021.09.24"
        },
        {
          name: "来海南自贸港实现“诗和远方”",
          id: "3",
          img: require("@/assets/images/news3.png"),
          time: "2021.05.24"
        }
      ]
    };
  },
  methods: {
    newDetailsUrl(id) {
      this.$router.push({ path: "/newsDetails", query: { id: id } });
    }
  }
};
</script>

<style scoped lang="scss">
.newsBox {
  width: 100%;
  cursor: pointer;
  ul {
    @include flexEnter2;
    li {
      background: $bg_fff;
      border-radius: 20px;
      width: 400px;
      height: 310px;
      margin: 0 25px;
      box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.1);
      .top {
        height: 202px;
        width: 400px;
        overflow: hidden;
        img {
          border-radius: 20px 20px 0 0;
          box-sizing: border-box;
          transition: all 0.5s ease-out 0.1s;
        }
        img:hover {
          transform: scale(1.2);
        }
      }
      .bottom {
        height: 108px;
        @include flexColumnBetween;
        padding: 13px 20px;
        box-sizing: border-box;
        h4 {
          font-size: $size_20;
          color: #000;
          line-height: 26px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
        }
        h4:hover {
          color: $clr_son;
        }
        .time {
          font-size: 16px;
          color: $clr_999;
        }
        .time:hover {
          opacity: 0.6;
        }
      }
    }
  }
}
</style>
